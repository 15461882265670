import { useTranslation } from '@/app/i18n/client';
import CryptoAmount from '@/components/CryptoAmount';
import CryptoTooltip from '@/components/CryptoTooltip';
import { useCurrency } from '@/hooks/currencies';
import { useFiatFromCrypto } from '@/hooks/fiat';
import { useAppSelector } from '@/hooks/redux';
import {
  Bonus,
  PreviewBonusesContentType,
} from '@/types/json-content/preview_bonuses';
import { cn } from '@/utils/style';
import { Text } from '@purposeinplay/core-v2';
import React from 'react';

const DepositRange = ({
  bonus,
  className,
}: {
  bonus: Bonus;
  className?: string;
}) => {
  const { t } = useTranslation();
  const content = t('preview_bonuses') as PreviewBonusesContentType;

  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
  const foundCurrency = useCurrency();
  const fiatAmountMin = useFiatFromCrypto(
    foundCurrency?.code,
    // @ts-ignore
    bonus?.min_deposit?.value[foundCurrency?.code],
  );

  const fiatAmountMax = useFiatFromCrypto(
    foundCurrency?.code,
    // @ts-ignore
    bonus?.max_deposit?.value[foundCurrency?.code],
  );

  return (
    <div
      className={cn([
        'flex flex-row items-center gap-spacing-2xs',
        className && className,
      ])}
    >
      <Text size="sm" className="font-medium text-text-default" as="span">
        {content?.deposit}
      </Text>
      <CryptoTooltip
        cryptoAmount={
          !viewFiat ? (
            <CryptoAmount
              removeIcon
              balance={Number(
                // @ts-ignore
                bonus?.min_deposit?.value[foundCurrency?.code],
              )}
              currency={foundCurrency?.code}
            />
          ) : (
            <Text size="sm">{fiatAmountMin}</Text>
          )
        }
        cryptoCode={foundCurrency?.code}
      >
        <CryptoAmount
          balance={Number(
            // @ts-ignore
            bonus?.min_deposit?.value[foundCurrency?.code],
          )}
          currency={foundCurrency?.code}
        />
      </CryptoTooltip>
      {bonus?.max_deposit ? (
        <>
          <Text size="xs" as="span" className="font-normal text-text-subdued">
            -
          </Text>
          <CryptoTooltip
            cryptoAmount={
              !viewFiat ? (
                <CryptoAmount
                  removeIcon
                  balance={Number(
                    // @ts-ignore
                    bonus?.max_deposit?.value[foundCurrency?.code],
                  )}
                  currency={foundCurrency?.code}
                />
              ) : (
                <Text size="sm">{fiatAmountMax}</Text>
              )
            }
            cryptoCode={foundCurrency?.code}
          >
            <CryptoAmount
              balance={Number(
                // @ts-ignore
                bonus?.max_deposit?.value[foundCurrency?.code],
              )}
              currency={foundCurrency?.code}
            />
          </CryptoTooltip>
        </>
      ) : (
        <Text size="sm" as="span" className="font-semibold">
          {content?.last_interval_label}
        </Text>
      )}
    </div>
  );
};

export default DepositRange;
