import { useEffect, useMemo, useState } from 'react';
import {
  useParams,
  usePathname,
  useRouter,
  useSearchParams,
} from 'next/navigation';
import useWalletSelector from './use-wallet-selector';
import useModal, { createQueryString } from './modal';
import { useAuth } from './auth';

const useUpdateMissingCurrency = (fiatBuy = false) => {
  const { user } = useAuth();
  const { create, replace } = useModal();
  const { account, formattedAccounts, isLoading } = useWalletSelector();
  const router = useRouter();
  const accountCurrency = account?.currency;
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [selectedCurr, setSelectedCurr] = useState();

  const paramCurrency = searchParams?.get('c');

  const searchParamCurrency = useMemo(() => {
    if (fiatBuy) {
      if (paramCurrency !== 'XRP') {
        return paramCurrency;
      }
      return paramCurrency === 'XRP' && accountCurrency !== 'XRP'
        ? accountCurrency
        : 'BTC';
    } else {
      return paramCurrency;
    }
  }, [accountCurrency, fiatBuy, paramCurrency]);

  useEffect(() => {
    if (!fiatBuy) return;
    const param = searchParams?.get('c');
    if (param !== searchParamCurrency && fiatBuy) {
      replace('c', searchParamCurrency);
    }
  }, [fiatBuy, replace, searchParamCurrency, searchParams, selectedCurr]);

  useEffect(() => {
    if (user?.isLoading || isLoading) return;
    if (!searchParamCurrency) {
      create('c', accountCurrency);
      if (accountCurrency && accountCurrency.toLowerCase() !== selectedCurr) {
        setSelectedCurr(accountCurrency.toLowerCase());
      }
    } else {
      const curr = Array.isArray(searchParamCurrency)
        ? searchParamCurrency[0]?.toLowerCase()
        : searchParamCurrency?.toLowerCase();
      const foundCurrency = formattedAccounts?.find(
        (acc: any) => acc.currency.toLowerCase() === curr,
      );
      if (
        !foundCurrency &&
        formattedAccounts[0]?.currency.toLowerCase() !== selectedCurr
      ) {
        setSelectedCurr(formattedAccounts[0]?.currency.toLowerCase());
      } else if (
        foundCurrency &&
        foundCurrency.currency.toLowerCase() !== selectedCurr
      ) {
        setSelectedCurr(foundCurrency.currency.toLowerCase());
      }
    }
  }, [
    accountCurrency,
    create,
    formattedAccounts,
    pathname,
    router,
    searchParamCurrency,
    selectedCurr,
    user?.isLoading,
    isLoading,
  ]);

  return { selectedCurr, setSelectedCurr };
};

export default useUpdateMissingCurrency;
