import React, { useMemo } from 'react';
import PreviewBonus from './preview-bonus';
import { setWalletDynamicContent } from '@/app/reducers/bootstrap';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useDepositPreviewBonuses } from '@/hooks/bonuses';
import { PreviewBonus as PreviewBonusType } from '@/types/bonuses';
import { useTranslation } from '@/app/i18n/client';
import { PreviewBonusesContentType } from '@/types/json-content/preview_bonuses';
import { useAuth } from '@/hooks/auth';
import CondensedPreviewBonus from './condensed-preview-bonus';
import { usePlayerStats } from '@/hooks/others';

const welcomeBonuses = ['first_deposit', 'second_deposit', 'third_deposit'];

const getReqDepositsToDisplay = (id: string) => {
  switch (id) {
    case 'first_deposit':
      return 0;
    case 'second_deposit':
      return 1;
    case 'third_deposit':
      return 2;
    default:
      return -1; // Return -1 for unknown IDs to indicate invalid cases
  }
};

const BonusData = () => {
  const { data: bonusesPreview, isFetching: bonusesPreviewLoading } =
    useDepositPreviewBonuses();
  const dispatch = useAppDispatch();

  const walletDynamicContent = useAppSelector(
    (state) => state?.bootstrap.walletDynamicContent,
  );
  const { t } = useTranslation();
  const content = t('preview_bonuses') as PreviewBonusesContentType;
  const previewBonuses = content?.bonus_selection_modal?.preview_bonuses;
  const { user } = useAuth();

  const activeBonusCode = useMemo(
    () => user?.currentUser?.deposit_bonus_code,
    [user],
  );
  const { data: playerStats, isLoading: playerStatsLoading } = usePlayerStats();

  const currentPreviewId = bonusesPreview && bonusesPreview[0]?.id;
  const depositCount = playerStats?.deposits_count;

  const foundBonus = previewBonuses?.find((item) => {
    if (!welcomeBonuses?.includes(item?.id)) {
      return (
        item?.coupon_code?.toUpperCase() == activeBonusCode?.toUpperCase() ||
        currentPreviewId?.includes(item?.id?.toUpperCase())
      );
    }
    const requiredDeposits = getReqDepositsToDisplay(item?.id);
    const returnsBonus =
      item?.coupon_code?.toUpperCase() == activeBonusCode?.toUpperCase();
    if (depositCount && depositCount > 0 && returnsBonus) {
      return requiredDeposits !== -1 && depositCount === requiredDeposits;
    }
    return (
      item?.coupon_code?.toUpperCase() == activeBonusCode?.toUpperCase() ||
      currentPreviewId?.includes(item?.id?.toUpperCase())
    );
  });
  const bonusToPreview: PreviewBonusType | null | undefined = useMemo(() => {
    if (walletDynamicContent.bonusToPreview) {
      return walletDynamicContent.bonusToPreview;
    }
    if (bonusesPreview && bonusesPreview.length > 0) {
      return bonusesPreview[0].bonuses?.[0];
    }
    return null;
  }, [walletDynamicContent.bonusToPreview, bonusesPreview]);

  if (bonusesPreviewLoading || !activeBonusCode) return null;

  if (foundBonus && bonusToPreview) {
    return <CondensedPreviewBonus bonus={foundBonus} />;
  }

  // special case for multiple bonuses preview
  if (bonusesPreview && bonusesPreview?.length > 1) {
    return (
      <>
        {bonusesPreview.map((bonus) => (
          <PreviewBonus key={bonus.id} bonus={bonus?.bonuses?.[0]} />
        ))}
      </>
    );
  }

  return (
    <>
      {bonusToPreview ? (
        <PreviewBonus
          bonus={bonusToPreview}
          onClick={() =>
            dispatch(
              setWalletDynamicContent({
                data: {},
                isOpen: 'bonus',
              }),
            )
          }
        />
      ) : null}
    </>
  );
};

export default BonusData;
