import { useCallback, useEffect, useState } from 'react';
import { Arguments, PaymentsLib } from 'payments-lib-types';
import { getApiBaseUrl, getCurrentHostname } from '@/utils/api-domains';
import { captureException } from '@sentry/nextjs';

const usePaymentsServiceV2 = (): {
  isLoading: boolean;
  paymentsAPI: PaymentsLib | null;
} => {
  const [paymentsAPI, setPaymentsAPI] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);
  const hostname = getCurrentHostname();
  const url = getApiBaseUrl(hostname);

  const setConfig = useCallback(async (params: Arguments.ConfigArgs) => {
    try {
      if (window.PaymentsAPI) {
        await window.PaymentsAPI.config(params);
      }
    } catch (error) {
      console.log(error);
      captureException(error);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const startService = async () => {
      try {
        await setConfig({
          serverUrl: url,
          apiPrefix: '',
        });
        setLoading(false);
        window.PaymentsAPI && setPaymentsAPI(window.PaymentsAPI);
      } catch (e) {
        setLoading(false);
        captureException(e);
        console.log(e);
      }
    };

    startService();

    return () => {
      setLoading(false);
      setPaymentsAPI(null);
    };
  }, [setConfig, url]);

  return {
    isLoading,
    paymentsAPI,
  };
};

export default usePaymentsServiceV2;
