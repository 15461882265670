import { useAuth } from '@/hooks/auth';
import { useUpdateBonusSettings } from '@/hooks/bonuses';
import { Alert, Switch } from '@purposeinplay/core-v2';
import React, { useEffect, useState } from 'react';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';
import { useTranslation } from '@/app/i18n/client';
import { WalletContentType } from '@/types/json-content/wallet';

const BonusToggle = () => {
  const { t } = useTranslation();
  const contentWallet = t('wallet') as WalletContentType;
  const content = contentWallet?.modal?.deposit?.bonuses?.alert;

  const {
    user: { currentUser },
  } = useAuth();

  const [wantsBonus, setWantsBonus] = useState(
    currentUser?.can_issue_bonuses ? currentUser?.can_issue_bonuses : false,
  );

  useEffect(() => {
    setWantsBonus(currentUser?.can_issue_bonuses || false);
  }, [currentUser?.can_issue_bonuses]);

  const [open, setOpen] = useState(false);

  const { mutateAsync: updateBonusSettings } = useUpdateBonusSettings();

  const walletContent = useGetWalletContent();

  return (
    <>
      <div className="flex flex-wrap items-center justify-between text-sm font-semibold text-text-default">
        <div>{walletContent?.deposit?.bonuses?.toggle_title}</div>
        <div className="flex items-center space-x-2">
          <div className="font-normal">
            {walletContent?.deposit?.bonuses?.toggle}
          </div>
          <Switch
            size="large"
            checked={wantsBonus}
            onChange={() => {
              setOpen(true);
              setWantsBonus(!wantsBonus);
            }}
          />
        </div>
      </div>
      <Alert
        content={
          !wantsBonus
            ? content?.description?.disable
            : content?.description?.enable
        }
        title={!wantsBonus ? content?.title?.disable : content?.title?.enable}
        primaryButtonLabel={
          !wantsBonus
            ? content?.primary_btn_label?.disable
            : content?.primary_btn_label?.enable
        }
        secondaryButtonLabel={content?.secondary_btn_label}
        isOpen={open}
        setIsOpen={setOpen}
        primaryButtonOnClick={() => {
          updateBonusSettings(wantsBonus);
        }}
        secondaryButtonOnClick={() => {
          setOpen(false);
          setWantsBonus(currentUser?.can_issue_bonuses || false);
        }}
      />
    </>
  );
};

export default BonusToggle;
