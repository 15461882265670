import { useTranslation } from '@/app/i18n/client';
import InputGroup from '@/components/InputGroup';
import { useAuth } from '@/hooks/auth';
import {
  useActivateCouponBonus,
  useClearPreviewBonusCoupon,
  useSetPreviewBonusCoupon,
} from '@/hooks/bonuses';
import useModal, { useCurrentModal } from '@/hooks/modal';
import { BonusesContentType } from '@/types/json-content/bonuses';
import { Button, Input, Modal } from '@purposeinplay/core-v2';
import { useToast } from '@purposeinplay/utils';
import React, { useMemo, useState } from 'react';

interface Props {
  forModal?: boolean;
}

const PromoCode = React.memo(({ forModal }: Props) => {
  const { t } = useTranslation();
  const bonusesContent = t('bonuses') as BonusesContentType;
  const content = bonusesContent?.promo_code_modal;

  const { open: openModal, close } = useModal();

  const { mutateAsync: activateBonus, isPending: setBonusLoading } =
    useSetPreviewBonusCoupon();

  const {
    mutateAsync: activatCuponBonus,
    isPending: activatCuponBonusLoading,
  } = useActivateCouponBonus();

  const { mutateAsync: clearBonus, isPending: clearBonusLoading } =
    useClearPreviewBonusCoupon();

  const { user } = useAuth();

  const { toast } = useToast();

  const isOpen = useCurrentModal() === 'promo-code';

  const promoCode = useMemo(
    () => user?.currentUser?.deposit_bonus_code,
    [user],
  );
  const [code, setCode] = useState(forModal ? '' : promoCode);

  const handleOnClicked = () => {
    const handleActivation = (activationPromise: Promise<void>) => {
      activationPromise.then(() => {
        if (forModal) close();
      });
    };

    if (forModal) {
      handleActivation(activatCuponBonus(code));
    } else if (promoCode) {
      clearBonus({}).then(() => {
        toast({
          state: 'success',
          title: content?.toast_notif_desc?.remove,
        });
        if (forModal) close();
      });
    } else {
      activateBonus(code).then(() => {
        toast({
          state: 'success',
          title: content?.toast_notif_desc?.add,
        });
        if (forModal) close();
      });
    }
  };

  return (
    <>
      {forModal ? (
        <Modal
          title={content?.title}
          isOpen={isOpen}
          setIsOpen={() => close()}
          footer={
            <Button
              className="w-full"
              type="submit"
              onClick={handleOnClicked}
              isLoading={
                setBonusLoading || clearBonusLoading || activatCuponBonusLoading
              }
              disabled={
                setBonusLoading ||
                activatCuponBonusLoading ||
                clearBonusLoading ||
                code?.length === 0
              }
            >
              {content?.action_btn_label?.activate}
            </Button>
          }
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleOnClicked();
            }}
          >
            <div>
              <Input
                label={content?.input_label}
                placeholder={content?.input_placeholder}
                variant="static"
                onChange={(e) => setCode(e.target.value)}
                disabled={false}
                value={code}
              />
            </div>
          </form>
        </Modal>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleOnClicked();
          }}
        >
          <div className="flex w-full flex-row items-center">
            <InputGroup className="mb-0 mr-2 w-4/5">
              <Input
                placeholder={content?.input_placeholder}
                variant="static"
                onChange={(e) => setCode(e.target.value)}
                disabled={promoCode ? true : false}
                value={!promoCode ? code : promoCode}
              />
            </InputGroup>
            <Button
              type="submit"
              color="tertiary"
              className="w-1/5"
              isLoading={setBonusLoading || clearBonusLoading}
              disabled={setBonusLoading || clearBonusLoading}
            >
              {promoCode
                ? content?.action_btn_label.remove
                : content?.action_btn_label?.activate}
            </Button>
          </div>
        </form>
      )}
    </>
  );
});

PromoCode.displayName = 'PromoCode';

export default PromoCode;
