import { useMemo } from 'react';
import useWalletSelector from './use-wallet-selector';
import { useAccounts } from './accounts';
import { FormattedAccount } from '@/types/accounts';

export const useGetSortedAccounts = () => {
  const { formattedAccounts } = useWalletSelector();
  const { account: currentAccount } = useAccounts();
  const sortedAccounts = useMemo(() => {
    return [
      formattedAccounts?.find(
        (acc: FormattedAccount) =>
          acc.currency.toLowerCase() === currentAccount?.currency.toLowerCase(),
      ),
      ...formattedAccounts?.filter(
        (acc: FormattedAccount) =>
          acc.currency.toLowerCase() !== currentAccount?.currency.toLowerCase(),
      ),
    ];
  }, [formattedAccounts, currentAccount]);

  return { sortedAccounts };
};
