import { InputWithLabelLoader } from '@/components/Loaders';
import { ChangellyGetOffersResponse } from '@changelly/fiat-api-sdk-node';
import React from 'react';
import { useGetWalletContent } from '../../hooks/use-get-wallet-content';
import { Field, Input } from '@purposeinplay/core-v2';
import { getIconByCurrency } from '@/utils/helpers';

type Props = {
  offersLoading: boolean;
  changellyOffers: ChangellyGetOffersResponse[] | undefined;
  selectedCurrency: string | undefined;
};

const ChangellyOffers: React.FC<Props> = React.memo(
  ({ offersLoading, changellyOffers, selectedCurrency }) => {
    const walletContent = useGetWalletContent();
    return offersLoading ? (
      <InputWithLabelLoader />
    ) : (
      changellyOffers &&
        !changellyOffers[0]?.errorDetails &&
        !changellyOffers[0]?.errorMessage && (
          <Field>
            <Input
              key={`offer-${changellyOffers[0]?.amountExpectedTo}`}
              variant="static"
              label={walletContent?.buy_crypto?.input?.get}
              placeholder={changellyOffers[0]?.amountExpectedTo}
              disabled
              id="crypto-value"
              leadingIcon={getIconByCurrency(selectedCurrency as string)}
            />
          </Field>
        )
    );
  },
);

ChangellyOffers.displayName = 'ChangellyOffers';

export default ChangellyOffers;
