import { Button, Modal } from '@purposeinplay/core-v2';
import React from 'react';
import { useGetWalletContent } from '../Wallet/WalletModal/hooks/use-get-wallet-content';

const ConfirmationModal = ({
  title,
  content,
  isOpen,
  setIsOpen,
  onClickPrimary,
  isPrimaryLoading,
}: {
  title: React.ReactNode;
  content: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (param: boolean) => void;
  onClickPrimary?: () => void;
  isPrimaryLoading?: boolean;
}) => {
  const walletContent = useGetWalletContent();
  const FooterButtons = () => (
    <div className="flex flex-row items-center justify-end space-x-1">
      <Button isLoading={isPrimaryLoading} onClick={onClickPrimary}>
        {walletContent?.transactions?.cancel_withdrawal_modal?.action?.primary}
      </Button>
      <Button onClick={() => setIsOpen(false)} color="tertiary">
        {
          walletContent?.transactions?.cancel_withdrawal_modal?.action
            ?.secondary
        }
      </Button>
    </div>
  );
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title={title}
      footer={<FooterButtons />}
    >
      {content}
    </Modal>
  );
};

export default ConfirmationModal;
