import useFiatFromCents from '@/hooks/fiat';
import { useAppSelector } from '@/hooks/redux';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import React from 'react';

const TrailingSelectItem = ({
  currency,
  balance,
}: {
  currency: any;
  balance: any;
}) => {
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
  const fiat = useFiatFromCents(currency, balance);
  const { formatCurrency } = useCurrencyFormatter();

  return (
    <div className="flex items-center space-x-1  text-sm font-medium text-text-default">
      <span>{viewFiat ? fiat : formatCurrency(balance, currency)}</span>
    </div>
  );
};

export default TrailingSelectItem;
