import React from 'react';
import Link from 'next/link';
import { Button } from '@purposeinplay/core-v2';
import { useTranslation } from 'react-i18next';
import { WalletContentType } from '@/types/json-content/wallet';

const CoolOffComponent = () => {
  const { t } = useTranslation();
  const walletContent = t('wallet') as WalletContentType;
  const content = walletContent?.modal?.deposit?.cool_off_limit;

  return (
    <div className="mt-8">
      <p className="mb-2">{content?.title}</p>
      <Link
        className="link-button link-button--primary"
        href="/settings/limits"
        passHref
        prefetch={false}
      >
        {content?.action}
      </Link>
    </div>
  );
};

export default CoolOffComponent;
