import { useGetFoundCurrencyDeposit } from '@/hooks/use-get-found-currency-deposit';
import useUpdateMissingCurrency from '@/hooks/use-update-missing-currency';
import { CommissionType, Payment } from 'payments-lib-types';
import React, { useMemo } from 'react';
import {
  useFiatFee,
  useFiatMaxCashout,
  useFiatMinCashout,
} from '../../../hooks/withdraw-hooks';
import { useAppSelector } from '@/hooks/redux';
import { getIconByCurrency } from '@/utils/helpers';
import CryptoTooltip from '@/components/CryptoTooltip';
import { useSearchParams } from 'next/navigation';
import { TextLoader } from '@/components/Loaders';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import { useCentsFromCrypto } from '@/hooks/fiat';

const WithdrawDetails = ({
  paymentMethod,
}: {
  paymentMethod: Payment.Method;
}) => {
  const { foundCurrencyDeposit: foundCurrency } = useGetFoundCurrencyDeposit();
  const searchParams = useSearchParams();
  const currency = searchParams.get('c') || 'BTC';
  const { formatCurrency } = useCurrencyFormatter();

  const walletContent = useGetWalletContent();

  const fiatMinCashout = useFiatMinCashout(
    paymentMethod?.termsOfService?.restrictions?.minAmountValue,
    foundCurrency?.code,
  );

  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
  const { formattedBalance: minWithdrawCrypto } = useCentsFromCrypto(
    foundCurrency?.code,
    paymentMethod?.termsOfService?.restrictions?.minAmountValue as number,
  );
  const minWithdraw = useMemo(() => {
    if (viewFiat && fiatMinCashout) {
      return fiatMinCashout;
    }
    return minWithdrawCrypto;
  }, [viewFiat, fiatMinCashout, minWithdrawCrypto]);

  const commissionFixed =
    paymentMethod?.termsOfService?.commissions?.type === CommissionType.Fixed
      ? paymentMethod?.termsOfService?.commissions.cents
      : null;

  const fiatFee = useFiatFee(commissionFixed, foundCurrency?.code);

  const fee = useMemo(() => {
    if (viewFiat && fiatFee) {
      return fiatFee;
    }
    if (!commissionFixed) return '0.00';
    return formatCurrency(commissionFixed, foundCurrency?.code as string);
  }, [commissionFixed, fiatFee, formatCurrency, foundCurrency?.code, viewFiat]);

  const isData = useMemo(() => {
    return (
      paymentMethod?.termsOfService?.restrictions?.minAmountValue !== undefined
    );
  }, [paymentMethod?.termsOfService?.restrictions?.minAmountValue]);

  return (
    <div className="mb-4 flex flex-col">
      <div className="flex items-center justify-between">
        <div className="flex flex-wrap text-xs text-text-subdued">
          <span className="mr-1">
            {walletContent?.withdraw?.amount?.min_withdrawal}
          </span>
          {isData ? (
            <CryptoTooltip cryptoAmount={minWithdraw} cryptoCode={currency}>
              {paymentMethod?.termsOfService?.restrictions?.minAmountValue}
              <span className="ml-1 inline-block h-5 w-5 text-center align-middle">
                {getIconByCurrency(currency?.toLowerCase())}
              </span>
            </CryptoTooltip>
          ) : (
            <TextLoader className="inline-block w-20" />
          )}
          .
          <div className="ml-0.5">
            {' '}
            {walletContent?.withdraw?.amount?.transaction_fee} {fee}
          </div>
          <span className="ml-1 inline-block h-5 w-5 text-center align-middle">
            {getIconByCurrency(currency?.toLowerCase())}
          </span>
          .
        </div>
      </div>
    </div>
  );
};

export default WithdrawDetails;
