import React, { memo, useCallback, useState } from 'react';
import { useGetCryptoDepositInfo } from '@/hooks/paymentsv2';
import { useGetFoundCurrencyDeposit } from '@/hooks/use-get-found-currency-deposit';
import useWalletSelector from '@/hooks/use-wallet-selector';
import CopySVG from 'public/assets/wallet/copy.svg';
import QRSVG from 'public/assets/wallet/qr.svg';
import copy from 'copy-to-clipboard';
import { Tooltip } from '@purposeinplay/core-v2';
import { IconButton } from '@purposeinplay/core-v2';
import DepositQR from './deposit-qr';
import { TextLoader } from '@/components/Loaders';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';
import useMediaQuery, { MOBILE_BREAKPOINT } from '@/hooks/use-media-query';
import { useToast } from '@purposeinplay/utils';

const Trigger = memo(
  ({
    onClick,
    onMouseLeave,
  }: {
    onClick: () => void;
    onMouseLeave: () => void;
  }) => {
    return (
      <IconButton
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        className="group h-8 w-8"
        ghost
        color="primary"
      >
        <CopySVG className="h-5 w-5 fill-current text-text-default group-hover:fill-current group-hover:text-base-primary" />
      </IconButton>
    );
  },
);

Trigger.displayName = 'Trigger';

const MandatoryData = () => {
  const [hasCopied, setHasCopied] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const { foundCurrencyDeposit: foundCurrency } = useGetFoundCurrencyDeposit();
  const { formattedAccounts } = useWalletSelector();
  const { depositInfoAdress, depositInfoHref } = useGetCryptoDepositInfo();
  const { matchesQuery: isMobile } = useMediaQuery(MOBILE_BREAKPOINT);

  const walletContent = useGetWalletContent();

  const { toast } = useToast();

  const handleClick = useCallback(() => {
    copy(depositInfoAdress);
    setHasCopied(true);
    if (isMobile) {
      toast({
        state: 'success',
        title: walletContent?.deposit?.address?.copy?.toast,
      });
    }
  }, [depositInfoAdress, isMobile, toast, walletContent]);

  const handleMouseLeave = useCallback(() => {
    setHasCopied(false);
  }, []);

  return (
    <div className="w-full">
      <div>
        <div>
          {foundCurrency && depositInfoAdress ? (
            <p className="mb-2 text-sm font-semibold text-text-default">{`${
              foundCurrency
                ? foundCurrency?.code.toUpperCase()
                : formattedAccounts[0]?.currency.toUpperCase()
            } Deposit Address`}</p>
          ) : (
            <TextLoader className="mb-2 h-6 w-40" />
          )}
        </div>
      </div>
      {showQR && <DepositQR depositInfoHref={depositInfoHref} />}

      <div className="w-full rounded-xl bg-surface-default px-3 py-[11px] text-text-default">
        <div className="flex w-full items-center justify-between space-x-1">
          <div className="flex-1 flex-wrap truncate text-sm font-medium sm:break-all">
            {depositInfoAdress ? (
              depositInfoAdress
            ) : (
              <TextLoader className="h-6 lg:w-full" />
            )}
          </div>
          <div className="flex flex-row space-x-1">
            {isMobile ? (
              <Trigger onClick={handleClick} onMouseLeave={handleMouseLeave} />
            ) : (
              <Tooltip
                styleOptions={{
                  arrowStyles: '!z-[99999]',
                  popperStyles: '!z-[99999]',
                }}
                placement="top"
                displayOn="hover"
                trigger={
                  <Trigger
                    onClick={handleClick}
                    onMouseLeave={handleMouseLeave}
                  />
                }
              >
                {hasCopied
                  ? walletContent?.deposit?.address?.copy?.toast
                  : walletContent?.deposit?.address?.copy?.tooltip}
              </Tooltip>
            )}

            <IconButton
              onClick={() => setShowQR((prev) => !prev)}
              className="group h-8 w-8"
              ghost
              color="primary"
            >
              <QRSVG className="fill-current text-text-default group-hover:fill-current group-hover:text-base-primary" />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MandatoryData;
