import InputGroup from '@/components/InputGroup';
import { SigninModal } from '@/types/json-content/auth';
import { Field, Input, Label, PinInput } from '@purposeinplay/core-v2';
import React, { useEffect } from 'react';
import { StateType } from '../../signin-form';
import { handleError } from '@/utils/helpers';
import { cn } from '@/utils/style';

type Props = {
  content: SigninModal;
  isError: boolean;
  isLoading: boolean;
  showOtp: boolean;
  error: { errors: Record<string, string> };
  state: StateType;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, type: string) => void;
};

const OtpFormField: React.FC<Props> = React.memo(
  ({ showOtp, error, isError, content, handleChange, state, isLoading }) => {
    useEffect(() => {
      if (
        isError &&
        error?.errors &&
        handleError(error.errors, 'otp_attempt')
      ) {
        // reset input
        // @ts-ignore
        handleChange({ target: { value: '' } }, 'otp_attempt');
      }
    }, [error?.errors, handleChange, isError]);

    if (!showOtp) return null;
    const handleOtpChange = (otp: string) =>
      // @ts-ignore
      handleChange({ target: { value: otp } }, 'otp_attempt');

    return (
      <Field
        className={cn([
          'w-full',
          isError && handleError(error.errors, 'otp_attempt') && 'mb-12',
        ])}
      >
        <Label htmlFor="otpField">
          {content?.form?.authentication_code?.label}
        </Label>
        <PinInput
          isHidden
          fields={6}
          inputMode="number"
          disabled={isLoading}
          onChange={handleOtpChange}
          error={isError && handleError(error.errors, 'otp_attempt')}
          errorMessage={isError && handleError(error?.errors, 'otp_attempt')}
          value={state.otp_attempt}
          stylingOptions={{ wrapperStyles: 'max-h-10' }}
        />
      </Field>
    );
  },
);

OtpFormField.displayName = 'OtpFormField';

export default OtpFormField;
