export function filterItemsByBalance(items: any, hideZeroBalances: boolean) {
  if (hideZeroBalances) {
    return items.filter((item: any) => {
      if (item.id === 'BTC') {
        return true;
      } else {
        //@ts-ignore
        return parseFloat(item.trailing.props.balance.split(' ')[0]) !== 0;
      }
    });
  } else {
    return items;
  }
}
