import useWalletSelector from '@/hooks/use-wallet-selector';
import React, { useEffect, useMemo, useState } from 'react';
import { ACTIONS } from '../../constants';
import { usePlayerTransactions } from '@/hooks/transactions';
import { useUnmount } from '@purposeinplay/utils';
import { useSearchParams } from 'next/navigation';
import { TableLoaderV2 } from '@/components/Loaders';
import { Button, Select, Table } from '@purposeinplay/core-v2';
import MobileDropdown from '@/components/MobileDropdown';
import TransactionTableRow from './components/transaction-table-row';
import { TransactionProps } from '../../types';
import EmptyState from '@/components/EmptyState';
import useMediaQuery from '@/hooks/use-media-query';
import { cn } from '@/utils/style';
import { useGetWalletContent } from '../../hooks/use-get-wallet-content';
import { MOBILE_BREAKPOINT } from '@/hooks/use-media-query';
import { useTranslation } from '@/app/i18n/client';
import { WalletContentType } from '@/types/json-content/wallet';

const Transactions = ({}) => {
  const searchParams = useSearchParams();
  const currency = searchParams.get('c');

  const { t } = useTranslation();
  const wContent = t('wallet') as WalletContentType;
  const content = wContent?.modal?.transactions;

  const { formattedAccounts: currencies = [] }: any = useWalletSelector();

  const formattedCurrencies = useMemo(() => {
    if (currencies) {
      return currencies.map((currency: any) => ({
        id: currency.currency.toUpperCase(),
        component: currency.currency,
      }));
    }
  }, [currencies]);

  const [selectedCurrencies, setSelectedCurrencies] = useState([] as any[]);

  const [selectedActions, setSelectedActions] = useState<any>(ACTIONS);
  const [firstTimeFetch, setFirstTimeFetch] = useState(true);
  const [open, setOpen] = useState(false);
  const actionPayload = useMemo(
    () =>
      selectedActions.length > 0 &&
      selectedActions.map((action: any) => action.id.toLowerCase()).join(','),
    [selectedActions],
  );

  const currencyPayload = useMemo(
    () =>
      selectedCurrencies.length > 0
        ? selectedCurrencies.map((currency: any) => currency?.id).join(',')
        : null,
    [selectedCurrencies],
  );

  const { data, isLoading, fetchNextPage, isFetching } = usePlayerTransactions({
    offset: 0,
    action: actionPayload,
    currency: !currencyPayload ? 'all' : currencyPayload,
    limit: 10,
  });

  useUnmount(() => {
    setSelectedCurrencies([]);
  });

  useEffect(() => {
    if (data?.transactions) {
      setFirstTimeFetch(false);
    }
  }, [
    isLoading,
    data?.transactions,
    currency,
    selectedCurrencies,
    firstTimeFetch,
  ]);

  const handleCurrencySelect = (selectedItem: any) => {
    const isSelected = selectedCurrencies.some(
      (item: any) => item.id === selectedItem.id,
    );
    if (isSelected) {
      const updatedCurrencies = selectedCurrencies.filter(
        (item: any) => item.id !== selectedItem.id,
      );
      setSelectedCurrencies(updatedCurrencies);
    } else {
      setSelectedCurrencies([...selectedCurrencies, selectedItem]);
    }
  };

  const handleActionSelect = (selectedItem: any) => {
    if (selectedActions.length === 3) {
      setSelectedActions([selectedItem]);
    } else {
      const isSelected = selectedActions.some(
        (item: any) => item.id === selectedItem.id,
      );

      if (isSelected) {
        const updatedActions = selectedActions.filter(
          (item: any) => item.id !== selectedItem.id,
        );
        setSelectedActions(
          updatedActions.length > 0 ? updatedActions : ACTIONS,
        );
      } else {
        setSelectedActions([...selectedActions, selectedItem]);
      }
    }
  };

  const hasMoreTransactions = useMemo(() => {
    return data?.transactions?.length !== data?.totalCount;
  }, [data?.transactions, data?.totalCount]);

  const hasNoTransactions = useMemo(() => {
    return data?.transactions?.length == 0 && !isLoading;
  }, [data?.transactions?.length, isLoading]);

  const isLoadingOrFirstFetch = useMemo(() => {
    return firstTimeFetch;
  }, [firstTimeFetch]);

  const walletContent = useGetWalletContent();

  const { matchesQuery: isMobile } = useMediaQuery(MOBILE_BREAKPOINT);

  const headers = useMemo(() => {
    const headings = [
      walletContent?.transactions?.table?.heading1,
      walletContent?.transactions?.table?.heading2,
    ];

    return isMobile
      ? headings
      : [...headings, walletContent?.transactions?.table?.heading3];
  }, [
    isMobile,
    walletContent?.transactions?.table?.heading1,
    walletContent?.transactions?.table?.heading2,
    walletContent?.transactions?.table?.heading3,
  ]);

  const renderTable = useMemo(() => {
    if (hasNoTransactions) {
      return (
        <EmptyState
          title={walletContent?.transactions?.no_results?.title}
          description={walletContent?.transactions?.no_results?.description}
          iconName="wallet"
        />
      );
    } else if (isLoadingOrFirstFetch) {
      return (
        <TableLoaderV2
          tableRowProps={cn([isMobile ? 'h-16' : 'h-12'])}
          headers={isMobile ? 2 : 3}
        />
      );
    } else {
      return (
        <div>
          <Table
            stylingOptions={{
              containerStyles: 'p-0 mb-7',
            }}
            headers={headers}
          >
            {data?.transactions?.map((transaction: TransactionProps) => (
              <TransactionTableRow
                key={`${transaction.created_at}-${transaction.amount_cents}`}
                transaction={transaction}
              />
            ))}
          </Table>

          {hasMoreTransactions && (
            <Button
              isLoading={isFetching}
              className="mx-auto"
              color="tertiary"
              onClick={() => {
                fetchNextPage();
              }}
            >
              {walletContent?.transactions?.load_more}
            </Button>
          )}
        </div>
      );
    }
  }, [
    hasNoTransactions,
    isLoadingOrFirstFetch,
    walletContent?.transactions?.no_results?.title,
    walletContent?.transactions?.no_results?.description,
    walletContent?.transactions?.load_more,
    isMobile,
    headers,
    data?.transactions,
    hasMoreTransactions,
    isFetching,
    fetchNextPage,
  ]);

  return (
    <div className="px-1 lg:px-0">
      <>
        <div>
          <MobileDropdown
            className="mb-3 lg:block"
            title={content?.title}
            open={open}
            setOpen={setOpen}
          >
            <Select
              disablePillsForMulti
              placeholder={
                walletContent?.transactions?.filters?.action_select?.placeholder
              }
              onSelect={handleActionSelect}
              items={ACTIONS}
              multi
            />
            <Select
              disablePillsForMulti
              placeholder={
                walletContent?.transactions?.filters?.currency_select
                  ?.placeholder
              }
              onSelect={handleCurrencySelect}
              items={formattedCurrencies}
              multi
            />
          </MobileDropdown>
        </div>
        {renderTable}
      </>
    </div>
  );
};

export default Transactions;
