import AdaptiveImage from '@/components/AdaptiveImage';
import {
  Button,
  Checkbox,
  ErrorMessage,
  Field,
  Label,
  ListComponent,
  Text,
} from '@purposeinplay/core-v2';
import React, { useState } from 'react';
import { FormDataType } from '.';
import { getIconByCurrency } from '@/utils/helpers';
import Link from 'next/link';
import { useGetWalletContent } from '../../hooks/use-get-wallet-content';

type Props = {
  formData: FormDataType;
  createOrderPending: boolean;
  currentIpLoading: boolean;
  currency: string | undefined;
  fiatCurrency: string | undefined;
  changellyOffers: any[] | undefined;
  handleSubmit: () => Promise<void>;
  resetForm: () => void;
  setConfirmPurchase: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConfirmPurchase: React.FC<Props> = React.memo(
  ({
    formData,
    currency,
    fiatCurrency,
    createOrderPending,
    currentIpLoading,
    handleSubmit,
    changellyOffers,
    resetForm,
    setConfirmPurchase,
  }) => {
    const walletContent = useGetWalletContent();
    const confirmContent = walletContent?.buy_crypto?.confirm_purchase;
    const [agreed, setAgreed] = useState(false);
    const [error, setError] = useState<string | null>(null);

    return (
      <div className="space-y-spacing-sm">
        <Field>
          <Label>{confirmContent?.offers}</Label>
          {changellyOffers?.map((offer) => (
            <div className="space-y-2" key={`offer-${offer?.providerCode}`}>
              {offer?.paymentMethodOffer?.map((paymentOffer: any) => {
                return (
                  <div
                    key={`paymentoffer-${offer?.providerCode}-${paymentOffer?.methodName}`}
                    className="w-full space-y-1 rounded-xl text-text-default"
                  >
                    <ListComponent
                      label={confirmContent?.method_name}
                      stylingOptions={{
                        containerStyles:
                          'cursor-default bg-surface-subdued hover:cursor-default hover:bg-surface-subdued',
                        valueStyles: 'text-right font-semibold',
                        labelStyles: 'text-text-subdued',
                      }}
                      value={paymentOffer?.methodName}
                    />
                    <ListComponent
                      label={confirmContent?.amount_expected}
                      stylingOptions={{
                        containerStyles:
                          'cursor-default bg-surface-subdued hover:cursor-default hover:bg-surface-subdued',
                        valueStyles: 'text-right font-semibold',
                        labelStyles: 'text-text-subdued',
                      }}
                      value={
                        <span className="mx-auto inline-flex flex-row items-center">
                          {paymentOffer?.amountExpectedTo}{' '}
                          <span className="ml-1">
                            {getIconByCurrency(
                              currency?.toLowerCase() as string,
                            )}
                          </span>
                        </span>
                      }
                    />
                    <ListComponent
                      label={confirmContent?.fee}
                      stylingOptions={{
                        containerStyles:
                          'cursor-default bg-surface-subdued hover:cursor-default hover:bg-surface-subdued',
                        valueStyles: 'text-right font-semibold',
                        labelStyles: 'text-text-subdued',
                      }}
                      value={`${parseFloat(paymentOffer?.fee).toFixed(2)} ${fiatCurrency}`}
                    />
                  </div>
                );
              })}
            </div>
          ))}
        </Field>
        <Field>
          <Text size="xs" className="text-text-subdued">
            {confirmContent?.disclaimer?.replaceAll(
              '{{provider}}',
              formData?.providerCode as string,
            )}{' '}
            <Link href="mailto:support@wild.io">support@wild.io</Link>.
            {confirmContent?.disclaimer_responsability?.replaceAll(
              '{{provider}}',
              formData?.providerCode as string,
            )}
          </Text>
        </Field>
        <Field>
          <Checkbox
            id="purchase-agree"
            //@ts-ignore
            onChange={() => {
              setAgreed(!agreed);
            }}
            checked={agreed}
            label={confirmContent?.aggree}
            onClick={(value) => {
              setAgreed((prev) => !prev);
              if (error) {
                setError(null);
              }
            }}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Field>
        <div className="grid grid-cols-3 place-items-center gap-1">
          <Button
            onClick={async () => {
              if (!agreed) {
                setError(confirmContent?.error);
                return;
              }
              if (error) {
                setError(null);
              }
              await handleSubmit();
            }}
            isLoading={createOrderPending}
            disabled={
              !agreed ||
              createOrderPending ||
              formData.amount === '' ||
              currentIpLoading ||
              changellyOffers?.length === 0
            }
            className="col-span-3 w-full md:col-span-1"
          >
            {confirmContent?.actions?.confirm}
          </Button>
          <Button
            color="tertiary"
            className="col-span-3 w-full md:col-span-1"
            onClick={async () => {
              resetForm();
              setConfirmPurchase(false);
            }}
          >
            {confirmContent?.actions?.cancel}
          </Button>
        </div>
      </div>
    );
  },
);

ConfirmPurchase.displayName = 'FiatConfirmPurchase';

export default ConfirmPurchase;
