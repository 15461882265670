import React, { Dispatch, SetStateAction } from 'react';
import { cn } from '@/utils/style';
import { handleError } from '@/utils/helpers';
import { FieldGroup, Input } from '@purposeinplay/core-v2';

const PasswordInput = React.memo(
  ({
    quick,
    content,
    password,
    isError,
    errors,
    handleChange,
    onValidityChange,
  }: {
    quick: boolean;
    content: any;
    isError: boolean;
    password: string;
    errors: any;
    handleChange: (e: any, field: string) => void;
    onValidityChange?: Dispatch<SetStateAction<boolean>>;
  }) => {
    return (
      <FieldGroup className={cn([quick && `relative mb-4 md:mb-4`])}>
        <Input
          onValidityChange={onValidityChange}
          hidePasswordStrength={false}
          maxLength={50}
          id="password"
          type="password"
          autoComplete="on"
          label={content?.form?.password?.label}
          placeholder={content?.form?.password?.placeholder}
          value={password}
          error={isError && handleError(errors, 'password')}
          errorMessage={isError && handleError(errors, 'password')}
          onChange={(e) => handleChange(e, 'password')}
          variant="secure"
          stylingOptions={{
            secure: {
              tooltip: {
                arrowStyles:
                  'before:!-left-36 before:sm:!-left-32 before:xl:!-left-[8.5rem] before:!right-auto',
              },
            },
          }}
        />
      </FieldGroup>
    );
  },
);

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
