import { useChangellyCurrencies } from '@/hooks/changelly';
import { ChangellyProvider } from '@changelly/fiat-api-sdk-node';
import { useMemo } from 'react';

export const useFiatCurrencies = (providerCode: ChangellyProvider | null) => {
  const { data: changellyCurrencies, isLoading } =
    useChangellyCurrencies(providerCode);
  const fiatCurrencies = useMemo(() => {
    if (isLoading || !changellyCurrencies) return [];
    return changellyCurrencies
      ?.filter((currency) => currency?.type === 'fiat')
      ?.map((item) => ({
        id: item.ticker ?? '',
        component: (
          <div
            key={`fiat-currency-${item?.ticker}`}
            className="flex items-center justify-between"
          >
            <span className="capitalize">{item.ticker ?? 'Unknown'}</span>
          </div>
        ),
        value: item.ticker ?? '',
      }));
  }, [changellyCurrencies, isLoading]);

  return { fiatCurrencies, isLoading };
};
