import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { SessionLimit, UserLimit } from '@/types/user';
import React from 'react';
import client from '@/utils/api';
import { GENERAL_ERROR } from '@/utils/constants';
import { useAuth } from './auth';
import { Snackbar } from '@purposeinplay/core-v2';
import { useToast } from '@purposeinplay/utils';
import { useTranslation } from '@/app/i18n/client';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';

const usePlayerLimits = (): UseQueryResult<UserLimit[] | SessionLimit[]> => {
  const {
    user: { currentUser, isLoading: userLoading },
  }: any = useAuth();
  return useQuery({
    queryKey: ['playerLimits'],
    queryFn: () => client('user_limits'),
    staleTime: 300000,
    enabled: !userLoading && currentUser?.email ? true : false,
  });
};

const usePlayerLimitsOptions = (): UseQueryResult<any> => {
  const {
    user: { currentUser, isLoading: userLoading },
  }: any = useAuth();
  return useQuery({
    queryKey: ['playerLimitsOptions'],
    queryFn: () => client('info/user_limits_options'),
    enabled: !userLoading && currentUser?.email ? true : false,
  });
};

const useAddPlayerLimit = (): UseMutationResult => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => {
      const { user_limit } = data;
      try {
        return client('user_limits', { user_limit }, 'POST');
      } catch (e) {
        throw e;
      }
    },
    onSuccess: () => {
      toast({
        state: 'success',
        title: toastMessages?.limit_created,
      });
      queryClient.invalidateQueries({ queryKey: ['playerLimits'] });
    },
    onError: (e: any) => {
      if (!e?.errors?.period) {
        toast({
          state: 'error',
          title: toastMessages?.general_error,
        });
      }
      queryClient.invalidateQueries({ queryKey: ['playerLimits'] });
    },
  });
};

const useConfirmPlayerLimit = (): UseMutationResult => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => {
      const { token } = data;
      try {
        return client('user_limits/confirm', { limit_token: token }, 'POST');
      } catch (e) {
        throw e;
      }
    },
    onSuccess: () => {
      toast({
        state: 'success',
        title: toastMessages?.limit_confirmed,
      });
      queryClient.invalidateQueries({ queryKey: ['playerLimits'] });
    },
    onError: (e: any) => {
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
      queryClient.invalidateQueries({ queryKey: ['playerLimits'] });
    },
  });
};

const useRemoveUserLimit = () => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (limitId: number) => {
      try {
        return client(`user_limits/${limitId}`, {}, 'DELETE');
      } catch (e) {
        throw e;
      }
    },
    onSuccess: () => {
      toast({
        state: 'success',
        title: toastMessages?.limit_removed,
      });
      queryClient.invalidateQueries({ queryKey: ['playerLimits'] });
    },
    onError: () => {
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
      queryClient.invalidateQueries({ queryKey: ['playerLimits'] });
    },
  });
};

export {
  usePlayerLimits,
  useAddPlayerLimit,
  useRemoveUserLimit,
  usePlayerLimitsOptions,
  useConfirmPlayerLimit,
};
