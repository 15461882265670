import React, { useMemo } from 'react';

import { useAppSelector } from '@/hooks/redux';
import WalletContentTitle from './wallet-content-title';
import BetDetails from './Transactions/components/bet-details';
import PaymentDetails from './Transactions/components/payment-details';
import WalletContent from './wallet-content';
import { useGetWalletContent } from '../hooks/use-get-wallet-content';
import BonusSelectionDetails from '@/components/Bonuses/components/bonus-preview/bonus-selection-details';
import BonusDetails from './Deposit/components/bonus-details';
import { useAuth } from '@/hooks/auth';
import { WELCOME_PROMO_CODES } from '@/utils/constants';

const WalletContentRenderer = React.memo(
  ({ isStandalone = false }: { isStandalone?: boolean }) => {
    const walletDynamicContent = useAppSelector(
      (state) => state.bootstrap.walletDynamicContent,
    );

    const walletContent = useGetWalletContent();

    const { user } = useAuth();

    const promoCode = useMemo(
      () => user?.currentUser?.deposit_bonus_code,
      [user],
    );

    switch (walletDynamicContent.isOpen) {
      case 'details':
        return (
          <div>
            <WalletContentTitle
              type="wallet"
              title={
                walletContent?.transactions?.transaction_item?.details_modal
                  ?.title
              }
            />
            {walletDynamicContent?.data?.type === 'bet' ? (
              <BetDetails transaction={walletDynamicContent.data} />
            ) : (
              <PaymentDetails transaction={walletDynamicContent.data} />
            )}
          </div>
        );
      case 'default':
        return (
          <div>
            <div className="mb-3 text-base  font-medium leading-6 text-text-default md:mb-4 md:text-lg md:font-semibold lg:mb-7">
              {walletContent?.title}
            </div>
            <WalletContent isStandalone={isStandalone} />
          </div>
        );
      case 'bonus-details':
        return (
          <div>
            <WalletContentTitle
              backBtnHref="default"
              type="wallet"
              title={walletContent?.title}
            />
            {WELCOME_PROMO_CODES.includes(promoCode?.toUpperCase()) ? (
              <BonusSelectionDetails type="wallet" />
            ) : (
              <BonusDetails isInWallet={true} />
            )}
          </div>
        );
      default:
        return null;
    }
  },
);

WalletContentRenderer.displayName = 'WalletContentRenderer';

export default WalletContentRenderer;
