import { useBetInfo } from '../../../hooks/use-bet-info';
import { Badge, Text } from '@purposeinplay/core-v2';
import dayjs from '@/utils/dayjsconfig';
import { ListComponent } from '@purposeinplay/core-v2';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';
import { isValidBadgeType, useCalculateTransactionType } from '../utils';
import FiatAmount from '@/components/FiatAmount';
import { DateFormat } from '@/utils/date';
import CryptoTooltip from '@/components/CryptoTooltip';

const BetDetails = ({ transaction }: { transaction: any }) => {
  const foundCurrency = transaction?.source_currency || transaction.currency;

  const betInfo = useBetInfo(foundCurrency, transaction);

  const currency = transaction?.source_currency || transaction?.currency;

  const walletContent = useGetWalletContent();

  const transactionType = useCalculateTransactionType(transaction);

  const listComponentStyles = {
    valueStyles: 'text-right',
    containerStyles:
      'bg-surface-default hover:bg-surface-default !cursor-default  [&>span]:p-2 mb-1 rounded-lg',
  };

  return (
    <div>
      <ListComponent
        stylingOptions={listComponentStyles}
        label={
          <Text size="sm">
            {
              walletContent?.transactions?.transaction_item?.details_modal
                ?.amount
            }
          </Text>
        }
        value={
          <Text
            size="sm"
            className="flex items-center justify-end  text-text-default"
          >
            <CryptoTooltip
              cryptoAmount={betInfo?.formattedProfit}
              cryptoCode={currency}
            >
              <FiatAmount
                fiatAmount={betInfo?.fiatProfit}
                currency={currency}
              />
            </CryptoTooltip>
          </Text>
        }
      />
      <ListComponent
        stylingOptions={listComponentStyles}
        label={
          <Text className="capitalize" size="sm">
            {walletContent?.transactions?.transaction_item?.details_modal?.type}
          </Text>
        }
        value={
          <Text size="sm" className="text-right capitalize text-text-default">
            {transaction?.type}
          </Text>
        }
      />
      <ListComponent
        stylingOptions={listComponentStyles}
        label={
          <Text size="sm">
            {
              walletContent?.transactions?.transaction_item?.details_modal
                ?.status
            }
          </Text>
        }
        value={
          <Badge
            size="md"
            radius="rounded"
            type={
              isValidBadgeType(transactionType?.tag)
                ? transactionType?.tag
                : 'neutral'
            }
          >
            {transaction?.status?.type}
          </Badge>
        }
      />
      <ListComponent
        stylingOptions={listComponentStyles}
        label={
          <Text size="sm">
            {
              walletContent?.transactions?.transaction_item?.details_modal
                ?.created_at
            }
          </Text>
        }
        value={
          <Text size="sm" className="ml-auto text-text-default">
            {dayjs(transaction.created_at).format(DateFormat.DATE_WITH_TIME)}
          </Text>
        }
      />

      {transaction.finished_at && (
        <ListComponent
          stylingOptions={listComponentStyles}
          label={
            <Text size="sm">
              {
                walletContent?.transactions?.transaction_item?.details_modal
                  ?.finished_at
              }
            </Text>
          }
          value={
            <Text size="sm" className="ml-auto text-text-default">
              {dayjs(transaction.finished_at).format(DateFormat.DATE_WITH_TIME)}
            </Text>
          }
        />
      )}

      <ListComponent
        stylingOptions={listComponentStyles}
        label={
          <Text size="sm">
            {walletContent?.transactions?.transaction_item?.details_modal?.game}
          </Text>
        }
        value={
          <Text size="sm" className="ml-auto text-text-default">
            {transaction.title}
          </Text>
        }
      />

      <ListComponent
        stylingOptions={listComponentStyles}
        label={
          <Text size="sm">
            {
              walletContent?.transactions?.transaction_item?.details_modal
                ?.total_bets
            }
          </Text>
        }
        value={
          <Text
            size="sm"
            className="ml-auto flex items-center justify-end text-text-default"
          >
            <CryptoTooltip
              cryptoAmount={betInfo?.formattedTotalBets}
              cryptoCode={currency}
            >
              <FiatAmount
                fiatAmount={betInfo?.fiatTotalBets}
                currency={currency}
              />
            </CryptoTooltip>
          </Text>
        }
      />

      <ListComponent
        stylingOptions={listComponentStyles}
        label={
          <Text size="sm">
            {
              walletContent?.transactions?.transaction_item?.details_modal
                ?.total_wins
            }
          </Text>
        }
        value={
          <Text
            size="sm"
            className="ml-auto flex items-center justify-end text-text-default"
          >
            <CryptoTooltip
              cryptoAmount={betInfo?.formattedTotalWins}
              cryptoCode={currency}
            >
              <FiatAmount
                fiatAmount={betInfo?.fiatTotalWins}
                currency={currency}
              />
            </CryptoTooltip>
          </Text>
        }
      />
    </div>
  );
};

export default BetDetails;
