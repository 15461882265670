import { cn } from '@/utils/style';
import React from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
};

const InputGroup: React.FC<Props> = ({ className, children, ...rest }) => {
  return (
    <div className={cn(['mb-4', className])} {...rest}>
      {children}
    </div>
  );
};

export default InputGroup;
