import React, { useMemo } from 'react';
import { Field, Fieldset, Label, Select } from '@purposeinplay/core-v2';
import useGetProtocols from '@/hooks/use-get-protocols';
import useModal from '@/hooks/modal';
import { useSearchParams } from 'next/navigation';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';

const ProtocolSelector = () => {
  const { replace } = useModal();

  const { protocols } = useGetProtocols();

  const walletContent = useGetWalletContent();

  const searchParams = useSearchParams();
  const queryProtocol = searchParams.get('p') || 'ERC20';

  const defaultSelectedIndex = useMemo(() => {
    return protocols?.findIndex(
      (protocol: any) => protocol.id === queryProtocol,
    );
  }, [protocols, queryProtocol]);

  return protocols ? (
    <Fieldset>
      <Field className="[&>div>div>div>button]:hover:bg-bgr-hovered">
        <Label htmlFor="protocol_selector">
          {walletContent?.deposit?.network_input?.label}
        </Label>
        <Select
          id="protocol_selector"
          onSelect={(item: any) => replace('p', item?.id)}
          multi={false}
          defaultSelectedIndex={defaultSelectedIndex}
          placeholder={walletContent?.deposit?.network_input?.placeholder}
          items={protocols}
        />
      </Field>
    </Fieldset>
  ) : null;
};

export default ProtocolSelector;
