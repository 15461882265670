'use client';
import { Button, Input, Modal } from '@purposeinplay/core-v2';
import { useChangePassword } from '@/hooks/auth';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { handleError } from '@/utils/helpers';
import StandaloneModal from '../standalone-modal';
import { useToast } from '@purposeinplay/utils';
import { useAppSelector } from '@/hooks/redux';
import { useTranslation } from '@/app/i18n/client';
import { GeneralContentType } from '@/types/json-content/general';

const initialState = {
  password: '',
  password_confirmation: '',
};

const ChangePasswordModal: React.FC<{
  closeModal: () => void;
  reset_password_token: string | null;
  isStandalone?: boolean;
}> = ({
  closeModal,
  reset_password_token,
  isStandalone = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const general = t('general') as GeneralContentType;
  const pageTriggerContent = general?.page_triggers;

  const openedModal = useAppSelector(
    (state) => state.bootstrap.openTriggerModal,
  );

  // const { close } = useModal();

  const { toast } = useToast();

  const [state, setState] = useState(initialState);

  const {
    mutateAsync: changePassword,
    reset,
    isError,
    error,
    isPending,
  }: any = useChangePassword();

  useEffect(() => {
    return () => {
      setState({
        password: '',
        password_confirmation: '',
      });
      reset();
    };
  }, [reset]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
      setState((prevState) => ({ ...prevState, [type]: e.target.value }));
    },
    [],
  );

  const handleResetPassword = useCallback(
    async (e: any) => {
      e.preventDefault();
      await changePassword({ ...state, reset_password_token })
        .then(async () => {
          toast({
            state: 'success',
            title: pageTriggerContent?.toast?.password_success,
          });
          closeModal && closeModal();
        })
        .catch(() => {
          toast({
            state: 'error',
            title: pageTriggerContent?.toast?.no_reset_token,
          });
        });

      closeModal && closeModal();
    },
    [
      changePassword,
      closeModal,
      pageTriggerContent,
      reset_password_token,
      state,
      toast,
    ],
  );

  const changePassContent = pageTriggerContent?.modal?.change_password;

  const formContent = useMemo(
    () => (
      <form onSubmit={handleResetPassword}>
        <div className="flex flex-col space-y-3">
          <Input
            variant="static"
            required
            type="password"
            error={isError && handleError(error?.errors, 'password')}
            label={changePassContent?.input?.password?.label}
            placeholder={changePassContent?.input?.password?.placeholder}
            value={state.password}
            onChange={(e) => handleChange(e, 'password')}
          />
          <Input
            variant="static"
            required
            type="password"
            error={
              isError && handleError(error?.errors, 'password_confirmation')
            }
            label={changePassContent?.input?.confirm_password?.label}
            placeholder={
              changePassContent?.input?.confirm_password?.placeholder
            }
            value={state.password_confirmation}
            onChange={(e) => handleChange(e, 'password_confirmation')}
          />
          <Button
            size="md"
            type="submit"
            isLoading={isPending}
            disabled={isPending}
          >
            {changePassContent?.btn_text}
          </Button>
        </div>
      </form>
    ),
    [
      handleResetPassword,
      isError,
      error?.errors,
      changePassContent?.input?.password?.label,
      changePassContent?.input?.password?.placeholder,
      changePassContent?.input?.confirm_password?.label,
      changePassContent?.input?.confirm_password?.placeholder,
      changePassContent?.btn_text,
      state.password,
      state.password_confirmation,
      isPending,
      handleChange,
    ],
  );

  if (isStandalone) {
    return <StandaloneModal>{formContent}</StandaloneModal>;
  }
  return (
    <Modal
      title={changePassContent?.title}
      isOpen={openedModal === 'changePasswordModal'}
      setIsOpen={() => closeModal()}
    >
      {formContent}
    </Modal>
  );
};

export default ChangePasswordModal;
