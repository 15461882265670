import { isValidBadgeType, useCalculateTransactionType } from '../utils';
import { useState } from 'react';
import {
  Badge,
  IconButton,
  ListComponent,
  Text,
  Tooltip,
} from '@purposeinplay/core-v2';
import dayjs from '@/utils/dayjsconfig';
import CopySVG from 'public/assets/wallet/copy.svg';
import copy from 'copy-to-clipboard';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';
import FiatAmount from '@/components/FiatAmount';
import { DateFormat } from '@/utils/date';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import useFiatFromCents from '@/hooks/fiat';
import CryptoTooltip from '@/components/CryptoTooltip';

const PaymentDetails = ({ transaction }: { transaction: any }) => {
  const { formatCurrency } = useCurrencyFormatter();
  const currency = transaction?.source_currency || transaction?.currency;
  const fiatAmount = useFiatFromCents(currency, transaction?.amount_cents);

  const amount = formatCurrency(transaction.amount_cents, currency);

  const [hasCopied, setHasCopied] = useState(false);

  const walletContent = useGetWalletContent();

  const transactionType = useCalculateTransactionType(transaction);

  const labels = {
    amount:
      walletContent?.transactions?.transaction_item?.details_modal?.amount,
    type: walletContent?.transactions?.transaction_item?.details_modal?.type,
    status:
      walletContent?.transactions?.transaction_item?.details_modal?.status,
    createdAt:
      walletContent?.transactions?.transaction_item?.details_modal?.created_at,
    finishedAt:
      walletContent?.transactions?.transaction_item?.details_modal?.finished_at,
    id: walletContent?.transactions?.transaction_item?.details_modal?.id,
  };

  const listComponentStyles = {
    valueStyles: 'text-right',
    containerStyles:
      'bg-surface-default hover:bg-surface-default !cursor-default  [&>span]:p-2 mb-1 rounded-lg',
  };

  return (
    <div>
      <ListComponent
        stylingOptions={listComponentStyles}
        label={<Text size="sm">{labels?.amount}</Text>}
        value={
          <Text
            size="sm"
            className="ml-auto flex items-center justify-end text-right text-text-default"
          >
            <CryptoTooltip cryptoAmount={amount} cryptoCode={currency}>
              <FiatAmount fiatAmount={fiatAmount} currency={currency} />
            </CryptoTooltip>
          </Text>
        }
      />

      <ListComponent
        stylingOptions={listComponentStyles}
        label={<Text size="sm">{labels?.type}</Text>}
        value={
          <Text
            size="sm"
            className="ml-auto flex justify-end text-text-default"
          >
            {/* This capitalizes the first letter of the type prop */}
            {transaction?.type?.charAt(0).toUpperCase() +
              transaction?.type?.slice(1)}
          </Text>
        }
      />

      <ListComponent
        stylingOptions={listComponentStyles}
        label={<Text size="sm">{labels?.status}</Text>}
        value={
          <Badge
            size="md"
            radius="rounded"
            type={
              isValidBadgeType(transactionType?.tag)
                ? transactionType?.tag
                : 'neutral'
            }
          >
            {transaction?.status?.type}
          </Badge>
        }
      />
      <ListComponent
        stylingOptions={listComponentStyles}
        label={<Text size="sm">{labels?.createdAt}</Text>}
        value={
          <Text
            size="sm"
            className="ml-auto flex justify-end text-text-default"
          >
            {dayjs(transaction.created_at).format(DateFormat.DATE_WITH_TIME)}
          </Text>
        }
      />
      {transaction.finished_at && (
        <ListComponent
          stylingOptions={listComponentStyles}
          label={<Text size="sm">{labels?.finishedAt}</Text>}
          value={
            <Text
              size="sm"
              className="ml-auto flex justify-end text-text-default"
            >
              {dayjs(transaction.finished_at).format(DateFormat.DATE_WITH_TIME)}
            </Text>
          }
        />
      )}
      {transaction.blockchain_transaction_id && (
        <ListComponent
          stylingOptions={listComponentStyles}
          label={<Text size="sm">{labels?.id}</Text>}
          value={
            <div className="relative ml-auto flex flex-row items-center justify-end text-text-default">
              <Text size="sm" as="span" className="max-w-[10rem] truncate">
                {transaction?.blockchain_transaction_id}
              </Text>
              <Tooltip
                styleOptions={{
                  arrowStyles: '!z-[99999]',
                  popperStyles: '!z-[99999]',
                }}
                placement="top"
                displayOn="hover"
                trigger={
                  <IconButton
                    size="xs"
                    iconOnly
                    onMouseLeave={() => setHasCopied(false)}
                    onClick={() => {
                      copy(transaction.blockchain_transaction_id);
                      setHasCopied(true);
                    }}
                    className="group"
                    ghost
                    color="primary"
                    iconLeft={
                      <CopySVG className="group-hover:ext-icons-default fill-current text-icons-default group-hover:fill-current" />
                    }
                  />
                }
              >
                {hasCopied
                  ? walletContent?.transactions?.transaction_item?.details_modal
                      ?.tooltip_text?.copied
                  : walletContent?.transactions?.transaction_item?.details_modal
                      ?.tooltip_text?.copy}
              </Tooltip>
            </div>
          }
        />
      )}
    </div>
  );
};

export default PaymentDetails;
