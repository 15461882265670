import { usePlayer } from '@/hooks/profile';
import React from 'react';
import EmailConfirmation from './components/email-confirmation';
import WithdrawContent from './components/withdraw-content';
import { WithdrawLoader } from '@/components/Loaders';

const Withdraw = () => {
  const { data: player, isLoading } = usePlayer();

  const hasConfirmedEmail = player?.confirmed_at !== null;

  if (isLoading) {
    return <WithdrawLoader />;
  }

  if (hasConfirmedEmail) {
    return <WithdrawContent />;
  }

  return <EmailConfirmation email={player?.email} />;
};

export default Withdraw;
