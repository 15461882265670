import { Button, Input } from '@purposeinplay/core-v2';
import React, { useMemo } from 'react';
import { WithdrawInputProps, WithdrawalData } from '../../../types';
import { useAppSelector } from '@/hooks/redux';
import { handleError } from '@/utils/helpers';
import { useSearchParams } from 'next/navigation';
import Button100 from './button-100';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';

const AmountInput = ({
  formData,
  setFormData,
  error,
  isError,
  paymentMethod,
}: WithdrawInputProps) => {
  const searchParams = useSearchParams();
  const currency = searchParams.get('c');
  const amountError = useMemo(() => {
    const err = handleError(error?.errors, 'amount');
    if (!err) return;
    const numberRegex = /[+-]?\d+(?:\.\d+)?/g;

    return err.replace(numberRegex, (i: number) => {
      return `${i} ${currency}`;
    });
  }, [currency, error?.errors]);

  const walletContent = useGetWalletContent();

  return (
    <div className="relative cursor-pointer">
      <Input
        className="z-0"
        id="amount-input"
        onChange={(e) =>
          setFormData({
            ...formData,
            [paymentMethod?.brand]: {
              ...formData[paymentMethod?.brand],
              sum: e.target.value.slice(0, 10),
            },
          })
        }
        error={isError && amountError ? true : false}
        errorMessage={amountError}
        value={
          formData[paymentMethod?.brand]?.sum
            ? formData[paymentMethod?.brand]?.sum
            : ''
        }
        type="number"
        trailingIcon={<></>}
        label={walletContent?.withdraw?.amount?.label}
        variant="static"
        placeholder={walletContent?.withdraw?.amount?.placeholder}
      />
      <div className="absolute right-2 top-9">
        <Button100
          formData={formData}
          setFormData={setFormData}
          paymentMethod={paymentMethod}
        />
      </div>
    </div>
  );
};

export default AmountInput;
