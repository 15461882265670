import React, { useState } from 'react';
import { cn } from '@/utils/style';
import { Checkbox, Field, Modal } from '@purposeinplay/core-v2';
import { processMarkdown } from '@/utils/helpers';
import { useTranslation } from '@/app/i18n/client';
import { usePagesQuery } from '@/generated';
import { DEFAULT_CONTENT_STALE_TIME } from '@/utils/constants';
import { extractTranslations } from '@/utils/content';
import { AuthContentType } from '@/types/json-content/auth';
import { StateType } from '@/components/Auth/signup-form';
import InputGroup from '@/components/InputGroup';

const PrivacyTermsLabel = React.memo(
  ({
    inOverlay,
    quick,
    setState,
    state,
    children,
  }: {
    inOverlay: string | null;
    quick: boolean;
    state: StateType;
    setState: (state: any) => void;
    children: React.ReactNode;
  }) => {
    const { t, i18n } = useTranslation();
    const authContent = t('auth') as AuthContentType;

    const content = authContent.signup_modal;

    const { data: termsPage } = usePagesQuery(
      { path: '/terms' },
      {
        queryKey: ['pages', '/terms'],
        staleTime: DEFAULT_CONTENT_STALE_TIME,
      },
    );

    const { data: privacyPage } = usePagesQuery(
      { path: '/privacy' },
      {
        queryKey: ['pages', '/privacy'],
        staleTime: DEFAULT_CONTENT_STALE_TIME,
      },
    );
    const termsContent = termsPage?.pages?.data[0]?.attributes;
    const terms = extractTranslations(i18n.language, termsContent);
    const privacyContent = privacyPage?.pages?.data[0]?.attributes;
    const privacy = extractTranslations(i18n.language, privacyContent);

    const [openTermsModal, setOpenTermsModal] = useState(false);
    const [openPrivacyModal, setOpenPrivacyModal] = useState(false);
    return (
      <Field>
        <InputGroup
          className={cn([
            'svg:opacity-100 mb-4',
            inOverlay && `mb-7`,
            quick && `mb-4 md:mb-4`,
          ])}
        >
          <div className="items-center justify-center md:block">
            <Checkbox
              id="terms_acceptance"
              label={
                <span
                  className={'font-base mb-0 cursor-pointer whitespace-nowrap'}
                >
                  {content?.form?.terms.message}
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTermsModal(true);
                    }}
                    className="ml-1 cursor-pointer underline hover:text-base-primary"
                  >
                    {content?.form?.terms?.terms_action}
                  </span>{' '}
                  &{' '}
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenPrivacyModal(true);
                    }}
                    className="cursor-pointer underline hover:text-base-primary"
                  >
                    {content?.form?.terms?.policy_action}
                  </span>
                </span>
              }
              onClick={(value) =>
                setState({ ...state, terms_acceptance: value })
              }
            />
          </div>
          {children}
        </InputGroup>
        <Modal isOpen={openTermsModal} setIsOpen={setOpenTermsModal} size="3xl">
          {terms && (
            <div className="markdown-body">
              <div
                dangerouslySetInnerHTML={{
                  __html: processMarkdown(terms?.content[0]?.Markdown),
                }}
              />
            </div>
          )}
        </Modal>
        <Modal
          isOpen={openPrivacyModal}
          setIsOpen={setOpenPrivacyModal}
          size="3xl"
        >
          {privacy && (
            <div className="markdown-body">
              <div
                dangerouslySetInnerHTML={{
                  __html: processMarkdown(privacy?.content[0]?.Markdown),
                }}
              />
            </div>
          )}
        </Modal>
      </Field>
    );
  },
);

PrivacyTermsLabel.displayName = 'PrivacyTermsLabel';

export default PrivacyTermsLabel;
