'use client';
import React, { useMemo } from 'react';
import useModal from '@/hooks/modal';
import { Modal } from '@purposeinplay/core-v2';
import ContentAreaWrapper from '@/components/ContentAreaWrapper';
import useMediaQuery, { MOBILE_BREAKPOINT } from '@/hooks/use-media-query';
import DesktopImage from './desktop-image';
import Image from 'next/image';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { useIsMounted } from 'usehooks-ts';

type Props = {
  isOpen?: boolean;
  setIsOpen?: any;
  children: React.ReactNode;
  forPage?: boolean;
};

const LayoutModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  children,
  forPage,
}) => {
  const { close } = useModal();

  const isMounted = useIsMounted();

  const { matchesQuery: isMobile, isLoading } =
    useMediaQuery(MOBILE_BREAKPOINT);

  const renderContent = useMemo(() => {
    return (
      <div className="relative overflow-hidden  sm:grid sm:grid-cols-11 sm:grid-rows-none">
        <div className="relative z-0 row-span-2 flex flex-col items-center overflow-hidden bg-[linear-gradient(188.75deg,#6508db_-2.96%,#de3ec4_46.56%,#fd8618_106.63%)] pb-20 sm:col-span-5 sm:row-span-6 sm:-mb-0 sm:h-full sm:pb-0 lg:bg-[linear-gradient(190.15deg,#6508db_7.44%,#de3ec4_48.66%,#fd8618_116.06%)]">
          {isMobile && isMounted() ? (
            <div className="flex h-[300px] w-full items-center justify-center">
              <Image
                height={340}
                width={384}
                loader={DEFAULT_IMAGE_LOADER}
                src="/assets/login_banner_mobile.webp"
                alt="confetti background"
                style={{
                  objectFit: 'cover',
                }}
              />
            </div>
          ) : (
            <DesktopImage />
          )}
        </div>
        <div className="relative z-20 -mt-32 rounded-t-2xl bg-bgr-modal  p-3 sm:col-span-6 sm:row-span-6 sm:mt-0 sm:rounded-t-none sm:p-7 ">
          {children}
        </div>
      </div>
    );
  }, [children, isMobile, isMounted]);

  if (forPage) {
    return (
      <ContentAreaWrapper className="max-w-6xl overflow-hidden 2xl:max-w-6xl">
        <div className="overflow-hidden rounded-2xl">{renderContent}</div>
      </ContentAreaWrapper>
    );
  }

  return (
    <Modal
      onClose={() => {
        close();
      }}
      size="2xl"
      isOpen={isOpen}
      modalStylingOptions={{
        containerStyles: 'overflow-hidden',
        contentStyles: '!p-0',
      }}
      setIsOpen={() => null}
    >
      {renderContent}
    </Modal>
  );
};

export default LayoutModal;
