import { ChangellyProvider } from '@changelly/fiat-api-sdk-node';
import { Button } from '@purposeinplay/core-v2';
import React from 'react';
import { useGetWalletContent } from '../../hooks/use-get-wallet-content';

type Props = {
  offersLoading: boolean;
  amount: string;
  currentIpLoading: boolean;
  changellyOffers: any;
  providerCode: ChangellyProvider | null;
  setConfirmPurchase: React.Dispatch<React.SetStateAction<boolean>>;
};

const BuyAction: React.FC<Props> = React.memo(
  ({
    offersLoading,
    amount,
    currentIpLoading,
    changellyOffers,
    setConfirmPurchase,
    providerCode,
  }) => {
    const walletContent = useGetWalletContent();
    return (
      <Button
        type="button"
        fullWidth
        isLoading={offersLoading}
        disabled={
          offersLoading ||
          amount === '' ||
          currentIpLoading ||
          changellyOffers?.length === 0 ||
          !changellyOffers
        }
        onClick={() => setConfirmPurchase(true)}
        className="capitalize"
      >
        {offersLoading
          ? 'Fetching Offer...'
          : providerCode
            ? `Buy via ${providerCode}`
            : walletContent?.buy_crypto?.action?.btn_label}
      </Button>
    );
  },
);

BuyAction.displayName = 'FiatBuyAction';

export default BuyAction;
