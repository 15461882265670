import React from 'react';
import { cn } from '@/utils/style';
import { handleError } from '@/utils/helpers';
import { FieldGroup, Input } from '@purposeinplay/core-v2';
import { SignupModal } from '@/types/json-content/auth';

type Props = {
  quick: boolean;
  content: SignupModal;
  isError: boolean;
  email: string;
  errors: any;
  handleChange: (e: any, field: string) => void;
};

const EmailInput: React.FC<Props> = React.memo(
  ({ quick, content, email, isError, errors, handleChange }) => {
    return (
      <FieldGroup className={cn([quick && `mb-4 md:mb-4`])}>
        <Input
          id="email"
          type="email"
          label={content?.form?.email?.label}
          placeholder={content?.form?.email?.placeholder}
          value={email}
          onChange={(e) => handleChange(e, 'email')}
          variant="static"
          error={isError && handleError(errors, 'email')}
          errorMessage={isError && handleError(errors, 'email')}
        />
      </FieldGroup>
    );
  },
);

EmailInput.displayName = 'EmailInput';

export default EmailInput;
