import { CURRENCY_NAMES } from '@/utils/constants';
import { getIconByCurrency } from '@/utils/helpers';
import { cn } from '@/utils/style';
import React from 'react';

interface Props {
  item: any;
  noTitle?: boolean;
}

const LeadingSelectItem = ({ item, noTitle = false }: Props) => {
  if (!item) {
    return null;
  }

  return (
    <div className="flex items-center space-x-2 text-sm font-medium">
      <div className="shrink-0 [&>svg]:h-5 [&>svg]:w-5">
        {item === 'USD'
          ? getIconByCurrency('usd')
          : getIconByCurrency(item?.currency.toLowerCase())}
      </div>
      <span className="truncate whitespace-nowrap">
        {!noTitle && CURRENCY_NAMES[item.currency]}{' '}
        {item === 'USD' ? 'USD' : item.currency}
      </span>
    </div>
  );
};

export default LeadingSelectItem;
