import React from 'react';
import { Badge, Button, Text } from '@purposeinplay/core-v2';
import Image from 'next/image';

import GiftFillSVG from 'public/assets/gift-fill.svg';
import Countdown from '@/components/Countdown';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { PreviewBonusesContentType } from '@/types/json-content/preview_bonuses';
import { useTranslation } from '@/app/i18n/client';
import useBonusTime from '@/hooks/use-bonus-time';

type Props = {
  bonusTitle: string | undefined;
  handleClickDetails: () => void;
};

const CondensedPreviewHeader: React.FC<Props> = ({
  bonusTitle,
  handleClickDetails,
}) => {
  const { t } = useTranslation();
  const content = t('preview_bonuses') as PreviewBonusesContentType;

  const { time } = useBonusTime();

  return (
    <div className="mb-spacing-sm flex flex-row items-start md:items-center">
      <div className="flex w-full flex-col md:flex-row md:items-center md:justify-between">
        <div className="mb-3 flex flex-row items-center gap-spacing-2xs md:mb-0">
          <GiftFillSVG className="h-4 w-4" />
          <Text size="sm" as="span">
            {bonusTitle}
          </Text>
        </div>
        <div className="flex flex-row items-center">
          <Image
            alt="Bonus Prize Details Wallet"
            height={16}
            width={16}
            src="/assets/lightning-limit.svg"
            loader={DEFAULT_IMAGE_LOADER}
          />
          <Text size="xs" className="mr-spacing-xs font-medium">
            {content?.bonus_selection_modal?.countdown_label}
          </Text>
          <Badge
            radius="rounded"
            size="sm"
            type="warning"
            className="mr-spacing-sm"
          >
            <Countdown date={time} withoutTextTag withSvg={false} />
          </Badge>
        </div>
      </div>

      <Button size="xs" color="primary" ghost onClick={handleClickDetails}>
        {content?.details_btn_label}
      </Button>
    </div>
  );
};

export default CondensedPreviewHeader;
