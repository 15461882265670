import React from 'react';
import { ListComponent, Text } from '@purposeinplay/core-v2';

import CryptoAmount from '@/components/CryptoAmount';
import CryptoTooltip from '@/components/CryptoTooltip';
import { useCurrency } from '@/hooks/currencies';
import { useFiatFromCrypto } from '@/hooks/fiat';
import { useAppSelector } from '@/hooks/redux';
import {
  Bonus,
  PreviewBonusesContentType,
} from '@/types/json-content/preview_bonuses';
import { useTranslation } from '@/app/i18n/client';

const CondensedDepositRange = ({
  bonus,
  className,
}: {
  bonus: Bonus;
  className?: string;
}) => {
  const { t } = useTranslation();
  const content = t('preview_bonuses') as PreviewBonusesContentType;

  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
  const foundCurrency = useCurrency();
  const fiatAmountMin = useFiatFromCrypto(
    foundCurrency?.code,
    // @ts-ignore
    bonus?.min_deposit?.value[foundCurrency?.code],
  );

  const fiatAmountMax = useFiatFromCrypto(
    foundCurrency?.code,
    // @ts-ignore
    bonus?.max_deposit?.value[foundCurrency?.code],
  );

  return (
    <div className={className}>
      <ListComponent
        stylingOptions={{
          containerStyles: 'hover:bg-surface-subdued !cursor-auto',
        }}
        label={
          <div className="flex flex-col items-start gap-1 md:flex-row md:items-center">
            <Text size="sm" className="font-medium text-text-subdued" as="span">
              {content?.deposit}
            </Text>
            <div className="flex flex-row items-center gap-1">
              <CryptoTooltip
                cryptoAmount={
                  !viewFiat ? (
                    <CryptoAmount
                      removeIcon
                      balance={Number(
                        // @ts-ignore
                        bonus?.min_deposit?.value[foundCurrency?.code],
                      )}
                      currency={foundCurrency?.code}
                    />
                  ) : (
                    <Text size="sm">{fiatAmountMin}</Text>
                  )
                }
                cryptoCode={foundCurrency?.code}
              >
                <CryptoAmount
                  balance={Number(
                    // @ts-ignore
                    bonus?.min_deposit?.value[foundCurrency?.code],
                  )}
                  currency={foundCurrency?.code}
                />
              </CryptoTooltip>
              {bonus?.max_deposit ? (
                <>
                  <Text
                    size="xs"
                    as="span"
                    className="font-normal text-text-subdued"
                  >
                    -
                  </Text>
                  <CryptoTooltip
                    cryptoAmount={
                      !viewFiat ? (
                        <CryptoAmount
                          removeIcon
                          balance={Number(
                            // @ts-ignore
                            bonus?.max_deposit?.value[foundCurrency?.code],
                          )}
                          currency={foundCurrency?.code}
                        />
                      ) : (
                        <Text size="sm">{fiatAmountMax}</Text>
                      )
                    }
                    cryptoCode={foundCurrency?.code}
                  >
                    <CryptoAmount
                      balance={Number(
                        // @ts-ignore
                        bonus?.max_deposit?.value[foundCurrency?.code],
                      )}
                      currency={foundCurrency?.code}
                    />
                  </CryptoTooltip>
                </>
              ) : (
                <Text size="sm" as="span" className="font-normal">
                  {content?.last_interval_label}
                </Text>
              )}
            </div>
          </div>
        }
        value={bonus?.title?.value}
      />
    </div>
  );
};

export default CondensedDepositRange;
