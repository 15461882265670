import usePaymentsServiceV2 from '@/services/payment-service-v2';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { Payment, Arguments } from 'payments-lib-types';
import config from '@/config';
import { useCallback, useMemo } from 'react';
import useUpdateMissingCurrency from './use-update-missing-currency';
import { useSearchParams } from 'next/navigation';

const useResetCache = () => {
  const resetCache = useCallback(async () => {
    if (window?.PaymentsAPI) return;
    return await window.PaymentsAPI?.resetCache();
  }, []);

  return {
    resetCache,
  };
};

export const useFetchPaymentMethodsV2 = (
  currency: string = 'BTC',
  paymentAction: Payment.Action,
): UseQueryResult<Payment.Method[]> => {
  const { resetCache } = useResetCache();
  const { isLoading, paymentsAPI } = usePaymentsServiceV2();

  const shouldBlock = useMemo(() => {
    return !currency || !paymentsAPI || isLoading;
  }, [currency, isLoading, paymentsAPI]);

  return useQuery({
    queryKey: ['fetchPaymentMethodsV2', { currency, paymentAction }],
    queryFn: async ({ queryKey }) => {
      await resetCache();
      // @ts-ignore
      const { currency } = queryKey[1];
      if (paymentsAPI) {
        return await paymentsAPI.getMethods({
          currency,
          paymentAction,
        });
      }
    },
    enabled: !shouldBlock ? true : false,
  });
};

const useFetchMethodFields = (
  currency: string,
  paymentAction: Payment.Action,
): UseQueryResult<any> => {
  const { resetCache } = useResetCache();
  const { isLoading, paymentsAPI } = usePaymentsServiceV2();

  const { isLoading: paymentMethodsIsLoading, data: paymentMethods }: any =
    useFetchPaymentMethodsV2(currency, paymentAction);

  const availablePaymentMethods = useMemo(
    () =>
      paymentMethods &&
      paymentMethods.filter((paymentMethod: any) =>
        config.PAYMENT_PROVIDERS.includes(paymentMethod.provider),
      ),
    [paymentMethods],
  );

  const currentPaymentMethod =
    paymentMethods &&
    (paymentMethods?.find(
      (method: any) =>
        method?.termsOfService?.restrictions?.amountCurrencyCode?.toLowerCase() ===
          method?.termsOfService?.restrictions?.originCryptoCurrency?.toLowerCase() &&
        method?.termsOfService?.restrictions?.amountCurrencyCode?.toLowerCase() ===
          currency?.toLowerCase(),
    ) as any);

  const shouldBlock = useMemo(() => {
    return (
      !currency ||
      !paymentsAPI ||
      isLoading ||
      paymentMethodsIsLoading ||
      !paymentMethods
    );
  }, [
    currency,
    isLoading,
    paymentMethods,
    paymentMethodsIsLoading,
    paymentsAPI,
  ]);

  return useQuery({
    queryKey: ['getMethodFields', { currency, paymentAction }],
    queryFn: async ({ queryKey }: any) => {
      await resetCache();
      // @ts-ignore
      const { currency } = queryKey[1];
      if (paymentsAPI) {
        return await paymentsAPI.getMethodFields({
          id: currentPaymentMethod.id,
          currency,
          paymentAction,
        });
      }
    },
    enabled: !shouldBlock ? true : false,
  });
};

export const useCryptoDepositInfo = (currency: string) => {
  const {
    isLoading: methodFieldsLoading,
    data,
    refetch: refetchMethodFields,
  } = useFetchMethodFields(currency, Payment.Action.Deposit);

  const depositInfo = data?.methodFields?.find(
    (method: any) =>
      method.type === 'deposit_crypto_address' ||
      'multi_protocol_crypto_address',
  );
  const optionalData = data?.methodFields?.find(
    (method: any) => method.fieldName === 'tag',
  );

  const banner = data?.methodFields?.find(
    (method: any) => method.type === 'banner',
  );
  return {
    depositInfo,
    optionalData,
    banner,
    isLoading: methodFieldsLoading,
    refetchMethodFields,
  };
};

export const useGetCryptoDepositInfo = () => {
  const searchParams = useSearchParams();
  const currency = searchParams.get('c') || '';
  const protocol = searchParams.get('p') || '';

  const { depositInfo, isLoading, optionalData } = useCryptoDepositInfo(
    currency?.toUpperCase(),
  );

  const isDepositUSDT = depositInfo?.type === 'multi_protocol_crypto_address';

  const depositInfoHref = useMemo(() => {
    return isDepositUSDT
      ? depositInfo?.addressByProtocol[protocol]?.href
      : depositInfo?.href;
  }, [
    depositInfo?.addressByProtocol,
    depositInfo?.href,
    isDepositUSDT,
    protocol,
  ]);

  const depositInfoAdress = useMemo(() => {
    return isDepositUSDT
      ? depositInfo?.addressByProtocol[protocol]?.address
      : depositInfo?.address;
  }, [
    depositInfo?.address,
    depositInfo?.addressByProtocol,
    isDepositUSDT,
    protocol,
  ]);

  return {
    depositInfo,
    isLoading,
    optionalData,
    depositInfoHref,
    depositInfoAdress,
  };
};
