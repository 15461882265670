import React, { useEffect, useMemo, useState } from 'react';
import CurrencySelector from '../../Deposit/components/currency-selector';
import PopularCurrencies from '../../Deposit/components/popular-currencies';
import ProtocolSelector from '../../Deposit/components/protocol-selector';
import AddressInput from './address-input';
import AmountInput from './amount-input';
import OptionalInput from './optional-input';
import { useWithdraw } from '@/hooks/payments';
import { useGetFoundCurrencyDeposit } from '@/hooks/use-get-found-currency-deposit';
import {
  useFiatMinCashout,
  useFiatSum,
  useWithdrawableAmount,
} from '../../../hooks/withdraw-hooks';
import {
  useCryptoDepositInfo,
  useFetchPaymentMethodsV2,
  useGetCryptoDepositInfo,
} from '@/hooks/paymentsv2';
//@ts-ignore
import { Payment } from 'payments-lib-types';
import { useAppSelector } from '@/hooks/redux';
import { useSearchParams } from 'next/navigation';
import WithdrawDetails from './withdraw-details';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';
import '../styles/withdraw.css';
import { Button } from '@purposeinplay/core-v2';
import { Currency } from '@/types/currency';

const WithdrawContent = () => {
  const [formData, setFormData] = useState<any>({});
  const { foundCurrencyDeposit: foundCurrency } = useGetFoundCurrencyDeposit();
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);

  const walletContent = useGetWalletContent();

  const searchParams = useSearchParams();
  const currency = searchParams.get('c') || 'BTC';
  const protocol = searchParams.get('p') || '';

  const { isLoading } = useCryptoDepositInfo(currency.toUpperCase());

  const { data: paymentMethods } = useFetchPaymentMethodsV2(
    currency?.toUpperCase(),
    Payment.Action.Cashout,
  );

  const { optionalData, depositInfo } = useGetCryptoDepositInfo();

  const paymentMethod =
    paymentMethods &&
    (paymentMethods?.find(
      (method) =>
        method?.termsOfService?.restrictions?.amountCurrencyCode?.toLowerCase() ===
          method?.termsOfService?.restrictions?.originCryptoCurrency?.toLowerCase() &&
        method?.termsOfService?.restrictions?.amountCurrencyCode?.toLowerCase() ===
          currency?.toLowerCase(),
    ) as any);

  const isOptionalDataAvailable =
    optionalData && optionalData.fieldName === 'tag';

  const onSuccess = () => {
    setFormData({});
  };

  const {
    mutateAsync: withdrawFunds,
    isPending: withdrawLoading,
    isError,
    error,
  }: any = useWithdraw(onSuccess);

  const withdrawableAmount = useWithdrawableAmount(
    parseFloat(formData[paymentMethod?.brand]?.sum),
    foundCurrency as Currency,
  );
  useEffect(() => {
    setFormData((formData: any) => ({
      ...formData,
      [paymentMethod?.brand]: {
        ...formData[paymentMethod?.brand],
        sum: null,
      },
    }));
  }, [viewFiat, paymentMethod?.brand]);

  useEffect(() => {
    if (!isLoading && protocol && currency.toUpperCase() === 'USDT') {
      setFormData((formData: any) => ({
        ...formData,
        [paymentMethod?.brand]: {
          ...formData[paymentMethod?.brand],
          protocol: protocol,
        },
      }));
    }
  }, [currency, isLoading, paymentMethod?.brand, protocol]);

  const fiatMinCashout = useFiatMinCashout(
    paymentMethod?.termsOfService?.restrictions?.minAmountValue,
    foundCurrency?.code,
  );

  const isInvalidWithdrawal =
    withdrawLoading ||
    !formData[paymentMethod?.brand]?.sum ||
    formData[paymentMethod?.brand]?.sum === '' ||
    !formData[paymentMethod?.brand]?.address ||
    formData[paymentMethod?.brand]?.address === '' ||
    Number(formData[paymentMethod?.brand].sum) <
      (viewFiat
        ? Number(fiatMinCashout?.split('$')[1])
        : Number(paymentMethod?.termsOfService?.restrictions?.minAmountValue));

  const withdrawCash = useFiatSum(
    parseFloat(formData[paymentMethod?.brand]?.sum),
  );

  const withdrawSum = useMemo(() => {
    return !viewFiat
      ? `${walletContent?.tabs[1].label} ${
          formData[paymentMethod?.brand]?.sum
            ? formData[paymentMethod?.brand]?.sum
            : ''
        } ${foundCurrency?.code}`
      : withdrawCash;
  }, [
    formData,
    foundCurrency?.code,
    paymentMethod?.brand,
    viewFiat,
    walletContent?.tabs,
    withdrawCash,
  ]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!withdrawableAmount) return;
    const protocol = formData[paymentMethod?.brand]?.protocol;
    const payload = {
      address: formData[paymentMethod?.brand].address,
      amount: withdrawableAmount,
      currency: currency,
      original_currency: currency,
      payment_action: 'cashout',
      payment_method: paymentMethod?.id,
      tag: formData[paymentMethod?.brand].tag,
      protocol: undefined,
    };
    if (protocol) {
      payload['protocol'] = protocol;
    }
    try {
      await withdrawFunds(payload);
    } catch (e) {
      throw e;
    }
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className=" flex flex-col space-y-7"
    >
      <div className="withdraw-container flex flex-col space-y-4">
        <CurrencySelector />
        <ProtocolSelector />
        <PopularCurrencies />
        {/* depositInfo undefined = account suspended, we need to display the input no matter what */}
        {(isOptionalDataAvailable ||
          (depositInfo === undefined && currency === 'XRP')) && (
          <OptionalInput
            isError={isError}
            formData={formData}
            setFormData={setFormData}
            error={error}
            paymentMethod={paymentMethod}
          />
        )}
        <AddressInput
          isError={isError}
          formData={formData}
          setFormData={setFormData}
          error={error}
          paymentMethod={paymentMethod}
        />
        <AmountInput
          isError={isError}
          formData={formData}
          setFormData={setFormData}
          error={error}
          paymentMethod={paymentMethod}
        />
        <WithdrawDetails paymentMethod={paymentMethod} />
      </div>

      <Button disabled={isInvalidWithdrawal} isLoading={withdrawLoading}>
        {withdrawSum}
      </Button>
    </form>
  );
};

export default WithdrawContent;
