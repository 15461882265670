import React, { useState } from 'react';
import CurrencySelector from './currency-selector';
import ProtocolSelector from './protocol-selector';
import PopularCurrencies from './popular-currencies';
import BonusToggle from './bonus-toggle';
import BonusData from './bonus-data';
import { useGetCoolOffLimit } from '@/components/Wallet/hooks/use-get-coolof-limit';
import CoolOffComponent from './cool-off-component';
import DepositInfo from './deposit-info';
import { useAuth } from '@/hooks/auth';
import PromoCode from '@/components/Bonuses/components/promo-code';
import { Callout } from '@purposeinplay/core-v2';
import { useTranslation } from '@/app/i18n/client';
import { WalletContentType } from '@/types/json-content/wallet';

const DepositContent = () => {
  const { coolOffLimit } = useGetCoolOffLimit();

  const { t } = useTranslation();
  const walletContent = t('wallet') as WalletContentType;
  const content = walletContent?.modal?.deposit?.bonuses;

  const {
    user: { currentUser, isLoading },
  } = useAuth();

  return (
    <div className="flex flex-col space-y-7">
      <div className="flex flex-col space-y-4">
        <CurrencySelector />
        <ProtocolSelector />
        <PopularCurrencies />
      </div>
      {coolOffLimit ? <CoolOffComponent /> : <DepositInfo />}
      <div className="flex flex-col space-y-4">
        <BonusToggle />
        {currentUser?.can_issue_bonuses && <PromoCode forModal={false} />}
        {currentUser?.can_issue_bonuses && <BonusData />}
        {!isLoading && !currentUser?.can_issue_bonuses && (
          <div className="[&>div>div>div>div>div:last-of-type]:w-full">
            <Callout type="warning" content={content?.bonus_refuse} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DepositContent;
