import { ChangellyError } from '@/services/changelly';
import {
  ChangellyCreateOrderParams,
  ChangellyCreateOrderResponse,
  ChangellyCurrency,
  ChangellyGetCountryAvailabilityResponse,
  ChangellyGetCurrencyListResponse,
  ChangellyGetOffersParams,
  ChangellyGetOffersResponse,
  ChangellyGetProviderListResponse,
  ChangellyProvider,
} from '@changelly/fiat-api-sdk-node';
import { useToast } from '@purposeinplay/utils';
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { useAuth } from './auth';
import { GENERAL_ERROR } from '@/utils/constants';

const useChangellyCurrencies = (
  providerCode?: ChangellyProvider | null,
): UseQueryResult<ChangellyGetCurrencyListResponse[]> => {
  return useQuery({
    queryKey: ['changellyCurrencies', providerCode],
    queryFn: () => {
      try {
        return fetch(`/api/fiat/currencies?providerCode=${providerCode}`).then(
          async (res) => {
            const data = await res.text();
            if (res.ok) {
              const parsed = (data && JSON.parse(data)) || {};
              return Promise.resolve(parsed?.response);
            } else {
              return Promise.reject(JSON.parse(data));
            }
          },
        );
      } catch (e) {
        throw e;
      }
    },
    enabled: providerCode !== null,
  });
};

const useChangellyProviders = (): UseQueryResult<
  ChangellyGetProviderListResponse[]
> => {
  return useQuery({
    queryKey: ['changellyCurrencies'],
    queryFn: () => {
      try {
        return fetch(`/api/fiat/providers`).then(async (res) => {
          const data = await res.text();
          if (res.ok) {
            const parsed = (data && JSON.parse(data)) || {};
            return Promise.resolve(parsed?.response);
          } else {
            return Promise.reject(JSON.parse(data));
          }
        });
      } catch (e) {
        throw e;
      }
    },
  });
};

const useChangellyOffers = (payload: {
  amountFrom: string;
  currencyFrom: string | undefined;
  currencyTo: string | undefined;
  country: string | undefined;
  state: string | undefined;
  providerCode: ChangellyProvider | null;
}): UseQueryResult<ChangellyGetOffersResponse[]> => {
  const { user } = useAuth();

  return useQuery({
    queryKey: ['changellyOffers', payload],
    queryFn: () => {
      const {
        amountFrom,
        currencyFrom,
        currencyTo,
        country,
        state,
        providerCode,
      } = payload;
      let currency =
        currencyTo === 'DOG'
          ? 'DOGE'
          : currencyTo === 'USDT'
            ? 'USDT20'
            : currencyTo;
      let url = `/api/fiat/offers?externalUserId=${user?.currentUser?.id}&currencyFrom=${currencyFrom}&currencyTo=${currency}&amountFrom=${amountFrom}&country=${country}&providerCode=${providerCode}`;
      if (country === 'US') {
        url = `/api/fiat/offers?externalUserId=${user?.currentUser?.id}&currencyFrom=${currencyFrom}&currencyTo=${currency}&amountFrom=${amountFrom}&country=${country}&state=${state}&providerCode=${providerCode}`;
      }
      try {
        return fetch(url).then(async (res) => {
          const data = await res.text();
          if (res.ok) {
            const parsed = (data && JSON.parse(data)) || {};
            return Promise.resolve(parsed?.response);
          } else {
            return Promise.reject(JSON.parse(data));
          }
        });
      } catch (e) {
        throw e;
      }
    },
    staleTime: 0,
    enabled:
      payload?.amountFrom &&
      payload?.amountFrom?.length > 0 &&
      payload?.currencyFrom &&
      payload?.currencyTo &&
      payload?.country
        ? true
        : false,
  });
};

const useChangellyCountries = (
  providerCode?: ChangellyProvider | null,
): UseQueryResult<ChangellyGetCountryAvailabilityResponse[]> => {
  return useQuery({
    queryKey: ['changellyCountries', providerCode],
    queryFn: () => {
      try {
        return fetch(`/api/fiat/countries?providerCode=${providerCode}`).then(
          async (res) => {
            const data = await res.text();
            if (res.ok) {
              const parsed = (data && JSON.parse(data)) || {};
              return Promise.resolve(parsed?.response);
            } else {
              return Promise.reject(JSON.parse(data));
            }
          },
        );
      } catch (e) {
        throw e;
      }
    },
    enabled: providerCode !== null,
  });
};

const useValidateWalletAddress = () => {
  const { toast } = useToast();
  return useMutation({
    // @ts-ignore
    mutationFn: (payload: {
      currency: string;
      walletAddress: string;
      walletExtraId?: string;
    }) => {
      try {
        return fetch('/api/fiat/validate-address', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }).then(async (res) => {
          const data = await res.text();
          if (res.ok) {
            const parsed = (data && JSON.parse(data)) || {};
            return Promise.resolve(parsed);
          } else {
            return Promise.reject(JSON.parse(data));
          }
        });
      } catch (e) {
        throw e;
      }
    },
    onSuccess: () => {
      toast({
        state: 'success',
        title: 'Wallet address validated successfully',
        autoClose: 1500,
      });
    },
    onError: (e: ChangellyError) => {
      toast({
        state: 'error',
        title: e?.response?.data?.errorDetails[0]?.value || GENERAL_ERROR,
        autoClose: 1500,
      });
    },
  });
};

const useCreateOrder = () => {
  const { toast } = useToast();
  return useMutation({
    mutationFn: (payload: ChangellyCreateOrderParams) => {
      let currency =
        payload?.currencyTo === 'DOG'
          ? 'DOGE'
          : payload?.currencyTo === 'USDT'
            ? 'USDT20'
            : payload?.currencyTo;
      const orderPayload = {
        ...payload,
        currencyTo: currency,
      };
      try {
        return fetch('/api/fiat/orders', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(orderPayload),
        }).then(async (res) => {
          const data = await res.text();
          if (res.ok) {
            const parsed = (data && JSON.parse(data)) || {};
            return Promise.resolve(parsed?.response);
          } else {
            return Promise.reject(JSON.parse(data));
          }
        });
      } catch (e) {
        throw e;
      }
    },
    onError: (e: ChangellyError) => {
      toast({
        state: 'error',
        title: e?.response?.data?.errorDetails[0]?.value || GENERAL_ERROR,
        autoClose: 1500,
      });
    },
  });
};
export {
  useChangellyCurrencies,
  useValidateWalletAddress,
  useChangellyOffers,
  useChangellyCountries,
  useCreateOrder,
  useChangellyProviders,
};
