'use client';

import { IconButton } from '@purposeinplay/core-v2';
import { useOnClickOutside } from '@purposeinplay/utils';
import { useSearchParams } from 'next/navigation';
import CloseSVG from 'public/assets/close.svg';
import React, { useRef } from 'react';

const StandaloneModal: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const searchParams = useSearchParams();
  const terms = searchParams.get('terms');
  const privacy = searchParams.get('privacy');
  const formRef = useRef<any>();

  useOnClickOutside(formRef, (e) => {
    if (terms || privacy) {
      return;
    }
    // check if the user is clicking outside the form to prevent closing when clicking the onesignal subscribe modal
    if (
      !e.target?.parentElement?.id ||
      e.target?.parentElement?.id === 'app-body'
    ) {
      window.parent.postMessage({ type: 'close' }, '*');
    }
  });

  return (
    <div
      ref={formRef}
      className="absolute left-1/2 top-1/2 mx-auto w-[95%] max-w-6xl -translate-x-1/2 -translate-y-1/2 rounded-3xl bg-bgr-subtile p-7 md:max-w-[44rem]"
    >
      <div className="absolute right-4 top-3.5 z-[99]">
        <IconButton
          type="button"
          size="xs"
          onClick={(...args) => {
            window.parent.postMessage({ type: 'close' }, '*');
          }}
          ghost
          color="tertiary"
          iconLeft={<CloseSVG />}
          iconOnly
        />
      </div>
      {children}
    </div>
  );
};

export default StandaloneModal;
