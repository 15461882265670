import { useMemo } from 'react';
import { useGetSortedAccounts } from './use-get-sorted-accounts';
import { useCurrencies } from './currencies';
import { useCurrencyFormatter } from './use-currency-formatter-wrapper';

export const useAccountData = () => {
  const { sortedAccounts } = useGetSortedAccounts();
  const { data: currencies } = useCurrencies();
  const { formatCurrency } = useCurrencyFormatter();

  const accountData = useMemo(() => {
    return currencies.map((currency, index) => {
      const sortedAccount = sortedAccounts?.find(
        (account) =>
          account?.currency?.toLowerCase() === currency.code.toLowerCase(),
      );

      if (sortedAccount) {
        return {
          currencyObj: currency,
          index,
          currency: sortedAccount.currency,
          balance: sortedAccount.balance,
        };
      } else {
        const balance = formatCurrency(0, currency?.code, { style: 'decimal' });

        return {
          currencyObj: null,
          index,
          currency: currency.code,
          balance: balance === '0' ? '0.00' : balance,
        };
      }
    });
  }, [currencies, sortedAccounts, formatCurrency]);

  return { accountData };
};
