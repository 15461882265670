import React, { useEffect } from 'react';
import MagnifySVG from 'public/assets/magnify.svg';
import { useState } from 'react';
import { matchSorter } from 'match-sorter';
import { SelectItemType } from './types';
import { useMemo } from 'react';
import { FormattedAccount } from '@/types/accounts';
import { CURRENCY_NAMES, POPULAR_CURRENCIES } from '@/utils/constants';
import CurrencyListItems from './components/currency-list-items';
import WalletListItems from './components/wallet-list-items';
import { cn } from '@/utils/style';
import { Input } from '@purposeinplay/core-v2';
import { useTranslation } from 'react-i18next';
import { GeneralContentType } from '@/types/json-content/general';

const baseSort = (a: any, b: any) => {
  const aIndex = POPULAR_CURRENCIES.indexOf(a.currency);
  const bIndex = POPULAR_CURRENCIES.indexOf(b.currency);

  if (aIndex !== -1 && bIndex !== -1) {
    return aIndex - bIndex;
  }

  return 0;
};

const filterKeys = {
  dropdown: ['currency', 'name'],
  modal: ['id', 'name'],
};

const filterItems = (items: any[], searchQuery: string, keys: any) => {
  return matchSorter(items, searchQuery, { keys, baseSort });
};

interface Props {
  items: (SelectItemType | FormattedAccount)[];
  close?: () => void;
  forDropdown?: boolean;
  setCurrencyOpen?: (open: boolean) => void;
}

export const SearchInput = ({
  items,
  close,
  forDropdown,
  setCurrencyOpen,
}: Props) => {
  const { t } = useTranslation();
  const general = t('general') as GeneralContentType;

  const mappedItems = useMemo(() => {
    return items?.map((item: any) => ({
      ...item,
      name: CURRENCY_NAMES[item.currency],
    }));
  }, [items]);

  const [allItems, setAllItems] = useState(mappedItems);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (forDropdown) {
      setAllItems(filterItems(mappedItems, searchQuery, filterKeys.dropdown));
    } else {
      setAllItems(filterItems(items, searchQuery, filterKeys.modal));
    }
  }, [forDropdown, items, mappedItems, searchQuery]);

  const currencyDropdownContent =
    general?.navigation?.right_nav_auth?.currency_dropdown;

  const renderItems = useMemo(() => {
    if (allItems?.length === 0) {
      return (
        <div
          className={cn([
            'mb-0 flex h-[36px] items-center px-3 text-sm  text-text-subdued',
            forDropdown && `justify-center`,
          ])}
        >
          {currencyDropdownContent?.no_currencies}
        </div>
      );
    }

    if (forDropdown) {
      return (
        <CurrencyListItems setCurrencyOpen={setCurrencyOpen} items={allItems} />
      );
    } else {
      return <WalletListItems items={allItems} close={close} />;
    }
  }, [
    allItems,
    close,
    currencyDropdownContent?.no_currencies,
    forDropdown,
    setCurrencyOpen,
  ]);

  return (
    <div className="relative">
      <div
        className={cn([
          'absolute top-0 w-full ',
          forDropdown &&
            `relative mb-2.5 [&>div>div>div>div>input]:pl-8 [&>div>div>div>div>span]:left-2 [&>div>div>div>div>span]:after:w-0`,
        ])}
      >
        <Input
          value={searchQuery}
          onChange={(e: any) => setSearchQuery(e.target.value)}
          className={cn([
            'cursor-pointer transition duration-200 ease-in-out hover:placeholder:text-text-subdued',
          ])}
          id="leadingIcon"
          required
          type="text"
          leadingIcon={
            <MagnifySVG className="h-5 w-5 !fill-none stroke-text-subdued" />
          }
          variant="static"
          placeholder={currencyDropdownContent?.search_label}
        />
      </div>
      <div className={cn(['relative -mx-2 px-2', forDropdown && 'mx-0 px-0'])}>
        <div
          className={cn([
            'scrollbar relative -mr-3 mt-10 max-h-[15rem] !overflow-y-scroll pb-1',
            forDropdown && 'mt-0 max-h-[16.2rem] px-0 pb-0',
          ])}
        >
          {renderItems}
        </div>
      </div>
    </div>
  );
};
