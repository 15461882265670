import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { cn } from '@/utils/style';
import {
  ChangellyGetProviderListResponse,
  ChangellyProvider,
} from '@changelly/fiat-api-sdk-node';
import Image from 'next/image';
import React from 'react';
import { getFiatPaymentIconByProvider } from './utils';
import { ListComponent, Radio } from '@purposeinplay/core-v2';
import { ProviderInfo } from '@/types/json-content/wallet';

type Props = {
  isSelected: boolean;
  provider: ChangellyGetProviderListResponse;
  providerInfo: ProviderInfo[];
  onSelect: (provider: ChangellyProvider) => void;
};

const getProvidersImgSrc = (provider: ChangellyGetProviderListResponse) => {
  if (provider?.code === 'wert') {
    return '/assets/wert.svg';
  }
  return provider?.iconUrl;
};

const FiatProviderCard: React.FC<Props> = ({
  isSelected,
  provider,
  providerInfo,
  onSelect,
}) => {
  const isWert = provider.code === 'wert';
  // WERT logo is loaded from assets
  const additionalProps = isWert ? { loader: DEFAULT_IMAGE_LOADER } : {};

  return (
    <div
      role="button"
      onClick={() => onSelect(provider.code)}
      className={cn([
        'cursor-pointer rounded-xl border-2 border-transparent bg-surface-default px-padding-xl py-padding-lg transition-all hover:bg-surface-hovered',
        isSelected && 'border-focus-ring-primary bg-surface-hovered',
      ])}
    >
      <div className="mb-spacing-xs flex w-full flex-row items-center">
        <div className="flex flex-row items-center sm:space-x-spacing-lg">
          <Image
            src={getProvidersImgSrc(provider)}
            width={isWert ? 49 : 100}
            height={16}
            alt={provider.code}
            className="object-contain dark:invert"
            {...additionalProps}
          />
          {getFiatPaymentIconByProvider(provider?.code)}
        </div>
        <div className="ml-auto">
          <Radio
            id={provider?.code}
            defaultChecked={isSelected}
            checked={isSelected}
          />
        </div>
      </div>
      <div>
        {providerInfo?.map((info, index) => {
          return (
            <ListComponent
              key={`provider-info-${index}`}
              label={info?.label}
              value={info?.value}
              stylingOptions={{
                containerStyles:
                  'bg-transparent hover:bg-transparent cursor-default',
                valueStyles:
                  'text-right !pr-0 !py-1 text-text-subdued whitespace-nowrap',
                labelStyles: 'text-left !pl-0 !py-1 text-text-subdued',
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FiatProviderCard;
