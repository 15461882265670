import { Badge, ListComponent, Text } from '@purposeinplay/core-v2';

import {
  Bonus,
  PreviewBonusesContentType,
} from '@/types/json-content/preview_bonuses';
import TooltipAmount from './tooltip-amount';
import { getIconByCurrency } from '@/utils/helpers';
import { useState } from 'react';
import { useCurrency } from '@/hooks/currencies';
import { useGamesByIds } from '@/hooks/games/filters';
import GameThumbnails from '../game-thumnails';
import GamesModal from '../bonus-details-modal/games-modal';
import { useTranslation } from '@/app/i18n/client';

export const BonusDetailsItemExclusive = ({ bonus }: { bonus: Bonus }) => {
  const [gamesModalOpen, setGamesModalOpen] = useState(false);

  const { t } = useTranslation();
  const content = t('preview_bonuses') as PreviewBonusesContentType;

  const foundCurrency = useCurrency();

  const { data: completeGames } = useGamesByIds(
    {
      game_ids: bonus?.games?.value as string[],
    },
    {
      enabled: bonus?.games && bonus?.games?.value?.length > 0,
    },
  );

  return (
    <div
      key={bonus?.dsl_id}
      className="mb-1 flex flex-col gap-spacing-xs font-semibold text-text-default last:mb-0"
    >
      <ListComponent
        label={bonus?.title?.label}
        value={bonus?.title?.value}
        stylingOptions={{
          containerStyles: '!cursor-auto hover:bg-surface-subdued',
        }}
      />

      <div>
        <ListComponent
          stylingOptions={{
            containerStyles:
              'rounded-b-none hover:bg-surface-subdued !cursor-auto [&>div]:pb-2',
          }}
          label={bonus?.fs_total_amount?.label}
          value={
            <span className="row flex items-center justify-end gap-1">
              <>
                {bonus?.fs_total_amount?.value} Free Spins · $
                {bonus?.bet_per_spin?.value}
              </>
              {getIconByCurrency(foundCurrency?.code?.toLowerCase() ?? 'BTC')}
            </span>
          }
        />
        {bonus?.games?.value && bonus?.games?.value?.length > 0 && (
          <ListComponent
            stylingOptions={{
              containerStyles:
                '!rounded-t-none [&>div]:pt-1 hover:bg-surface-subdued !cursor-auto',
            }}
            label={bonus?.games?.label}
            value={
              <div
                className="ml-auto flex max-w-min cursor-pointer flex-row items-center justify-end space-x-spacing-xs"
                onClick={() => {
                  setGamesModalOpen(true);
                }}
              >
                <GameThumbnails completeGames={completeGames?.slice(0, 3)} />
                {bonus?.games?.value?.length > 3 && (
                  <Text size="xs" className="text-base-primary">
                    + {bonus?.games?.value?.length - 3} More
                  </Text>
                )}
              </div>
            }
          />
        )}
      </div>

      <TooltipAmount
        value={bonus?.min_deposit?.value}
        label={bonus?.min_deposit?.label}
      />
      <TooltipAmount
        value={bonus?.max_reward?.value}
        label={bonus?.max_reward?.label}
      />
      <ListComponent
        label={bonus?.wager?.label}
        stylingOptions={{
          containerStyles: '!cursor-auto hover:bg-surface-subdued',
        }}
        value={
          <div className="flex justify-end">
            <Badge radius="rounded" type="success" ghost size="md">
              {bonus?.wager?.value}
            </Badge>
          </div>
        }
      />
      <GamesModal
        bonus={bonus}
        open={gamesModalOpen}
        setOpen={setGamesModalOpen}
        completeGames={completeGames}
        title={content?.bonus_selection_modal?.games_modal_title}
        isPreview
      />
    </div>
  );
};
