import client from '@/utils/api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

type CurrentIpType = {
  ip: string;
  country_code: string;
  region_code: string;
  default_currency: string;
};

export const useGetCurrentIp = (): UseQueryResult<CurrentIpType> => {
  return useQuery({
    queryKey: ['currentIp'],
    queryFn: async () => {
      const currentIp = await client('current_ip');
      return currentIp;
    },
  });
};
