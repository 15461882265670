import { cn } from '@/utils/style';

const buttonCommonStyles = `
relative space-x-1 z-0 flex items-center justify-center font-semibold overflow-hidden select-none outline-none transition-all
disabled:cursor-not-allowed
focus:ring-0 focus:ring-offset-0
focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
h-10 px-padding-2xl py-padding-lg !text-sm rounded-lg rounded-radius-md

`;

const buttonColorStyles =
  'bg-surface-subdued text-text-default focus-visible:outline-border-general-default hover:bg-surface-hovered disabled:bg-surface-disabled disabled:text-text-disabled hover:disabled:bg-surface-disabled hover:disabled:text-text-disabled';

export const CustomButton = ({
  children,
  onClick,
  className,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}) => {
  return (
    <div
      tabIndex={0}
      role="button"
      onClick={onClick}
      className={cn([buttonColorStyles, buttonCommonStyles, className])}
    >
      {children}
    </div>
  );
};
