import { useFiatFromCents } from '@/hooks/fiat';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';

export const useFiatTotalBets = (currency: any, amount: any) => {
  const fiatAmount = useFiatFromCents(currency, amount);

  return fiatAmount;
};

export const useFiatTotalWins = (currency: any, amount: any) => {
  const fiatAmount = useFiatFromCents(currency, amount);
  return fiatAmount;
};

export const useBetInfo = (currency: string, transaction: any) => {
  const { formatCurrency } = useCurrencyFormatter();
  const formattedTotalBets = formatCurrency(
    transaction?.total_bets_crypto || transaction?.total_bets,
    transaction?.currency === 'USD' || transaction?.currency === 'EUR'
      ? transaction?.source_currency
      : currency,
    { style: 'decimal' },
  );

  const formattedTotalWins = formatCurrency(
    transaction?.total_wins_crypto || transaction?.total_wins,
    transaction?.currency === 'USD' || transaction?.currency === 'EUR'
      ? transaction?.source_currency
      : currency,
    { style: 'decimal' },
  );
  const totalBets = transaction?.total_bets_crypto || transaction.total_bets;
  const totalWins = transaction?.total_wins_crypto || transaction.total_wins;
  const profitAmount = totalWins - totalBets;
  const fiatTotalBets = useFiatTotalBets(currency, totalBets);
  const fiatTotalWins = useFiatTotalWins(currency, totalWins);
  const fiatProfit = useFiatFromCents(
    currency,
    profitAmount && !Number.isNaN(profitAmount) ? profitAmount : 0,
  );

  if (transaction.payment_system) {
    return null;
  }

  return {
    formattedTotalWins,
    formattedTotalBets,
    profitAmount,
    fiatProfit,
    fiatTotalBets,
    fiatTotalWins,
    formattedProfit: formatCurrency(profitAmount, currency, {
      style: 'decimal',
    }),
  };
};
