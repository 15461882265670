import React, { useMemo } from 'react';

import { BonusDetailItemWelcome } from './bonus-details-item-welcome';
import { Bonus } from '@/types/json-content/preview_bonuses';
import { BonusDetailItemCashback } from './bonus-details-item-cashback';
import { BonusDetailItemSportsbook } from './bonus-details-item-sportsbook';
import { BonusDetailItemVip } from './bonus-details-item-vip';
import { BonusDetailsItemExclusive } from './bonus-details-item-exclusive';

const BonusDetailsWrapper = ({
  bonus,
  type,
}: {
  bonus: Bonus;
  type: string;
}) => {
  const getContentByType = useMemo(() => {
    switch (type) {
      case 'first_deposit':
        return <BonusDetailItemWelcome bonus={bonus} />;
      case 'second_deposit':
        return <BonusDetailItemWelcome bonus={bonus} />;
      case 'third_deposit':
        return <BonusDetailItemWelcome bonus={bonus} />;
      case 'live_cashback':
        return <BonusDetailItemCashback bonus={bonus} />;
      case 'sportsbook':
        return <BonusDetailItemSportsbook bonus={bonus} />;
      case 'vip_cashback':
        return <BonusDetailItemVip bonus={bonus} />;
      case 'exclusive':
        return <BonusDetailsItemExclusive bonus={bonus} />;
      default:
        return null;
    }
  }, [type, bonus]);

  return <div>{getContentByType}</div>;
};

export default BonusDetailsWrapper;
