import { useAppSelector } from '@/hooks/redux';
import React, { useCallback, useMemo } from 'react';
import BonusDetailsWrapper from './bonus-details-wrapper';
import {
  useClearPreviewBonusCoupon,
  useSetPreviewBonusCoupon,
} from '@/hooks/bonuses';
import { useAuth } from '@/hooks/auth';
import { useToast } from '@purposeinplay/utils';
import useModal from '@/hooks/modal';
import { Accordion, Button } from '@purposeinplay/core-v2';
import { useTranslation } from '@/app/i18n/client';
import {
  Bonus,
  PreviewBonusesContentType,
} from '@/types/json-content/preview_bonuses';
import BonusDetailsBanner from './bonus-details-banner';
import { usePagesQuery } from '@/generated';
import { DEFAULT_CONTENT_STALE_TIME } from '@/utils/constants';
import { extractTranslations } from '@/utils/content';
import { useDynamicContent } from '@/utils/content-helpers';
import WheelDetails from './wheel-details';
import { useAnalytics } from '@/hooks/use-analytics';
import { getBackgroundByType, pathByBonusId } from '../../utils';
import { useSearchParams } from 'next/navigation';

const BonusSelectionDetails = ({
  type = 'bonus-selection',
}: {
  type?: 'bonuses' | 'bonus-selection' | 'wallet';
}) => {
  const bonusSelectionContent = useAppSelector(
    (state) => state.bootstrap.bonusSelctionDynamicContent,
  );

  const bonusDetailsContent = useAppSelector(
    (state) => state.bootstrap.bonusDetailsDynamicContent,
  );

  const bonusWalletContent = useAppSelector(
    (state) => state.bootstrap.walletDynamicContent,
  );

  const { toast } = useToast();

  const { mutateAsync: activateBonus, isPending: setBonusLoading } =
    useSetPreviewBonusCoupon();

  const { mutateAsync: clearBonus, isPending: clearBonusLoading } =
    useClearPreviewBonusCoupon();

  const { user } = useAuth();

  const { open } = useModal();

  const { track } = useAnalytics();

  const promoCode = useMemo(
    () => user?.currentUser?.deposit_bonus_code,
    [user],
  );

  const { t, i18n } = useTranslation();
  const content = t('preview_bonuses') as PreviewBonusesContentType;

  const isCardLoading = useMemo(() => {
    return setBonusLoading || clearBonusLoading;
  }, [setBonusLoading, clearBonusLoading]);

  const searchParams = useSearchParams();
  const bonus = searchParams.get('bonus');
  const findBonus = content?.bonus_selection_modal?.preview_bonuses?.find(
    (item) => item?.id === bonus,
  );

  const walletDynamicContent = useMemo(() => {
    return type === 'wallet'
      ? bonusWalletContent
      : type === 'bonuses'
        ? bonusDetailsContent
        : bonusSelectionContent;
  }, [bonusDetailsContent, bonusSelectionContent, bonusWalletContent, type]);

  const id = bonus ? bonus : walletDynamicContent?.data?.id;
  const bonuses = findBonus
    ? findBonus?.bonuses
    : walletDynamicContent?.data?.bonuses;

  const { data: termsPage } = usePagesQuery(
    { path: pathByBonusId(id) },
    {
      queryKey: ['pages', pathByBonusId(id)],
      staleTime: DEFAULT_CONTENT_STALE_TIME,
      enabled: !!pathByBonusId(id),
    },
  );
  const termsContent = termsPage?.pages?.data[0]?.attributes;
  const contentTerms = extractTranslations(i18n.language, termsContent);

  const dynamicContent = useDynamicContent({
    attributes: contentTerms,
  });

  const handleClaimBonus = useCallback(
    async (code: any) => {
      if (code?.coupon_code === promoCode) {
        open('wallet', { a: 'deposit', c: 'BTC' });
        return;
      }
      try {
        if (promoCode) {
          await clearBonus({});
        }
        await activateBonus(code?.coupon_code);

        toast({
          state: 'success',
          title: content?.bonus_selection_modal?.notification,
        });

        track('claim_post_signup_bonus', {
          category: 'bonuses',
          label: code,
          user_id: user?.currentUser?.id,
        });
        open('wallet', { a: 'deposit', c: 'BTC' });
      } catch (error) {
        console.error('Failed to claim bonus:', error);
      }
    },
    [
      activateBonus,
      clearBonus,
      content?.bonus_selection_modal?.notification,
      open,
      promoCode,
      toast,
      track,
      user?.currentUser?.id,
    ],
  );

  return (
    <div className="flex flex-col space-y-7">
      <BonusDetailsBanner
        title={findBonus ? findBonus?.title : walletDynamicContent?.data?.title}
        background={getBackgroundByType(id)}
      />

      {bonuses?.map((bonus: Bonus) => {
        return (
          <BonusDetailsWrapper
            key={bonus?.title?.value}
            bonus={bonus}
            type={id}
          />
        );
      })}

      {id === 'bonus-wheel-prev' && (
        <WheelDetails details={walletDynamicContent?.data} />
      )}
      {dynamicContent && dynamicContent?.length > 0 && (
        <Accordion type="underline" title={contentTerms?.title}>
          <div className="flex-start relative inline-flex w-full flex-col justify-start gap-4">
            {dynamicContent?.map((content: any) => content)}
          </div>
        </Accordion>
      )}
      {type !== 'wallet' && (
        <Button
          isLoading={isCardLoading}
          disabled={isCardLoading}
          onClick={() =>
            handleClaimBonus(findBonus ? findBonus : walletDynamicContent?.data)
          }
          className="w-full whitespace-nowrap"
        >
          {findBonus?.coupon_code === promoCode
            ? content?.bonus_selection_modal?.actions?.deposit
            : content?.bonus_selection_modal?.actions?.claim}
        </Button>
      )}
    </div>
  );
};

export default BonusSelectionDetails;
