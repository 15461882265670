import { Badge, ListComponent } from '@purposeinplay/core-v2';

import { Bonus } from '@/types/json-content/preview_bonuses';
import TooltipAmount from './tooltip-amount';

export const BonusDetailItemVip = ({ bonus }: { bonus: Bonus }) => {
  return (
    <div
      key={bonus?.dsl_id}
      className="mb-1 flex flex-col gap-spacing-xs font-semibold text-text-default last:mb-0"
    >
      <ListComponent
        label={bonus?.title?.label}
        value={bonus?.title?.value}
        stylingOptions={{
          containerStyles: '!cursor-auto hover:bg-surface-subdued',
        }}
      />
      <TooltipAmount
        value={bonus?.min_deposit?.value}
        label={bonus?.min_deposit?.label}
      />

      <ListComponent
        label={bonus?.frequency?.label}
        value={bonus?.frequency?.value}
        stylingOptions={{
          containerStyles: '!cursor-auto hover:bg-surface-subdued',
        }}
      />
      <ListComponent
        label={bonus?.wager?.label}
        value={
          <div className="flex justify-end">
            <Badge radius="rounded" type="success" ghost size="md">
              {bonus?.wager?.value}
            </Badge>
          </div>
        }
        stylingOptions={{
          containerStyles: '!cursor-auto hover:bg-surface-subdued',
        }}
      />
      <TooltipAmount
        label={bonus?.max_cashback?.label}
        value={bonus?.max_cashback?.value}
      />
    </div>
  );
};
