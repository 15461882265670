import { SignupModal } from '@/types/json-content/auth';
import { getIconByCategory } from '@/utils/helpers';
import { Heading } from '@purposeinplay/core-v2';
import React from 'react';

type Props = {
  quick: boolean;
  inOverlay: string | null;
  content: SignupModal;
};

const SignUpFormHeader: React.FC<Props> = ({ quick, inOverlay, content }) => {
  if (quick) return null;
  return (
    <div className="mb-2 flex items-center space-x-3">
      {inOverlay && (
        <div className="flex items-center justify-center">
          {getIconByCategory('contact')}
        </div>
      )}
      <Heading
        as="h1"
        className="mx-auto max-w-xs text-center text-2xl font-bold"
      >
        {content && content?.heading}
      </Heading>
    </div>
  );
};

export default SignUpFormHeader;
