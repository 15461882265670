import CryptoAmount from '@/components/CryptoAmount';
import CryptoTooltip from '@/components/CryptoTooltip';
import { useCurrency } from '@/hooks/currencies';
import { useFiatFromCrypto } from '@/hooks/fiat';
import { useAppSelector } from '@/hooks/redux';
import { ListComponent, Text } from '@purposeinplay/core-v2';
import React from 'react';

interface MaxBonusAmountProps {
  value?: any;
  label?: string;
}

const TooltipAmount: React.FC<MaxBonusAmountProps> = React.memo(
  ({ value, label }) => {
    const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
    const foundCurrency = useCurrency();
    const fiatAmountMax = useFiatFromCrypto(
      foundCurrency?.code,
      // @ts-ignore
      value[foundCurrency?.code],
    );

    return (
      <ListComponent
        stylingOptions={{
          containerStyles: 'hover:bg-surface-subdued !cursor-auto',
          valueStyles: 'flex justify-end',
        }}
        label={label}
        value={
          <CryptoTooltip
            cryptoAmount={
              !viewFiat ? (
                <CryptoAmount
                  removeIcon
                  balance={Number(
                    // @ts-ignore
                    value[foundCurrency?.code],
                  )}
                  currency={foundCurrency?.code}
                />
              ) : (
                <Text size="sm">{fiatAmountMax}</Text>
              )
            }
            cryptoCode={foundCurrency?.code}
          >
            <CryptoAmount
              balance={Number(
                // @ts-ignore
                value[foundCurrency?.code],
              )}
              currency={foundCurrency?.code}
            />
          </CryptoTooltip>
        }
      />
    );
  },
);

TooltipAmount.displayName = 'TooltipAmount';

export default TooltipAmount;
