import { Input } from '@purposeinplay/core-v2';
import React from 'react';
import { WithdrawInputProps } from '../../../types';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';
import { handleError } from '@/utils/helpers';

const OptionalInput = ({
  formData,
  setFormData,
  error,
  isError,
  paymentMethod,
}: WithdrawInputProps) => {
  const walletContent = useGetWalletContent();
  return (
    <Input
      value={
        formData[paymentMethod?.brand]?.tag
          ? formData[paymentMethod?.brand]?.tag
          : ''
      }
      onChange={(e) =>
        setFormData({
          ...formData,
          [paymentMethod?.brand]: {
            ...formData[paymentMethod?.brand],
            tag: e.target.value,
          },
        })
      }
      id="currency-code"
      errorMessage={handleError(error?.errors, 'currency')}
      error={isError}
      label={walletContent?.withdraw?.withdraw_tag?.label}
      placeholder={walletContent?.withdraw?.withdraw_tag?.placeholder}
      type="text"
      variant="static"
    />
  );
};

export default OptionalInput;
