import { SignupModal } from '@/types/json-content/auth';
import React from 'react';
import { StateType } from '@/components/Auth/signup-form';
import EmailInput from './email-input';
import PasswordInput from './password-input';
import { Field } from '@purposeinplay/core-v2';

type Props = {
  quick: boolean;
  content: SignupModal;
  isError: boolean;
  state: StateType;
  errors: { errors: Record<string, string> };
  handleChange: (e: any, field: string) => void;
  setIsPasswordValid: React.Dispatch<React.SetStateAction<boolean>>;
};

const SignUpFormFields: React.FC<Props> = React.memo(
  ({
    quick,
    content,
    isError,
    errors,
    state,
    handleChange,
    setIsPasswordValid,
  }) => {
    return (
      <>
        <Field>
          <EmailInput
            quick={quick}
            content={content}
            isError={isError}
            email={state?.email as string}
            errors={errors?.errors}
            handleChange={handleChange}
          />
        </Field>

        <Field>
          <PasswordInput
            quick={quick}
            content={content}
            isError={isError}
            password={state?.password as string}
            onValidityChange={setIsPasswordValid}
            errors={errors?.errors}
            handleChange={handleChange}
          />
        </Field>
      </>
    );
  },
);

SignUpFormFields.displayName = 'SignUpFormFields';

export default SignUpFormFields;
