import { useUpdatePlayerAccount } from '@/hooks/accounts';
import React from 'react';
import { useBalance } from '@/hooks/accounts';
import useFiatFromCents from '@/hooks/fiat';
import { FormattedAccount } from '@/types/accounts';
import useWalletSelector from '@/hooks/use-wallet-selector';
import { createQueryString, getIconByCurrency } from '@/utils/helpers';
import { useAppSelector } from '@/hooks/redux';
import { cn } from '@/utils/style';
import { ListComponent } from '@purposeinplay/core-v2';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

type CurrencyProps = {
  account: FormattedAccount;
  setOpen?: (param: boolean) => void;
};

const CurrencyListItem = React.memo(({ account, setOpen }: CurrencyProps) => {
  const { mutateAsync: updateAccount } = useUpdatePlayerAccount();
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
  const { balance, formattedBalance } = useBalance(account?.currency, false);

  const { account: cryptoAccount } = useWalletSelector();

  const fiat = useFiatFromCents(account?.currency, balance);

  const searchParams = useSearchParams();

  const currencyParam = searchParams?.get('c');
  const pathname = usePathname();
  const router = useRouter();

  return (
    <div
      className="w-full"
      role="button"
      onClick={async () => {
        await updateAccount(account.currency);
        if (currencyParam) {
          const queryString = `${pathname}?${createQueryString(
            'c',
            account?.currency,
            searchParams,
          )}`;
          router.push(queryString);
        }

        setOpen && setOpen(false);
      }}
    >
      <ListComponent
        label={
          <div className="flex h-full items-center space-x-1 text-text-default">
            <div>{getIconByCurrency(account.currency.toLowerCase())}</div>
            <div className="text-sm uppercase">{account.currency}</div>
          </div>
        }
        stylingOptions={{
          containerStyles: cn([
            'bg-transparent',
            cryptoAccount?.currency === account?.currency &&
              `bg-surface-hovered`,
          ]),
          valueStyles: 'text-right',
          labelStyles: '!py-2.5',
        }}
        value={viewFiat ? fiat : formattedBalance}
      />
    </div>
  );
});

CurrencyListItem.displayName = 'CurrencyListItem';

export default CurrencyListItem;
