import { Badge, ListComponent, Text } from '@purposeinplay/core-v2';
import React from 'react';
import { useTranslation } from '@/app/i18n/client';

import { PreviewBonusesContentType } from '@/types/json-content/preview_bonuses';
import MinDeposit from '@/components/Wallet/WalletModal/components/Deposit/components/min-deposit';
import MaxBonus from '@/components/Wallet/WalletModal/components/Deposit/components/max-bonus';

interface Props {
  details: any;
}

const WheelDetails = ({ details }: Props) => {
  const { t } = useTranslation();
  const contentAll = t('preview_bonuses') as PreviewBonusesContentType;
  const content = contentAll?.bonus_selection_modal?.wheel_details;
  return (
    <div className="mb-1 flex flex-col gap-spacing-xs font-semibold text-text-default last:mb-0">
      <ListComponent
        stylingOptions={{
          containerStyles: 'hover:bg-surface-subdued !cursor-auto',
        }}
        label={content?.wager_label}
        value={
          <div className="flex justify-end">
            <Badge radius="rounded" type="success" ghost size="md">
              {details?.wager}
            </Badge>
          </div>
        }
      />

      <ListComponent
        stylingOptions={{
          containerStyles: 'hover:bg-surface-subdued !cursor-auto',
        }}
        label={content?.min_deposit_label}
        value={<MinDeposit forList isBonusWheel={true} data={details} />}
      />
      <ListComponent
        stylingOptions={{
          containerStyles: 'hover:bg-surface-subdued !cursor-auto',
        }}
        label={content?.max_reward_label}
        value={<MaxBonus forList isBonusWheel={true} data={details} />}
      />
    </div>
  );
};

export default WheelDetails;
