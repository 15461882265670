import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import Image from 'next/image';
import React from 'react';

const DesktopImage = () => {
  return (
    <div className="absolute inset-0">
      <Image
        fill
        loader={DEFAULT_IMAGE_LOADER}
        src="/assets/desktop-confetti.png"
        alt="confetti background"
        style={{
          objectFit: 'cover',
        }}
      />

      <div className="absolute -top-6 z-20 h-80 w-full">
        <Image
          fill
          loader={DEFAULT_IMAGE_LOADER}
          src="/assets/desktop-content.png"
          alt="confetti background"
          style={{
            objectFit: 'contain',
          }}
        />
      </div>
      <div className="absolute -bottom-10 z-10 h-80 w-full">
        <Image
          fill
          loader={DEFAULT_IMAGE_LOADER}
          src="/assets/desktop-cards.png"
          alt="card image"
          style={{
            objectFit: 'cover',
          }}
        />
      </div>
    </div>
  );
};

export default DesktopImage;
