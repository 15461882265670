import React, { useCallback, useEffect, useState } from 'react';
import { Text } from '@purposeinplay/core-v2';
import { useChangellyOffers, useCreateOrder } from '@/hooks/changelly';
import { ChangellyProvider } from '@changelly/fiat-api-sdk-node';
import { Currency } from '@/types/currency';
import { useGetFoundCurrencyDeposit } from '@/hooks/use-get-found-currency-deposit';
import { useAuth } from '@/hooks/auth';
import { useGetCryptoDepositInfo } from '@/hooks/paymentsv2';
import { useFiatCurrencies } from './hooks/use-fiat-currencies';
import { useGetCurrentIp } from '@/hooks/use-get-current-ip';
import ConfirmPurchase from './confirm-purchase';
import CryptoInfo from './crypto-info';
import BuyAction from './buy-action';
import { useGetWalletContent } from '../../hooks/use-get-wallet-content';
import ChangellyFiatAmount from './fiat-amount';
import ChangellyOffers from './changelly-offers';
import ChangellyFiatProviders from './fiat-providers';

export type FormDataType = {
  amount: string;
  selectedFiat: any;
  selectedCurrency: Currency | null;
  providerCode: ChangellyProvider | null;
};

const BuyCrypto = () => {
  const walletContent = useGetWalletContent();
  const { user } = useAuth();
  const { foundCurrencyDeposit: currencyObj, isLoading: currencyLoading } =
    useGetFoundCurrencyDeposit(true);
  const [amount, setAmount] = useState('');
  const [confirmPurchase, setConfirmPurchase] = useState(false);
  const [formData, setFormData] = useState<FormDataType>({
    amount: '',
    selectedFiat: '',
    selectedCurrency: null,
    providerCode: null,
  });
  const { fiatCurrencies, isLoading } = useFiatCurrencies(
    formData?.providerCode,
  );
  const { data: currentIp, isLoading: currentIpLoading } = useGetCurrentIp();

  useEffect(() => {
    if (isLoading || !fiatCurrencies || currencyLoading || currentIpLoading)
      return;
    setFormData((prev) => ({
      ...prev,
      selectedFiat:
        fiatCurrencies?.find(
          (currency) => currency?.id === currentIp?.default_currency,
        ) || fiatCurrencies[0],
      selectedCurrency: currencyObj as any,
    }));
  }, [
    currencyLoading,
    currencyObj,
    currentIp,
    fiatCurrencies,
    isLoading,
    currentIpLoading,
  ]);

  const { data: changellyOffers, isLoading: offersLoading } =
    useChangellyOffers({
      amountFrom: formData?.amount,
      currencyFrom: formData?.selectedFiat?.value,
      currencyTo: formData?.selectedCurrency?.code,
      providerCode: formData?.providerCode,
      country: currentIp?.country_code,
      state: currentIp?.region_code,
    });

  const { mutateAsync: createOrder, isPending: createOrderPending } =
    useCreateOrder();
  const { depositInfoAdress } = useGetCryptoDepositInfo();

  const resetForm = useCallback(() => {
    setFormData((prev) => ({
      ...prev,
      amount: '',
      selectedFiat:
        fiatCurrencies?.find(
          (currency) => currency?.id === currentIp?.default_currency,
        ) || fiatCurrencies[0],
      selectedCurrency: currencyObj as any,
      providerCode: null,
    }));
    setAmount('');
    setConfirmPurchase(false);
  }, [currencyObj, currentIp?.default_currency, fiatCurrencies]);

  const handleSubmit = useCallback(async () => {
    if (currentIpLoading || !formData?.providerCode || !formData?.amount)
      return;
    const orderRes = await createOrder({
      externalOrderId: `order-${user?.currentUser?.id.toString() as string}`,
      externalUserId: user?.currentUser?.id.toString() as string,
      providerCode: formData?.providerCode as ChangellyProvider,
      currencyFrom: formData.selectedFiat?.value,
      currencyTo: formData?.selectedCurrency?.code as string,
      walletAddress: depositInfoAdress,
      amountFrom: formData?.amount,
      country: currentIp?.country_code as string,
      state: currentIp?.region_code,
    });
    if (orderRes?.redirectUrl) {
      const newWindow = window.open(
        orderRes?.redirectUrl,
        '_blank',
        'noopener,noreferrer',
      );
      if (newWindow) newWindow.opener = null;
      resetForm();
    }
  }, [
    createOrder,
    currentIp?.country_code,
    currentIp?.region_code,
    currentIpLoading,
    depositInfoAdress,
    formData?.amount,
    formData?.providerCode,
    formData?.selectedCurrency?.code,
    formData.selectedFiat?.value,
    resetForm,
    user?.currentUser?.id,
  ]);

  return confirmPurchase ? (
    <ConfirmPurchase
      currency={formData?.selectedCurrency?.code}
      fiatCurrency={formData?.selectedFiat?.value}
      formData={formData}
      createOrderPending={createOrderPending}
      resetForm={resetForm}
      currentIpLoading={currentIpLoading}
      changellyOffers={changellyOffers}
      handleSubmit={handleSubmit}
      setConfirmPurchase={setConfirmPurchase}
    />
  ) : (
    <div className="flex flex-col space-y-7">
      <CryptoInfo fiatBuy />
      <ChangellyFiatProviders
        setFormData={setFormData}
        selectedProvider={formData.providerCode}
      />
      {formData?.providerCode && (
        <>
          <ChangellyFiatAmount
            setFormData={setFormData}
            fiatCurrencies={fiatCurrencies}
            isLoading={isLoading}
            amount={amount}
            changellyOffers={changellyOffers}
            setAmount={setAmount}
          />
          <ChangellyOffers
            offersLoading={offersLoading}
            changellyOffers={changellyOffers}
            selectedCurrency={formData?.selectedCurrency?.code?.toLowerCase()}
          />
          {((!changellyOffers &&
            formData?.amount?.length > 0 &&
            !offersLoading) ||
            (changellyOffers?.length === 0 && !offersLoading)) && (
            <Text size="sm">{walletContent?.buy_crypto?.no_offer}</Text>
          )}
        </>
      )}

      <BuyAction
        offersLoading={offersLoading}
        amount={formData?.amount}
        currentIpLoading={currentIpLoading}
        changellyOffers={changellyOffers}
        providerCode={formData.providerCode}
        setConfirmPurchase={setConfirmPurchase}
      />
    </div>
  );
};

export default BuyCrypto;
