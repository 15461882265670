import React from 'react';
import { PreviewBonus as PreviewBonusType } from '@/types/bonuses';
import MinDeposit from './min-deposit';
import { useGetPreviewBonusData } from '../hooks';
import { ListComponent, Text } from '@purposeinplay/core-v2';
import { useAppDispatch } from '@/hooks/redux';
import { setWalletDynamicContent } from '@/app/reducers/bootstrap';
import CondensedPreviewHeader from './condensed-preview-bonus/condensed-preview-header';
import { useTranslation } from '@/app/i18n/client';
import { PreviewBonusesContentType } from '@/types/json-content/preview_bonuses';

const PreviewBonus = ({
  bonus,
}: {
  bonus: PreviewBonusType;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  const { t } = useTranslation();
  const contentAll = t('preview_bonuses') as PreviewBonusesContentType;
  const content = contentAll?.bonus_selection_modal?.wheel_details;

  const { dataBonusWheel, isBonusWheel, data } = useGetPreviewBonusData(
    bonus,
    true,
  );

  const dispatch = useAppDispatch();

  const handleClickDetails = () => {
    dispatch(
      setWalletDynamicContent({
        isOpen: 'bonus-details',
        data: {
          id: 'bonus-wheel-prev',
          title: bonus?.title,
          ...dataBonusWheel,
        },
        bonusToPreview: bonus,
      }),
    );
    return;
  };

  return (
    <div>
      <CondensedPreviewHeader
        bonusTitle={bonus?.title}
        handleClickDetails={handleClickDetails}
      />
      <ListComponent
        stylingOptions={{
          containerStyles: 'hover:bg-surface-subdued !cursor-auto',
        }}
        label={content?.min_deposit_label}
        value={
          <MinDeposit
            forList
            isBonusWheel={isBonusWheel}
            data={isBonusWheel ? dataBonusWheel : data}
          />
        }
      />
    </div>
  );
};

export default PreviewBonus;
