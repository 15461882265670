import { useUpdatePlayerAccount } from '@/hooks/accounts';
import { useAccountData } from '@/hooks/use-account-data';
import { useGetSortedAccounts } from '@/hooks/use-get-sorted-accounts';
import {
  CURRENCY_NAMES,
  CURRENCY_ORDER,
  USD_STABLECOINS,
} from '@/utils/constants';
import { createQueryString } from '@/utils/helpers';
import TrailingSelectItem from './trailing-select-item';
import LeadingSelectItem from '../../leading-select-item';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { SearchableSelect } from '@/components/SearchableSelect';
import { useCallback, useMemo } from 'react';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';

const CurrencySelector = ({ fiatBuy }: { fiatBuy?: boolean }) => {
  const router = useRouter();

  const { mutateAsync: addAccount } = useUpdatePlayerAccount();

  const { sortedAccounts } = useGetSortedAccounts();

  const { accountData } = useAccountData();

  const pathname = usePathname();

  const searchParams = useSearchParams();

  const walletContent = useGetWalletContent();
  const queryProtocol = searchParams.get('p');
  const action = searchParams.get('a');

  const handleSelect = useCallback(
    async (item: any) => {
      let queryString = `${pathname}?${createQueryString(
        'c',
        item.currency,
        searchParams,
      )}`;

      if (USD_STABLECOINS.includes(item?.currency) && !queryProtocol) {
        queryString = `${pathname}?m=wallet&a=${action}&c=${item.currency}&p=ERC20`;
      }

      router.push(queryString);

      const foundAccount = sortedAccounts?.find(
        (acc) => acc?.currency === item.currency,
      );

      if (!foundAccount) {
        await addAccount(item.currency);
      }
    },
    [
      action,
      addAccount,
      pathname,
      queryProtocol,
      router,
      searchParams,
      sortedAccounts,
    ],
  );

  const selectItems = useMemo(() => {
    if (fiatBuy) {
      // filter out xrp from fiat purchase
      return accountData
        ?.filter((acc) => acc?.currency?.toLowerCase() !== 'xrp')
        .map((item) => ({
          id: item.currency,
          leading: <LeadingSelectItem item={item} />,
          trailing: (
            <TrailingSelectItem
              balance={item?.balance}
              currency={item?.currency}
            />
          ),
          onClick: async () => {
            handleSelect(item);
          },
          name: CURRENCY_NAMES[item.currency],
        }));
    }
    return accountData.map((item) => ({
      id: item.currency,
      leading: <LeadingSelectItem item={item} />,
      trailing: (
        <TrailingSelectItem balance={item.balance} currency={item.currency} />
      ),
      onClick: async () => {
        handleSelect(item);
      },
      name: CURRENCY_NAMES[item.currency],
    }));
  }, [accountData, handleSelect, fiatBuy]);

  const orderedSelectItems = useMemo(() => {
    const getCurrencyOrder = (currency: string) =>
      CURRENCY_ORDER.indexOf(currency) >= 0
        ? CURRENCY_ORDER.indexOf(currency)
        : Infinity;

    return selectItems?.sort(
      (a, b) => getCurrencyOrder(a.id) - getCurrencyOrder(b.id),
    );
  }, [selectItems]);

  return (
    <SearchableSelect
      forDropdown={false}
      label={walletContent?.deposit?.currency?.label}
      items={orderedSelectItems}
    />
  );
};

export default CurrencySelector;
