import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import client from '@/utils/api';
import fp from 'lodash/fp';
import dayjs from '@/utils/dayjsconfig';
import { usePlayerPayments } from './payments';
import { useCallback, useEffect, useState } from 'react';

const fetchAllBets = async () => {
  try {
    const response = await client(`player/games`);
    return response;
  } catch (e) {
    throw e;
  }
};

const filterTransactions = (payments: any, filters: any) => {
  const funcList = [];

  if (filters.currency) {
    const currencies = filters.currency.split(',');
    funcList.push(fp.filter(filterByCurrency(currencies)));
  }

  if (filters.action) {
    const action = filters.action.split(',');
    funcList.push(fp.filter(filterByAction(action)));
  }

  if (filters.currency || filters.action) {
    return fp.compose(funcList)(payments);
  }

  return payments;
};

const filterByCurrency = fp.curry((currencies: any, transaction: any) => {
  return currencies.includes(
    transaction?.currency === 'USD' || transaction?.currency === 'EUR'
      ? transaction?.source_currency
      : transaction?.currency,
  );
});

const filterByAction = fp.curry((action: any, transaction: any) => {
  return (
    action.includes(transaction.action) ||
    (action.includes('bet') && transaction.game)
  );
});

const fetchTransactions = async (payments: any, pageParam: any) => {
  const { offset, limit } = pageParam;
  let transactions = [];
  try {
    const bets = await fetchAllBets();
    transactions = [...payments, ...bets];
  } catch (e) {
    throw e;
  }
  const filteredTransactions = filterTransactions(transactions, {
    ...pageParam,
    currency: pageParam.currency === 'all' ? false : pageParam.currency,
  });
  const sortedTransactions =
    filteredTransactions &&
    filteredTransactions.sort((current: any, next: any) => {
      return dayjs(current.created_at).isAfter(dayjs(next.created_at))
        ? -1
        : dayjs(current.created_at).isBefore(dayjs(next.created_at))
          ? 1
          : 0;
    });

  const paginatedTransactions = limit
    ? sortedTransactions.slice(offset, offset + limit)
    : sortedTransactions;

  return {
    transactions: paginatedTransactions,
    totalCount: sortedTransactions && sortedTransactions.length,
  };
};

const usePlayerTransactions = (pageParams: any) => {
  const { data: payments = [], isLoading: paymentsLoading } =
    usePlayerPayments();

  const { data, isLoading, isRefetching, fetchNextPage, isFetching } =
    useInfiniteQuery({
      initialPageParam: pageParams,
      queryKey: ['playerTransactions', pageParams, payments],
      queryFn: async ({ queryKey, pageParam }) => {
        const transactions = await fetchTransactions(queryKey[2], pageParam);
        return transactions;
      },
      placeholderData: keepPreviousData,
      getNextPageParam: (lastPage: any, allPages, lastPageParam) => {
        return {
          ...pageParams,
          offset: lastPageParam?.offset + 10,
        };
      },
      refetchOnMount: true,
      enabled: pageParams.currency && !paymentsLoading ? true : false,
    });

  const transactions = data?.pages?.map((page) => page?.transactions)?.flat();
  const totalCount = data?.pages[data?.pages.length - 1]?.totalCount;

  return {
    fetchNextPage,
    data: { transactions, totalCount },
    isLoading: isLoading || isRefetching,
    isFetching: isFetching,
  };
};

export { usePlayerTransactions };
