import { useSearchParams } from 'next/navigation';
import React, { useEffect, useMemo, useState } from 'react';
import { useCryptoDepositInfo } from './paymentsv2';
import useModal from './modal';
import { PROTOCOL_NAMES } from '@/components/Wallet/WalletModal/constants';
import { USD_STABLECOINS } from '@/utils/constants';

const useGetProtocols = () => {
  const [protocol, setProtocol] = useState<any>({});
  const { create, closeOnly, replace } = useModal();
  const searchParams = useSearchParams();

  const currency = searchParams.get('c') || 'BTC';
  const protocolInQuery = searchParams.get('p');

  const { depositInfo } = useCryptoDepositInfo(currency.toUpperCase());

  const protocols = useMemo(() => {
    if (
      !depositInfo &&
      !depositInfo?.protocols &&
      USD_STABLECOINS.includes(currency) &&
      // USDC should not have a default protocols selector because there's only one protocol
      currency !== 'USDC'
    ) {
      // deposit_info returns 403, return default protocols;
      return Object.keys(PROTOCOL_NAMES)?.map((key: any) => {
        return {
          id: key,
          // @ts-ignore
          component: <div>{PROTOCOL_NAMES[key]}</div>,
        };
      });
    }

    return (
      depositInfo?.protocols &&
      depositInfo?.protocols?.map((protocol: 'ERC20' | 'TRC20' | 'BEP20') => ({
        id: protocol,
        component: <div>{PROTOCOL_NAMES[protocol]}</div>,
      }))
    );
  }, [currency, depositInfo]);

  useEffect(() => {
    if (protocolInQuery && currency === 'USDC' && protocolInQuery !== 'ERC20') {
      // USDC should always have ERC20 protocol, ensure replace only if necessary
      return replace('p', 'ERC20');
    }

    if (protocols && !protocolInQuery && USD_STABLECOINS.includes(currency)) {
      setProtocol(protocols[0]);
      create('p', protocols[0].id);
    }

    if (!protocols && protocolInQuery && !USD_STABLECOINS.includes(currency)) {
      closeOnly(['p']); // Only close the param if the currency is not USDC or USDT
    }
  }, [closeOnly, create, currency, protocolInQuery, protocols, replace]);

  return { selectedProtocol: protocol, protocols };
};

export default useGetProtocols;
