import { useMemo } from 'react';
import { Currency } from '@/types/currency';
import { useCryptoRates, useFiatFromCrypto } from '@/hooks/fiat';
import { useAppSelector } from '@/hooks/redux';

export const useFiatMaxCashout = (
  maxCashout: any,
  currency: string | undefined,
) => {
  const fiatBalance = useFiatFromCrypto(currency, maxCashout);
  if (!maxCashout) return;
  return fiatBalance;
};

export const useFiatMinCashout = (
  minCashout: number | null,
  currency: string | undefined,
) => {
  const fiatBalance = useFiatFromCrypto(currency, minCashout);
  return fiatBalance;
};

export const useFiatFee = (
  fee: number | null,
  currency: string | undefined,
) => {
  const fiatFee = useFiatFromCrypto(currency, fee);
  if (!fiatFee) return;
  return fiatFee;
};

export const useFiatSum = (sum: string | number) => {
  if (!sum) return `Withdraw`;
  return `Withdraw $${sum}`;
};

export const useWithdrawableAmount = (sum: number, currency: Currency) => {
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
  const { data: cryptoListings }: any = useCryptoRates();

  const foundCrypto = useMemo(
    () =>
      cryptoListings?.find(
        (crypto: Currency) => crypto?.symbol === currency?.code,
      ),
    [currency, cryptoListings],
  );

  const cryptoSum = useMemo(() => {
    if (!sum) return;
    const price = foundCrypto?.quote.USD.price;
    return parseFloat((sum / price).toFixed(6));
  }, [foundCrypto, sum]);

  if (!viewFiat) {
    return sum;
  }

  return cryptoSum;
};
