import React, { useMemo, useState } from 'react';
import { TransactionProps } from '../../../types';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useBetInfo } from '../../../hooks/use-bet-info';
import { useCryptoFromFiat, useFiatFromCents } from '@/hooks/fiat';
import { useCancelCashout } from '@/hooks/payments';
import ArrowUpSVG from 'public/assets/wallet/long-arrow-up.svg';
import ArrowDownSVG from 'public/assets/wallet/long-arrow-down.svg';
import CloseSVG from 'public/assets/wallet/close.svg';
import dayjs from '@/utils/dayjsconfig';
import {
  Badge,
  IconButton,
  TableCell,
  TableRow,
  Text,
  Tooltip,
} from '@purposeinplay/core-v2';

import CryptoTooltip from '@/components/CryptoTooltip';
import FiatAmount from '@/components/FiatAmount';
import { getIconByCurrency } from '@/utils/helpers';
import Spinner from '@/components/Spinner';
import ConfirmationModal from '@/components/ConfirmationModal';
import { useQueryClient } from '@tanstack/react-query';
import {
  getTextColorByType,
  isValidBadgeType,
  useCalculateTransactionType,
} from '../utils';
import { setWalletDynamicContent } from '@/app/reducers/bootstrap';
import { cn } from '@/utils/style';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';
import useMediaQuery, { MOBILE_BREAKPOINT } from '@/hooks/use-media-query';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import { Currency } from '@/types/currency';
import { DateFormat } from '@/utils/date';

const ArrowUpDown = ({
  className,
  betInfo,
  transaction,
}: {
  className?: string;
  betInfo: any;
  transaction: any;
}) => {
  if (
    (betInfo?.profitAmount && betInfo?.profitAmount >= 0) ||
    transaction.action === 'cashout'
  ) {
    return (
      <ArrowUpSVG
        className={cn(['h-4 w-4 fill-current text-green-500', className])}
      />
    );
  } else {
    return (
      <ArrowDownSVG
        className={cn(['h-4 w-4 fill-current text-red-500', className])}
      />
    );
  }
};

const TransactionTableRow = ({
  transaction,
}: {
  transaction: TransactionProps;
}) => {
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const { matchesQuery: isMobile } = useMediaQuery(MOBILE_BREAKPOINT);

  const transactionType = useCalculateTransactionType(transaction);

  const walletContent = useGetWalletContent();

  const queryClient = useQueryClient();

  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);

  const currency = transaction?.source_currency || transaction.currency;

  const type =
    transaction?.action === 'deposit'
      ? 'deposit'
      : !transaction.action
        ? 'bet'
        : 'cashout';

  const betInfo = useBetInfo(currency, transaction);

  const showInFiat = useMemo(
    () => viewFiat && betInfo?.fiatProfit,
    [viewFiat, betInfo],
  );
  const fiatAmount = useFiatFromCents(currency, transaction.amount_cents);

  const { mutateAsync: cancelCashout, isPending } = useCancelCashout();

  const transactionId = transaction?.id;

  const dispatch = useAppDispatch();

  const { formatCurrency } = useCurrencyFormatter();

  const amountCrypto = formatCurrency(transaction?.amount_cents || 0, currency);

  return (
    <TableRow
      className="cursor-pointer hover:bg-surface-default"
      onRowSelect={() =>
        dispatch(
          setWalletDynamicContent({
            data: {
              ...transaction,
              type,
              status: transactionType,
            },
            isOpen: 'details',
          }),
        )
      }
    >
      <TableCell className="m-0 !rounded-l-xl py-[3px]">
        <div className={'flex flex-row items-center space-x-2 '}>
          {!isMobile && (
            <ArrowUpDown
              betInfo={betInfo}
              transaction={transaction}
              className="h-7 w-7 rounded p-1"
            />
          )}
          <div className="flex flex-col">
            <div
              className={cn([
                'flex flex-row items-center space-x-1',
                isMobile && 'py-1',
              ])}
            >
              {isMobile && (
                <ArrowUpDown betInfo={betInfo} transaction={transaction} />
              )}
              <Text
                size="sm"
                as="span"
                className="mb-0.5 leading-none text-text-default"
              >
                {/* This capitalizes the first letter of the type prop */}
                {type?.charAt(0).toUpperCase() + type?.slice(1)}
              </Text>
            </div>

            <Text size="xs" className="leading-none text-text-subdued">
              {dayjs(transaction.created_at).format(DateFormat.DATE_WITH_TIME)}
            </Text>
          </div>
        </div>
      </TableCell>
      <TableCell className={cn(['py-[3px]', isMobile && '!rounded-r-xl'])}>
        <div className={cn(['flex flex-col', isMobile && 'items-end'])}>
          <div className={cn([isMobile && 'mb-1'])}>
            {betInfo ? (
              <div
                className={cn([
                  'flex flex-row items-center justify-end space-x-1 ',
                  !isMobile && 'justify-start',
                ])}
              >
                <div className="text-sm">
                  {showInFiat ? (
                    <CryptoTooltip
                      cryptoAmount={betInfo?.formattedProfit?.split(' ')[0]}
                      cryptoCode={currency}
                    >
                      <FiatAmount
                        fiatAmount={betInfo?.fiatProfit}
                        currency={currency}
                      />
                    </CryptoTooltip>
                  ) : (
                    betInfo?.formattedProfit?.split(' ')[0]
                  )}
                </div>
                {!showInFiat && (
                  <span>{getIconByCurrency(currency.toLowerCase())}</span>
                )}
              </div>
            ) : viewFiat ? (
              <CryptoTooltip cryptoAmount={amountCrypto} cryptoCode={currency}>
                {fiatAmount}
              </CryptoTooltip>
            ) : (
              <div
                className={cn([
                  'flex flex-row items-center justify-end',
                  !isMobile && 'justify-start',
                ])}
              >
                <div className="text-sm font-normal leading-4 text-text-default">
                  {formatCurrency(Number(transaction.amount_cents), currency, {
                    style: 'decimal',
                  })}
                </div>
                <span className="ml-1">
                  {getIconByCurrency(currency.toLowerCase())}
                </span>
              </div>
            )}
          </div>
          <div>
            {transactionType.type === 'Pending' && isMobile ? (
              <div className="flex flex-row items-center justify-end">
                <Badge
                  size="md"
                  ghost
                  radius="rounded"
                  type={
                    isValidBadgeType(transactionType?.tag)
                      ? transactionType?.tag
                      : 'neutral'
                  }
                >
                  {transactionType.type}
                </Badge>
                <Tooltip
                  styleOptions={{
                    arrowStyles: 'z-[99999]',
                    popperStyles: 'z-[99999]',
                  }}
                  trigger={
                    <IconButton
                      size="xs"
                      iconOnly
                      iconLeft={isPending ? <Spinner /> : <CloseSVG />}
                      ghost
                      onClick={(e) => {
                        e.stopPropagation();
                        setCancelModalOpen(true);
                      }}
                      disabled={isPending}
                    />
                  }
                  placement={'top'}
                  displayOn={'hover'}
                >
                  {walletContent?.transactions?.cancel_withdrawal_modal?.title}
                </Tooltip>
              </div>
            ) : (
              isMobile && (
                <Text
                  size="xs"
                  className={cn([getTextColorByType(transactionType.type)])}
                >
                  {transactionType.type}
                </Text>
              )
            )}
          </div>
        </div>
      </TableCell>
      {!isMobile && (
        <TableCell className="!rounded-r-xl">
          <div className="flex flex-row items-center justify-end space-x-1">
            <Badge
              size="lg"
              ghost
              radius="rounded"
              type={
                isValidBadgeType(transactionType?.tag)
                  ? transactionType?.tag
                  : 'neutral'
              }
            >
              {transactionType.type}
            </Badge>
            {transactionType.type === 'Pending' && (
              <Tooltip
                styleOptions={{
                  arrowStyles: '!z-[99999]',
                  popperStyles: '!z-[99999]',
                }}
                trigger={
                  <IconButton
                    size="xs"
                    iconOnly
                    iconLeft={isPending ? <Spinner /> : <CloseSVG />}
                    ghost
                    onClick={(e) => {
                      e.stopPropagation();
                      setCancelModalOpen(true);
                    }}
                    disabled={isPending}
                  />
                }
                placement={'top'}
                displayOn={'hover'}
              >
                {walletContent?.transactions?.cancel_withdrawal_modal?.title}
              </Tooltip>
            )}
          </div>
        </TableCell>
      )}
      <ConfirmationModal
        title={walletContent?.transactions?.cancel_withdrawal_modal?.title}
        content={
          walletContent?.transactions?.cancel_withdrawal_modal?.description
        }
        isPrimaryLoading={isPending}
        isOpen={cancelModalOpen}
        setIsOpen={setCancelModalOpen}
        onClickPrimary={() => {
          if (!transactionId) return;
          cancelCashout(transactionId)
            .then(() => {
              setCancelModalOpen(false);
              queryClient.invalidateQueries({ queryKey: ['playerPayments'] });
            })
            .catch(() => setCancelModalOpen(false));
        }}
      />
    </TableRow>
  );
};

export default TransactionTableRow;
