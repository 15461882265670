import { useBalance } from '@/hooks/accounts';
import { Button } from '@purposeinplay/core-v2';
import { useSearchParams } from 'next/navigation';
import React, { useMemo } from 'react';
import { WithdrawInputProps } from '../../../types';
import { useAppSelector } from '@/hooks/redux';

const Button100 = ({
  setFormData,
  formData,
  paymentMethod,
}: WithdrawInputProps) => {
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
  const searchParams = useSearchParams();
  const currency = searchParams.get('c');
  const { withdrawableBalance, fiatWithdrawableBalance } = useBalance(
    currency,
    true,
  );

  const isButtonDisabled = useMemo(() => {
    return (withdrawableBalance as number) <= 0;
  }, [withdrawableBalance]);

  return (
    <Button
      type="button"
      onClick={(e) => {
        e.preventDefault();
        if ((withdrawableBalance as number) <= 0) return;
        if (viewFiat) {
          setFormData({
            ...formData,
            [paymentMethod?.brand]: {
              ...formData[paymentMethod?.brand],
              sum: fiatWithdrawableBalance,
            },
          });
          return;
        }
        setFormData({
          ...formData,
          [paymentMethod?.brand]: {
            ...formData[paymentMethod?.brand],
            sum: withdrawableBalance?.toString(),
          },
        });
      }}
      disabled={isButtonDisabled}
      size="xs"
      color="tertiary"
    >
      100%
    </Button>
  );
};

export default Button100;
