import React, { useEffect, useMemo } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { useState } from 'react';
import { SearchInput } from './search-input';
import { SelectItemType } from './types';
import { filterItemsByBalance } from './utils';
import { Chevron } from './components/helpers';
import { useAppSelector } from '@/hooks/redux';
import { useSearchParams } from 'next/navigation';
import { SearchableSelectLoader, TextLoader } from '../Loaders';
import { Label, Field } from '@purposeinplay/core-v2';
import { cn } from '@/utils/style';

interface Props {
  items: SelectItemType[];
  column?: boolean;
  selected?: SelectItemType;
  label?: string;
  forDropdown?: boolean;
}

export const SearchableSelect = React.memo(
  ({ items, label, forDropdown = false, ...props }: Props) => {
    const searchParams = useSearchParams();
    const currency = searchParams.get('c');

    const isBuyCrypto = searchParams.get('a') === 'buy-crypto';

    const selectedItem = useMemo(() => {
      return items.find(
        (item: SelectItemType) => item.id === (currency || 'BTC'),
      );
    }, [currency, items]) as SelectItemType;

    const hideZeroBalances = useAppSelector(
      (state) => state.bootstrap.hideZeroBalances,
    );

    const filteredItems = filterItemsByBalance(
      items,
      isBuyCrypto ? false : hideZeroBalances,
    );

    if (!selectedItem) return <SearchableSelectLoader />;

    return (
      <Popover className="relative z-[1000] flex flex-col " {...props}>
        {({ open }) => (
          <Field>
            {label && <Label htmlFor="searchable_select_button">{label}</Label>}
            <Popover.Button
              id="searchable_select_button"
              className="relative z-10   flex w-full items-center justify-between overflow-hidden rounded-radius-minimal border border-border-general-default bg-surface-default px-3 py-[9px] text-sm font-semibold leading-5 text-text-default  focus:border-base-primary focus:border-transparent focus:outline-none focus:ring-2 focus:ring-inset focus:ring-base-primary"
            >
              <div className="flex w-full justify-between pr-6">
                {selectedItem?.leading}
                {selectedItem?.trailing}
              </div>
              <Chevron open={open} />
            </Popover.Button>
            <Transition
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0  mt-1 flex w-full">
                {({ close }) => (
                  <div
                    className={cn([
                      'scrollbar relative grid w-full  grid-cols-1 overflow-hidden rounded-xl bg-bgr-lightest p-3',
                      forDropdown && 'bg-bgr-lighter pb-0',
                    ])}
                  >
                    <SearchInput
                      forDropdown={forDropdown}
                      close={close}
                      items={filteredItems}
                    />
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </Field>
        )}
      </Popover>
    );
  },
);

SearchableSelect.displayName = 'SearchableSelect';

export default SearchableSelect;
