import React from 'react';
import MandatoryData from '../Deposit/components/mandatory-data';
import CurrencySelector from '../Deposit/components/currency-selector';

const CryptoInfo = React.memo(({ fiatBuy }: { fiatBuy?: boolean }) => {
  return (
    <>
      <CurrencySelector fiatBuy={fiatBuy} />
      <MandatoryData />
    </>
  );
});

CryptoInfo.displayName = 'FiatCryptoInfo';

export default CryptoInfo;
