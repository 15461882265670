import { useConfirmationEmail } from '@/hooks/profile';
import { Button } from '@purposeinplay/core-v2';
import React from 'react';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';
import EmptyState from '@/components/EmptyState';

const EmailConfirmation = ({ email }: { email: string }) => {
  const { mutateAsync: emailVerification, isPending } = useConfirmationEmail();
  const walletContent = useGetWalletContent();
  return (
    <div className="flex flex-col">
      <EmptyState
        className="py-7"
        title={walletContent?.withdraw?.email_confirmation?.title}
        description={walletContent?.withdraw?.email_confirmation?.description}
        button={
          <Button
            className="mx-auto w-auto"
            isLoading={isPending}
            disabled={isPending}
            ghost
            color="tertiary"
            onClick={() => emailVerification(email)}
          >
            {walletContent?.withdraw?.email_confirmation?.btn_text}
          </Button>
        }
      />
    </div>
  );
};

export default EmailConfirmation;
