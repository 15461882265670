import { SignupModal } from '@/types/json-content/auth';
import { cn } from '@/utils/style';
import { Button, Field, Label, Text } from '@purposeinplay/core-v2';
import Link from 'next/link';
import React from 'react';
import PrivacyTermsLabel from './privacy-terms-label';
import { StateType } from '@/components/Auth/signup-form';
import { handleError } from '@/utils/helpers';

type Props = {
  content: SignupModal;
  isPending: boolean;
  isError: boolean;
  error: any;
  quick: boolean;
  inOverlay: string | null;
  isActionDisabled: boolean;
  state: StateType;
  setState: React.Dispatch<React.SetStateAction<StateType>>;
  openLoginModal?: () => void;
};

const SignUpFooter: React.FC<Props> = React.memo(
  ({
    isPending,
    content,
    inOverlay,
    quick,
    openLoginModal,
    state,
    setState,
    isActionDisabled,
    isError,
    error,
  }) => {
    return (
      <div>
        <Field>
          <PrivacyTermsLabel
            state={state}
            setState={setState}
            inOverlay={inOverlay}
            quick={quick}
          >
            {isError && handleError(error?.errors, 'profile') && (
              <Label htmlFor="terms_acceptance" className="text-text-critical">
                {handleError(error?.errors, 'profile')?.accepted}
              </Label>
            )}
          </PrivacyTermsLabel>
        </Field>
        <Field className={cn([inOverlay && `flex max-w-[16rem] space-x-2`])}>
          <Button
            size="md"
            type="submit"
            className="mb-3 w-full max-w-full "
            isLoading={isPending}
            disabled={isActionDisabled}
          >
            {content?.form?.action?.btn_label}
          </Button>
          {inOverlay && (
            <Button
              size="md"
              onClick={() => {
                close();
              }}
              ghost
              isLoading={isPending}
              className="w-full max-w-full"
              disabled={isActionDisabled}
            >
              {content?.form?.for_fun_btn}
            </Button>
          )}
          {!inOverlay && !quick && (
            <div className="ml-auto flex items-center">
              <Text size="sm" className="mr-1.5 text-text-subdued">
                {content?.form?.already_has_account?.message}
              </Text>

              {openLoginModal ? (
                <div onClick={() => openLoginModal()}>
                  <p className="cursor-pointer text-sm font-bold leading-none text-base-primary">
                    {content?.form?.already_has_account?.action}
                  </p>
                </div>
              ) : (
                <Link
                  href={content?.form?.already_has_account?.action_href}
                  className="text-sm font-bold leading-5 text-base-primary md:text-sm lg:text-sm"
                >
                  {content?.form?.already_has_account?.action}
                </Link>
              )}
            </div>
          )}
        </Field>
      </div>
    );
  },
);

SignUpFooter.displayName = 'SignUpFooter';

export default SignUpFooter;
