import { useAppSelector } from '@/hooks/redux';
import { PreviewBonus } from '@/types/bonuses';
import React from 'react';

import { BonusDetailItem } from './bonus-detail-item';

const BonusDetails = ({ isInWallet = false }: { isInWallet?: boolean }) => {
  const walletDynamicContent = useAppSelector(
    (state) => state.bootstrap.walletDynamicContent,
  );

  return (
    <div className="flex flex-col space-y-7">
      {isInWallet ? (
        <BonusDetailItem
          key={walletDynamicContent?.bonusToPreview?.title}
          bonus={walletDynamicContent?.bonusToPreview}
          isInWallet={true}
        />
      ) : (
        walletDynamicContent?.data?.bonuses?.map((bonus: PreviewBonus) => (
          <BonusDetailItem key={bonus?.title} bonus={bonus} />
        ))
      )}
    </div>
  );
};

export default BonusDetails;
