import { usePlayerLimits } from './limits';
import { useMemo } from 'react';
import { SessionLimit as SessionLimitType } from '@/types/user';

export const useGetFilteredLimit = () => {
  const { data: limits = [], isPending: limitsLoading } = usePlayerLimits();

  const filteredLimit = useMemo(() => {
    const limit = limits?.find(
      // @ts-ignore
      (limit: SessionLimitType) =>
        limit.type === 'coolingoff' &&
        (limit.status === 'active' || limit.status === 'unconfirmed_disable'),
    );
    return limit;
  }, [limits]);

  return { filteredLimit, limitsLoading };
};
