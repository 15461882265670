import React from 'react';
import { FormDataType } from '.';
import { cn } from '@/utils/style';
import { useChangellyProviders } from '@/hooks/changelly';
import { DivLoader } from '@/components/Loaders';
import FiatProviderCard from './fiat-provider-card';
import { ChangellyProvider } from '@changelly/fiat-api-sdk-node';
import { Field, Label } from '@purposeinplay/core-v2';
import { useGetWalletContent } from '../../hooks/use-get-wallet-content';

type Props = {
  setFormData: React.Dispatch<React.SetStateAction<FormDataType>>;
  selectedProvider?: ChangellyProvider | null;
};

const ChangellyFiatProviders: React.FC<Props> = ({
  selectedProvider,
  setFormData,
}) => {
  const { data: fiatProviders, isLoading } = useChangellyProviders();
  const walletContent = useGetWalletContent();

  if (isLoading) {
    return (
      <div className="grid grid-cols-1 gap-2">
        <DivLoader className="h-[100px] w-full rounded-xl border-2 border-transparent bg-surface-subdued px-padding-xl py-padding-lg lg:w-full" />
        <DivLoader className="h-[100px] w-full rounded-xl border-2 border-transparent bg-surface-subdued px-padding-xl py-padding-lg lg:w-full" />
        <DivLoader className="h-[100px] w-full rounded-xl border-2 border-transparent bg-surface-subdued px-padding-xl py-padding-lg lg:w-full" />
      </div>
    );
  }
  return (
    <Field>
      <Label>{walletContent?.buy_crypto?.providers?.label}</Label>
      <div className={cn(['grid grid-cols-1 gap-2'])}>
        {fiatProviders?.map((provider) => (
          <FiatProviderCard
            key={`fiat-provider-${provider.code}`}
            provider={provider}
            providerInfo={
              walletContent?.buy_crypto?.providers?.info[provider?.code]
            }
            onSelect={(provider: ChangellyProvider) =>
              setFormData((prev) => ({
                ...prev,
                providerCode: provider,
              }))
            }
            isSelected={selectedProvider === provider.code}
          />
        ))}
      </div>
    </Field>
  );
};

export default ChangellyFiatProviders;
