import { SigninModal } from '@/types/json-content/auth';
import { handleError } from '@/utils/helpers';
import { Field, Input } from '@purposeinplay/core-v2';
import React from 'react';
import { StateType } from '@/components/Auth/signin-form';
import Link from 'next/link';

type Props = {
  content: SigninModal;
  isError: boolean;
  error: { errors: Record<string, string> };
  state: StateType;
  closeModal?: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, type: string) => void;
  openForgotPasswordModal?: () => void;
};

const SignInFormFields: React.FC<Props> = React.memo(
  ({
    content,
    isError,
    error,
    state,
    handleChange,
    closeModal,
    openForgotPasswordModal,
  }) => {
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(e, 'email');
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(e, 'password');
    const handleForgotPasswordClick = () => {
      if (closeModal) closeModal();
      if (openForgotPasswordModal) openForgotPasswordModal();
    };

    return (
      <>
        <Field>
          <Input
            id="email"
            type="email"
            variant="static"
            error={isError && handleError(error.errors, 'email')}
            errorMessage={isError && handleError(error.errors, 'email')}
            label={content?.form?.email?.label}
            placeholder={content?.form?.email?.placeholder}
            value={state.email}
            onChange={handleEmailChange}
          />
        </Field>
        <Field>
          <Input
            id="password"
            type="password"
            variant="static"
            hidePasswordStrength
            autoComplete="on"
            error={isError && handleError(error.errors, 'password')}
            errorMessage={isError && handleError(error.errors, 'password')}
            label={content?.form?.password?.label}
            placeholder={content?.form?.password?.label}
            value={state.password}
            onChange={handlePasswordChange}
          />
          {closeModal && openForgotPasswordModal ? (
            <div onClick={handleForgotPasswordClick}>
              <span className="cursor-pointer text-sm font-bold leading-none text-base-primary">
                {content?.form?.forgot_password?.label}
              </span>
            </div>
          ) : (
            <Link href="/forgot-password" passHref>
              <span className="text-sm font-bold leading-none text-base-primary">
                {content?.form?.forgot_password?.label}
              </span>
            </Link>
          )}
        </Field>
      </>
    );
  },
);

SignInFormFields.displayName = 'SignInFormFields';

export default SignInFormFields;
