import { ForgotPasswordModal } from '@/types/json-content/auth';
import { Text } from '@purposeinplay/core-v2';
import Link from 'next/link';
import React from 'react';

type Props = {
  content: ForgotPasswordModal;
  closeModal?: () => void;
  isPending: boolean;
  openLoginModal?: () => void;
};

const ForgotPasswordFormFooter: React.FC<Props> = React.memo(
  ({ closeModal, openLoginModal, content, isPending }) => {
    return (
      <>
        {closeModal && openLoginModal ? (
          <div className="flex flex-wrap items-center justify-start space-x-2">
            <Text size="sm" className="font-medium leading-4 text-text-subdued">
              {content?.form?.already_has_account?.message}
            </Text>
            <span
              onClick={() => {
                closeModal();
                openLoginModal();
              }}
              className="cursor-pointer text-sm font-bold leading-none text-base-primary"
            >
              {content?.form?.already_has_account?.action}
            </span>
          </div>
        ) : (
          <Link
            href={content?.form?.already_has_account?.action_href}
            className="cursor-pointer text-sm font-bold leading-none text-base-primary"
          >
            <div className="flex items-center  justify-center space-x-2">
              <Text className="text-sm font-medium text-text-subdued">
                {content?.form?.already_has_account?.message}
              </Text>
              <span> {content?.form?.already_has_account?.action}</span>
            </div>
          </Link>
        )}
      </>
    );
  },
);

ForgotPasswordFormFooter.displayName = 'ForgotPasswordFormFooter';

export default ForgotPasswordFormFooter;
