import { useGetFilteredLimit } from '@/hooks/use-get-filtered-limit';
import React from 'react';
import RestrictedDeposit from './components/restricted-deposit';
import DepositContent from './components/deposit-content';

const Deposit = () => {
  const { filteredLimit } = useGetFilteredLimit();

  return filteredLimit ? <RestrictedDeposit /> : <DepositContent />;
};

export default Deposit;
