import { ForgotPasswordModal } from '@/types/json-content/auth';
import { Heading, Text } from '@purposeinplay/core-v2';
import React from 'react';

type Props = {
  content: ForgotPasswordModal;
};
const ForgotPasswordFormHeader: React.FC<Props> = ({ content }) => {
  return (
    <div className="mb-7">
      <Heading as="h1" className="mb-1 max-w-xs text-2xl md:max-w-xs ">
        {content?.heading}
      </Heading>
      <Text size="base" className="font-light text-text-subdued">
        {content?.description}
      </Text>
    </div>
  );
};

export default ForgotPasswordFormHeader;
