import { useMemo } from 'react';
import { useCurrencies } from './currencies';
import useUpdateMissingCurrency from './use-update-missing-currency';
import { Currency } from '@/types/currency';
import useWalletSelector from './use-wallet-selector';

export const useGetFoundCurrencyDeposit = (fiatBuy = false) => {
  const { selectedCurr } = useUpdateMissingCurrency(fiatBuy);

  const { data: currencies, isLoading } = useCurrencies();
  const { formattedAccounts, account: selectedAcc } = useWalletSelector();

  const foundCurrencyDeposit = useMemo((): Currency | undefined => {
    if (isLoading) return;
    return selectedCurr
      ? currencies.find(
          (item: Currency) => item.code.toLowerCase() === selectedCurr,
        )
      : currencies.find(
          (item: Currency) => item.code === selectedAcc?.currency,
        );
  }, [currencies, selectedCurr, selectedAcc, isLoading]);
  return { foundCurrencyDeposit, isLoading };
};
