import { InputWithLabelLoader } from '@/components/Loaders';
import { Input, Select } from '@purposeinplay/core-v2';
import React, { useCallback, useMemo } from 'react';
import { useGetWalletContent } from '../../hooks/use-get-wallet-content';
import {
  ChangellyGetCurrencyListResponse,
  ChangellyGetOffersResponse,
} from '@changelly/fiat-api-sdk-node';
import { useGetCurrentIp } from '@/hooks/use-get-current-ip';
import { FormDataType } from '.';
import useDebounce from '@/hooks/debounce';

type Props = {
  setFormData: React.Dispatch<React.SetStateAction<FormDataType>>;
  fiatCurrencies: {
    id: string;
    component: React.JSX.Element;
    value: string;
  }[];
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  changellyOffers: ChangellyGetOffersResponse[] | undefined;
};

const ChangellyFiatAmount: React.FC<Props> = React.memo(
  ({
    setFormData,
    fiatCurrencies,
    isLoading,
    amount,
    changellyOffers,
    setAmount,
  }) => {
    const debounce = useDebounce();
    const { data: currentIp, isLoading: currentIpLoading } = useGetCurrentIp();
    const walletContent = useGetWalletContent();
    const handleSelectFiat = useCallback(
      (item: ChangellyGetCurrencyListResponse) => {
        setFormData((prev) => ({ ...prev, selectedFiat: item }));
      },
      [setFormData],
    );

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        const target = e.target;
        setFormData((prev) => ({ ...prev, [type]: target.value }));
      },
      [setFormData],
    );
    const defaultSelectedIndex = useMemo(() => {
      if (currentIpLoading) return;
      const defaultIndex = fiatCurrencies?.findIndex(
        (currency) => currency?.id === currentIp?.default_currency,
      );
      return defaultIndex !== -1 ? defaultIndex : 0;
    }, [currentIp?.default_currency, fiatCurrencies, currentIpLoading]);
    const errorMessage = useMemo(() => {
      if (!changellyOffers || !Array.isArray(changellyOffers)) return;
      if (changellyOffers && !changellyOffers[0]?.errorDetails) {
        return changellyOffers[0]?.errorMessage;
      }
      return (
        (changellyOffers &&
          changellyOffers[0]?.errorDetails &&
          `${changellyOffers[0]?.errorMessage}: ${changellyOffers[0]?.errorDetails[0]?.cause} ${changellyOffers[0]?.errorDetails[0]?.value}`) ||
        undefined
      );
    }, [changellyOffers]);

    return isLoading || currentIpLoading ? (
      <InputWithLabelLoader />
    ) : (
      <div className="input-number-container">
        <Input
          placeholder={walletContent?.buy_crypto?.input?.placeholder}
          variant="static"
          value={amount}
          type="number"
          label={walletContent?.buy_crypto?.input?.pay}
          error={
            changellyOffers && changellyOffers[0]?.errorType ? true : false
          }
          errorMessage={errorMessage}
          onChange={(e) => {
            setAmount(e?.target?.value);
            debounce(() => handleChange(e, 'amount'), 1000);
          }}
          iconStyles="pointer-events-auto right-1"
          trailingIcon={
            <Select
              size="sm"
              defaultSelectedIndex={defaultSelectedIndex}
              items={fiatCurrencies}
              onSelect={handleSelectFiat}
              dropdownStylingOptions={{
                triggerStyles:
                  'w-18 [&>div>div>div>div>div>span]:max-w-[40px] [&>div>div>div>div>div>span]:truncate -mr-2 [&>div]:rounded-radius-sm [&>div]:ring-transparent',
                contentStyles: '!max-h-[15rem] scrollbar',
                containerStyles: 'w-24 left-auto -right-2',
              }}
            />
          }
        />
      </div>
    );
  },
);

ChangellyFiatAmount.displayName = 'ChangellyFiatAmount';

export default ChangellyFiatAmount;
