import { Tabs } from '@purposeinplay/core-v2';
import React, { useCallback, useMemo } from 'react';
import { WalletTab, WalletTabProps } from '../types';
import useModal from '@/hooks/modal';
import { useSearchParams } from 'next/navigation';

const WalletTabs = ({ tabs, setTabs }: WalletTabProps) => {
  const searchParams = useSearchParams();
  const activeTab = searchParams.get('a') ?? 'deposit';
  const { replace } = useModal();
  const handleTabClick = useCallback(
    (e: any, item: WalletTab) => {
      const newItems = tabs.map((el) => ({
        ...el,
        active: el.id === item.id ? true : false,
      }));

      setTabs(newItems);
      replace('a', item.id);
    },
    [tabs, setTabs, replace],
  );

  const defaultSelectedIndex = useMemo(
    () => tabs?.findIndex((el) => el.id === activeTab),
    [tabs, activeTab],
  );

  return (
    <div className="hide-scroll-wrapper overflow-x-scroll">
      <Tabs
        defaultSelectedIndex={defaultSelectedIndex}
        className="mb-3 lg:mb-7"
        tabStyleOptions={{
          navItemStyles: 'whitespace-nowrap',
        }}
        variant={'segmented-rounded'}
        items={tabs}
        onClick={handleTabClick}
      />
    </div>
  );
};

export default WalletTabs;
