import EmptyState from '@/components/EmptyState';
import { Button } from '@purposeinplay/core-v2';
import { useRouter } from 'next/navigation';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';

const RestrictedDeposit = () => {
  const router = useRouter();
  const handleClick = () => {
    router.push('/settings/limits');
  };

  const walletContent = useGetWalletContent();

  return (
    <div tw="flex flex-col items-center justify-center mb-4 py-2 text-sm leading-4 space-y-7">
      <EmptyState
        iconName={'ribbon-na'}
        title={walletContent?.deposit?.cool_off_limit?.title}
        description={walletContent?.deposit?.cool_off_limit?.description}
        button={
          <Button onClick={handleClick} color="tertiary" className="mx-auto">
            {walletContent?.deposit?.cool_off_limit?.button_text}
          </Button>
        }
      />
    </div>
  );
};

export default RestrictedDeposit;
