import { usePlayerLimits } from '@/hooks/limits';
import { useMemo } from 'react';

export const useGetCoolOffLimit = () => {
  const { data: limits = [] } = usePlayerLimits();

  const coolOffLimit = useMemo(() => {
    const limit = limits?.find(
      (limit: any) =>
        limit.type === 'coolingoff' &&
        (limit.status === 'active' || limit.status === 'unconfirmed_disable'),
    );
    return limit;
  }, [limits]);

  return { coolOffLimit };
};
