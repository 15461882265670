import React from 'react';
import { POPULAR_CURRENCIES } from '@/utils/constants';
import { useGetSortedAccounts } from '@/hooks/use-get-sorted-accounts';
import CurrencyBadge from './currency-badge';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';

const PopularCurrencies = () => {
  const { sortedAccounts } = useGetSortedAccounts();
  const walletContent = useGetWalletContent();
  return (
    <div className="flex  flex-col  text-sm text-text-subdued">
      <span className="mb-1 text-xs leading-4 text-text-subdued">
        {walletContent?.deposit?.popular_currencies}
      </span>
      <div className="flex flex-wrap gap-1">
        {POPULAR_CURRENCIES.map((currency) => (
          <CurrencyBadge
            accounts={sortedAccounts}
            key={currency}
            account={currency}
          />
        ))}
      </div>
    </div>
  );
};

export default PopularCurrencies;
