import { useUpdatePlayerAccount } from '@/hooks/accounts';
import { FormattedAccount } from '@/types/accounts';
import { createQueryString, getIconByCurrency } from '@/utils/helpers';
import { Badge } from '@purposeinplay/core-v2';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import '../styles/popular.css';
import React from 'react';
import { cn } from '@/utils/style';

const CurrencyBadge = ({
  account,
  accounts,
}: {
  account: string;
  accounts: any[];
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const queryCurrency = searchParams?.get('c');

  const { mutateAsync: addAccount } = useUpdatePlayerAccount();

  const foundAccount = accounts.find((acc) => acc?.currency === account);

  const pathname = usePathname();

  const handleClick = async () => {
    if (!foundAccount) {
      await addAccount(account);
    }
    let queryString = `${pathname}?${createQueryString(
      'c',
      account,
      searchParams,
    )}`;

    router.push(queryString);
  };

  return (
    <div onClick={handleClick} className="badge-container cursor-pointer">
      <Badge
        type={queryCurrency === account ? 'success' : 'white'}
        className={cn([
          queryCurrency === account
            ? 'hover:bg-base-primary'
            : 'hover:bg-gray-300 dark:hover:bg-gray-600',
        ])}
        leadingIcon={getIconByCurrency(account.toLowerCase(), { small: true })}
        radius="rounded"
        size="md"
      >
        {account}
      </Badge>
    </div>
  );
};

export default CurrencyBadge;
