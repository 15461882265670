'use client';
import { Button, Field, FieldGroup, Input } from '@purposeinplay/core-v2';
import { useRouter } from 'next/navigation';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from '@/app/i18n/client';
import { useAuth, useResetInstructions } from '@/hooks/auth';
import useModal from '@/hooks/modal';
import { AuthContentType } from '@/types/json-content/auth';
import { handleError } from '@/utils/helpers';
import { useToast } from '@purposeinplay/utils';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';
import ForgotPasswordFormHeader from './components/forgot-password/form-header';
import ForgotPasswordFormFooter from './components/forgot-password/footer';

const initialState = {
  email: '',
};

const ForgotPasswordForm: React.FC<{
  closeModal?: () => void;
  disableDark?: boolean;
  openLoginModal?: () => void;
}> = ({ closeModal, openLoginModal }) => {
  const { close } = useModal();
  const [state, setState] = useState(initialState);
  const {
    mutateAsync: resetInstructions,
    isPending,
    isError,
    error,
    reset,
  }: any = useResetInstructions();
  const {
    user: { isAuthenticated },
  } = useAuth();
  const router = useRouter();
  const { t } = useTranslation();
  const authContent = t('auth') as AuthContentType;
  const content = authContent.forgot_password_modal;
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  useEffect(() => {
    if (isAuthenticated && !closeModal) {
      router.push('/', undefined);
    }
  }, [isAuthenticated, router, closeModal]);

  useEffect(() => {
    return () => {
      setState({
        email: '',
      });
      reset();
    };
  }, [reset]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    const target = e.target;
    setState({ ...state, [type]: target.value });
  };
  const handleResetInstructions = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        await resetInstructions({ user: { ...state } });
        closeModal && closeModal();
        close();

        toast({
          state: 'success',
          title: toastMessages?.reset_instructions,
        });
      } catch (e) {
        closeModal && closeModal();
        toast({
          state: 'error',
          title: toastMessages?.general_error,
        });
      }
    },
    [
      close,
      closeModal,
      resetInstructions,
      state,
      toast,
      toastMessages?.general_error,
      toastMessages?.reset_instructions,
    ],
  );
  return (
    <div className="h-full md:min-h-100">
      <form className="flex h-full flex-col" onSubmit={handleResetInstructions}>
        <ForgotPasswordFormHeader content={content} />
        <div className="md:flex md:h-full md:flex-col md:justify-between">
          <FieldGroup>
            <Field>
              <Input
                variant="static"
                id="email"
                required
                type="email"
                error={isError && handleError(error?.errors, 'email')}
                errorMessage={isError && handleError(error?.errors, 'email')}
                label={content?.form?.email?.label}
                placeholder={content?.form?.email?.placeholder}
                value={state.email}
                onChange={(e) => handleChange(e, 'email')}
              />
            </Field>
            <Field>
              <Button
                size="md"
                type="submit"
                isLoading={isPending}
                disabled={isPending}
                className="mb-3 w-full max-w-full"
              >
                {content?.form?.action?.btn_label}
              </Button>
              <ForgotPasswordFormFooter
                closeModal={closeModal}
                isPending={isPending}
                openLoginModal={openLoginModal}
                content={content}
              />
            </Field>
          </FieldGroup>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
