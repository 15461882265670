import { SigninModal } from '@/types/json-content/auth';
import { Button, Text } from '@purposeinplay/core-v2';
import Link from 'next/link';
import React from 'react';

type Props = {
  content: SigninModal;
  isPending: boolean;
  closeModal?: () => void;
  openRegisterModal?: () => void;
};

const SignInFooter: React.FC<Props> = React.memo(
  ({ isPending, content, closeModal, openRegisterModal }) => {
    return (
      <>
        <div className="mb-3">
          <Button
            size="md"
            type="submit"
            isLoading={isPending}
            disabled={isPending}
            className="w-full max-w-full"
          >
            {content?.form?.action?.btn_label}
          </Button>
        </div>
        <div className="flex items-center justify-start">
          <Text size="sm" className="mr-1.5 text-text-subdued">
            {content?.form?.no_account?.message}
          </Text>
          {openRegisterModal ? (
            <div
              onClick={() => {
                closeModal && closeModal();
                openRegisterModal && openRegisterModal();
              }}
              className="text-primary cursor-pointer text-sm font-bold text-base-primary md:text-sm lg:text-sm"
            >
              {content?.form?.no_account?.action}
            </div>
          ) : (
            <Link
              href={content?.form?.no_account?.action_href}
              className="text-primary cursor-pointer text-sm font-bold text-base-primary md:text-sm lg:text-sm"
            >
              {content?.form?.no_account?.action}
            </Link>
          )}
        </div>
      </>
    );
  },
);

SignInFooter.displayName = 'SignInFooter';

export default SignInFooter;
