import React, { useMemo, useState } from 'react';
import { WALLET_TABS } from '../constants';
import WalletTabs from './wallet-tabs';
import Deposit from './Deposit';
import Withdraw from './Withdraw';
import Transactions from './Transactions';
import { useSearchParams } from 'next/navigation';
import BuyCrypto from './BuyCrypto';

const WalletContent = ({
  isStandalone = false,
}: {
  isStandalone?: boolean;
}) => {
  const [tabs, setTabs] = useState(WALLET_TABS);

  const searchParams = useSearchParams();

  let activeTab = searchParams.get('a');

  if (isStandalone && !activeTab) {
    activeTab = 'deposit';
  }
  const getWalletContent = useMemo(() => {
    switch (activeTab) {
      case 'deposit':
        return <Deposit />;
      case 'buy-crypto':
        return <BuyCrypto />;
      case 'withdraw':
        return <Withdraw />;
      case 'transactions':
        return <Transactions />;
      default:
        return <Deposit />;
    }
  }, [activeTab]);

  return (
    <div>
      <WalletTabs tabs={tabs} setTabs={setTabs} />
      <div>{getWalletContent}</div>
    </div>
  );
};

export default WalletContent;
