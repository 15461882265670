import React from 'react';

const OptionalData = ({ optionalData }: { optionalData: any }) => {
  return (
    <div className="my-2 flex w-full items-center justify-between rounded-xl bg-surface-default p-3">
      <p className="text-sm font-bold text-text-default">Destination tag</p>
      <p className="flex-wrap break-all text-sm font-semibold">
        {optionalData?.href}
      </p>
    </div>
  );
};

export default OptionalData;
