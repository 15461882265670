import React from 'react';
import { SelectItemType } from '../types';
import { ListComponent } from '@purposeinplay/core-v2';

interface Props {
  items: SelectItemType[];
  close?: () => void;
}

const WalletListItems = ({ items, close }: Props) => {
  return (
    <>
      {items?.map((item) => (
        <div
          className="mt-2 w-full"
          key={item.id}
          onClick={(e) => {
            e.stopPropagation();
            close && close();
            item.onClick();
          }}
        >
          <ListComponent
            stylingOptions={{
              containerStyles: 'bg-transparent max-h-10',
              valueStyles: 'flex justify-end !py-2.5',
              labelStyles: '!py-2.5 ',
            }}
            label={item?.leading}
            value={item?.trailing}
          />
        </div>
      ))}
    </>
  );
};

export default WalletListItems;
