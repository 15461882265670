import { useCentsFromCrypto, useFiatFromCrypto } from '@/hooks/fiat';
import { useAppSelector } from '@/hooks/redux';
import { CURRENCY_CONFIRMATIONS, USD_STABLECOINS } from '@/utils/constants';
import { Payment } from 'payments-lib-types';
import { useFetchPaymentMethodsV2 } from '@/hooks/paymentsv2';
import CryptoTooltip from '@/components/CryptoTooltip';
import useWalletSelector from '@/hooks/use-wallet-selector';
import { useGetFoundCurrencyDeposit } from '@/hooks/use-get-found-currency-deposit';
import { TextLoader } from '@/components/Loaders';
import { useSearchParams } from 'next/navigation';
import { useGetWalletContent } from '../../../hooks/use-get-wallet-content';
import FiatAmount from '@/components/FiatAmount';
import CryptoAmount from '@/components/CryptoAmount';

const MinMaxDeposit = () => {
  const { formattedAccounts } = useWalletSelector();
  const searchParams = useSearchParams();
  const currency = searchParams.get('c') || 'BTC';

  const walletContent = useGetWalletContent();

  const { data: paymentMethods } = useFetchPaymentMethodsV2(
    currency?.toUpperCase(),
    Payment.Action.Deposit,
  );

  const { foundCurrencyDeposit: foundCurrency } = useGetFoundCurrencyDeposit();

  const paymentMethod =
    paymentMethods &&
    (paymentMethods?.find(
      (method) =>
        method?.termsOfService?.restrictions?.amountCurrencyCode?.toLowerCase() ===
          method?.termsOfService?.restrictions?.originCryptoCurrency?.toLowerCase() &&
        method?.termsOfService?.restrictions?.amountCurrencyCode?.toLowerCase() ===
          currency?.toLowerCase(),
    ) as any);

  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);

  const fiatMinDeposit = useFiatFromCrypto(
    foundCurrency?.code,
    paymentMethod?.termsOfService?.restrictions?.minAmountValue,
  );
  const { formattedBalance } = useCentsFromCrypto(
    foundCurrency?.code,
    paymentMethod?.termsOfService?.restrictions?.minAmountValue,
  );

  const protocol = searchParams.get('p') || '';

  const protocolMsg =
    foundCurrency?.code &&
    USD_STABLECOINS.includes(foundCurrency.code.toUpperCase()) &&
    protocol === 'ERC20'
      ? '(Ethereum ERC-20) '
      : foundCurrency?.code.toUpperCase() === 'USDT' && protocol === 'TRC20'
        ? '(Tron TRC-20) '
        : foundCurrency?.code.toUpperCase() === 'USDT' && protocol === 'BEP20'
          ? '(BNB Smart Chain BEP-20) '
          : '';

  const currencyDisplay =
    foundCurrency?.code.toUpperCase() ||
    formattedAccounts[0]?.currency?.toUpperCase() ||
    '';

  const formattedCurrency = currencyDisplay ? `${currencyDisplay} ` : '';

  const text = `${
    walletContent?.deposit?.extra_data?.only_send
  } ${formattedCurrency}${protocolMsg}${
    foundCurrency?.code.toUpperCase() === 'BNB'
      ? walletContent?.deposit?.extra_data?.bnb_chain
      : ''
  }${walletContent?.deposit?.extra_data?.to_address} `;

  const currencyCode =
    foundCurrency?.code.toUpperCase() as keyof typeof CURRENCY_CONFIRMATIONS;
  const confirmations = CURRENCY_CONFIRMATIONS[currencyCode];
  return (
    <div className="flex flex-wrap gap-1 text-xs text-text-subdued">
      <div className="flex flex-row space-x-1">
        {walletContent?.deposit?.extra_data?.min_deposit} &nbsp;
        {paymentMethod?.termsOfService?.restrictions?.minAmountValue ? (
          <CryptoTooltip
            cryptoAmount={
              viewFiat ? (
                <FiatAmount
                  removeIcon
                  fiatAmount={fiatMinDeposit}
                  currency={foundCurrency?.code ?? 'BTC'}
                />
              ) : (
                <CryptoAmount
                  removeIcon
                  currency={foundCurrency?.code ?? 'BTC'}
                  balance={formattedBalance}
                />
              )
            }
            cryptoCode={foundCurrency?.code}
          >
            <CryptoAmount
              currency={foundCurrency?.code ?? 'BTC'}
              balance={formattedBalance}
            />
          </CryptoTooltip>
        ) : (
          <TextLoader className="h-4 w-10" />
        )}
        .
      </div>

      {text}
      <span className="font-medium text-text-default">{confirmations}</span>
      {` ${walletContent?.deposit?.extra_data?.confirmation_req}`}
    </div>
  );
};

export default MinMaxDeposit;
