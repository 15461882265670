import { ChangellyProvider } from '@changelly/fiat-api-sdk-node';
import VisaSVG from 'public/assets/fiat-payment-logos/visa.svg';
import GPaySVG from 'public/assets/fiat-payment-logos/g-pay.svg';
import ApplePaySVG from 'public/assets/fiat-payment-logos/apple-pay.svg';
import MastercardSVG from 'public/assets/fiat-payment-logos/mastercard.svg';

export const getFiatPaymentIconByProvider = (provider: string) => {
  switch (provider) {
    case 'moonpay':
      return (
        <div className="grid grid-cols-4 sm:gap-spacing-xs">
          <MastercardSVG className="fill-current text-text-default" />
          <VisaSVG className="fill-current text-text-default" />
          <ApplePaySVG className="fill-current text-text-default" />
          <GPaySVG className="fill-current text-text-default" />
        </div>
      );
    case 'banxa':
      return (
        <div className="grid grid-cols-4 sm:gap-spacing-xs">
          <MastercardSVG className="fill-current text-text-default" />
          <VisaSVG className="fill-current text-text-default" />
          <GPaySVG className="fill-current text-text-default" />
          <ApplePaySVG className="fill-current text-text-default" />
        </div>
      );
    case 'wert':
      return (
        <div className="grid grid-cols-4 sm:gap-spacing-xs">
          <MastercardSVG className="fill-current text-text-default" />
          <VisaSVG className="fill-current text-text-default" />
          <ApplePaySVG className="fill-current text-text-default" />
          <GPaySVG className="fill-current text-text-default" />
        </div>
      );
    case 'simplex':
      return (
        <div className="grid grid-cols-4 sm:gap-spacing-xs">
          <MastercardSVG className="fill-current text-text-default" />
          <VisaSVG className="fill-current text-text-default" />
        </div>
      );
    default:
      return (
        <div className="grid grid-cols-4 gap-spacing-xs">
          <MastercardSVG className="fill-current text-text-default" />
          <VisaSVG className="fill-current text-text-default" />
          <ApplePaySVG className="fill-current text-text-default" />
          <GPaySVG className="fill-current text-text-default" />
        </div>
      );
  }
};
