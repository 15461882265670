import { useEffect, useRef } from 'react';
import { useRouter } from 'next/navigation';
import TelegramSVG from 'public/assets/social/telegram.svg';
import GoogleSVG from 'public/assets/social/google.svg';
import { CustomButton } from './custom-button';
import config from '@/config';
import DashedOr from '@/components/DashedOr';

const SocialAuth = () => {
  const router = useRouter();
  const telegramWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (telegramWrapperRef.current) {
      const scriptElement = document.createElement('script');
      scriptElement.src = 'https://telegram.org/js/telegram-widget.js?22';
      scriptElement.setAttribute('data-telegram-login', 'wild_io_bot');
      scriptElement.setAttribute('data-size', 'large');
      // scriptElement.setAttribute(
      //   'data-auth-url',
      //   'https://www.wild.io/users/auth/telegram/callback',
      // );
      scriptElement.setAttribute('data-onauth', 'onTelegramAuth(user)');
      scriptElement.setAttribute('data-request-access', 'write');
      scriptElement.async = true;
      telegramWrapperRef.current.appendChild(scriptElement);
    }

    // Define the onTelegramAuth function
    // @ts-ignore
    window.onTelegramAuth = function (user) {
      console.log('User authenticated:', user);
      // Construct the URL with query parameters
      const queryParams = new URLSearchParams(user).toString();
      const authUrl = `${config.API_URL_BASE}/users/auth/telegram/callback?${queryParams}`;
      // Redirect to the constructed URL
      window.location.href = authUrl;
    };
  }, []);

  return (
    <>
      <div className="mb-4 grid grid-cols-2 justify-center gap-1 md:mb-7">
        <CustomButton
          className="w-full !cursor-pointer"
          onClick={() =>
            router.push(`${config.API_URL_BASE}/users/auth/google_oauth2`)
          }
        >
          <GoogleSVG className="h-4 w-4" />
          <span className="text-sm font-semibold">Google</span>
        </CustomButton>

        <CustomButton className="relative !cursor-pointer overflow-hidden">
          <TelegramSVG className="h-4 w-4" />
          <span className="text-sm font-semibold">Telegram</span>
          <div
            id="telegram-container"
            className="telegram-container absolute inset-0 z-10 w-full !cursor-pointer overflow-hidden [&>iframe]:absolute [&>iframe]:inset-0 [&>iframe]:!cursor-pointer [&>iframe]:opacity-[0.001] "
            ref={telegramWrapperRef}
          ></div>
        </CustomButton>
      </div>
      <DashedOr />
    </>
  );
};

export default SocialAuth;
