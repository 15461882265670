export const WALLET_TABS = [
  { id: 'deposit', label: 'Deposit', active: true },
  { id: 'buy-crypto', label: 'Buy Crypto', active: false },
  {
    id: 'withdraw',
    label: 'Withdraw',
    active: false,
  },
  { id: 'transactions', label: 'Transactions', active: false },
];

export const PROTOCOL_NAMES: {
  ERC20: string;
  TRC20: string;
  BEP20: string;
} = {
  ERC20: 'Ethereum (ERC-20)',
  TRC20: 'Tron (TRC-20)',
  BEP20: 'BNB Smart Chain (BEP-20)',
};

export const POPULAR_CURRENCIES = ['BTC', 'USDT', 'LTC', 'TRX', 'ETH'];

export const CURRENCY_CONFIRMATIONS = {
  ADA: '15',
  BTC: '1',
  BCH: '6',
  LTC: '6',
  DOG: '6',
  ETH: '10',
  XRP: '3',
  USDT: '10',
  BNB: '1',
  TRX: '19',
};

export const ACTIONS = [
  {
    id: 'cashout',
    component: 'Cashout',
  },
  {
    id: 'deposit',
    component: 'Deposit',
  },
  {
    id: 'bet',
    component: 'Bet',
  },
];
