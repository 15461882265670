import React, { useState } from 'react';
import { PreviewBonus } from '@/types/json-content/preview_bonuses';

import CondensedDepositRange from './condensed-range';
import { useAppDispatch } from '@/hooks/redux';
import { setWalletDynamicContent } from '@/app/reducers/bootstrap';
import BonusDetailsPreviewModal from '@/components/Bonuses/components/bonus-details-modal-preview';
import CondensedPreviewHeader from './condensed-preview-header';

type Props = {
  bonus: PreviewBonus | undefined;
};

const CondensedPreviewBonus: React.FC<Props> = ({ bonus }) => {
  const dispatch = useAppDispatch();
  const [openDetails, setOpenDetails] = useState(false);

  const handleClickDetails = () => {
    dispatch(
      // setBonusDetailsnDynamicContent({
      //   data: bonus,
      // }),
      setWalletDynamicContent({
        isOpen: 'bonus-details',
        data: bonus,
      }),
    );
    setOpenDetails(true);
    return;
  };
  return (
    <div>
      <CondensedPreviewHeader
        bonusTitle={bonus?.title}
        handleClickDetails={handleClickDetails}
      />
      <div className="flex flex-col gap-spacing-2xs">
        {bonus?.bonuses?.map((item, index) => (
          <div key={`${index}-${item?.dls_id}`}>
            <CondensedDepositRange bonus={item} />
          </div>
        ))}
      </div>
      <BonusDetailsPreviewModal open={openDetails} setOpen={setOpenDetails} />
    </div>
  );
};

export default CondensedPreviewBonus;
