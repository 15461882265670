import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import client from '@/utils/api';
import { useToast } from '@purposeinplay/utils';
import { useTranslation } from '@/app/i18n/client';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';

const usePlayerFields = (): UseQueryResult => {
  return useQuery({
    queryKey: ['player_fields'],
    queryFn: () => client('info/player_fields'),
  });
};

const usePlayer = (): UseQueryResult<any> => {
  return useQuery({
    queryKey: ['player'],
    queryFn: () => client('player'),
    staleTime: 120000,
  });
};

const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['updatePlayerAccount'],
    mutationFn: async (data: any) => {
      try {
        return await client(
          'player',
          {
            context: data.context,
            player: {
              ...data.player,
            },
          },
          'PATCH',
        );
      } catch (e) {
        throw e;
      }
    },

    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['currentUser'] });
    },
  });
};

const useConfirmationEmail = (): UseMutationResult => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  // @ts-ignore
  return useMutation({
    mutationKey: ['deletePlayerAccount'],
    mutationFn: async (email: string) => {
      const payload = {
        user: {
          email,
        },
      };
      return await client('users/confirmation', payload, 'POST');
    },

    onSuccess: () => {
      toast({
        state: 'success',
        title: toastMessages?.confirmation_email,
      });
    },
  });
};

export { usePlayerFields, useUpdateProfile, usePlayer, useConfirmationEmail };
