import React from 'react';
import MinMaxDeposit from './min-max-deposit';
import MandatoryData from './mandatory-data';
import { useGetCryptoDepositInfo } from '@/hooks/paymentsv2';
import OptionalData from './optional-data';

const DepositInfo = () => {
  const { optionalData } = useGetCryptoDepositInfo();

  const isOptionalCardAvailable =
    optionalData && optionalData.fieldName === 'tag';

  return (
    <div className="flex w-full flex-col items-start justify-start space-y-2">
      <MandatoryData />
      {isOptionalCardAvailable && <OptionalData optionalData={optionalData} />}
      <MinMaxDeposit />
    </div>
  );
};

export default DepositInfo;
