import useModal, { useCurrentModal } from '@/hooks/modal';
import React, { useMemo } from 'react';
import LayoutModal from './layout-modal';
import SignUpForm from '@/components/Auth/signup-form';
import StandaloneModal from '../standalone-modal';
import { useRouter } from 'next/navigation';
import { useAnalytics } from '@/hooks/use-analytics';

const SignupModal = ({
  isStandalone,
}: {
  setOpen?: (param: any) => void;
  isStandalone?: boolean;
}) => {
  const { open: openModal, close } = useModal();
  const isOpen = useCurrentModal() === 'signup';

  const router = useRouter();

  const { track } = useAnalytics();

  const registerContent = useMemo(() => {
    return (
      <SignUpForm
        isStandalone={isStandalone}
        openLoginModal={() => {
          if (isStandalone) {
            router.push('/forms/sign_in');
          } else {
            openModal('signin');
          }
          track('signin_form_started', {
            category: 'auth',
          });
        }}
      />
    );
  }, [isStandalone, openModal, router, track]);

  if (isStandalone) {
    return <StandaloneModal>{registerContent}</StandaloneModal>;
  }

  return (
    <LayoutModal isOpen={isOpen} setIsOpen={() => openModal('signup')}>
      <SignUpForm
        openLoginModal={() => {
          openModal('signin');
          track('signin_form_started', {
            category: 'auth',
          });
        }}
      />
    </LayoutModal>
  );
};

export default SignupModal;
