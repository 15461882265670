import React from 'react';
import CurrencyListItem from '@/modules/layout/navbar/components/currency-list-item';
import { FormattedAccount } from '@/types/accounts';

interface Props {
  items: FormattedAccount[];
  setCurrencyOpen?: (open: boolean) => void;
}

const CurrencyListItems = ({ items, setCurrencyOpen }: Props) => {
  return (
    <ul className="space-y-1">
      {items?.map((acc: FormattedAccount) => {
        return (
          <li key={acc.key}>
            <CurrencyListItem setOpen={setCurrencyOpen} account={acc} />
          </li>
        );
      })}
    </ul>
  );
};

export default CurrencyListItems;
