import { useAppDispatch } from '@/hooks/redux';
import {
  setBonusSelectionDynamicContent,
  setWalletDynamicContent,
} from '@/app/reducers/bootstrap';
import { Chevron } from '@/components/SearchableSelect/components/helpers';
import React from 'react';
import { cn } from '@/utils/style';

const WalletContentTitle = ({
  title,
  backBtnHref,
  type,
  className,
}: {
  title: string;
  backBtnHref?: 'default' | 'details' | 'bonus' | 'bonus-details';
  type: 'bonus' | 'wallet';
  className?: string;
}) => {
  const dispatch = useAppDispatch();
  return (
    <div
      onClick={() => {
        if (type === 'bonus') {
          dispatch(
            setBonusSelectionDynamicContent({
              data: {},
              //@ts-ignore
              isOpen: backBtnHref ? backBtnHref : 'default',
            }),
          );
          return;
        } else if (type === 'wallet') {
          dispatch(
            setWalletDynamicContent({
              data: {},
              isOpen: backBtnHref ? backBtnHref : 'default',
            }),
          );
          return;
        }
      }}
      className={cn([
        'group relative mb-3 flex cursor-pointer items-center space-x-1 text-base font-medium leading-6  text-text-default md:mb-4 md:text-lg md:font-semibold lg:mb-7',
        className && className,
      ])}
    >
      <Chevron
        className="relative !right-0 !rotate-90 group-hover:[&>svg]:text-text-subdued"
        open={true}
      />
      <span className="group-hover:text-text-subdued">{title}</span>
    </div>
  );
};

export default WalletContentTitle;
