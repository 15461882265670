import { useTranslation } from '@/app/i18n/client';
import { useAnalytics } from '@/hooks/use-analytics';
import { GeneralContentType } from '@/types/json-content/general';
import { ErrorMessage } from '@purposeinplay/core-v2';
import React, { useEffect } from 'react';

const RestrictedMessage = () => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const content = t('general') as GeneralContentType;
  const restrictedMessage =
    content?.restricted_page?.reason?.restricted_registration;

  useEffect(() => {
    track('auth_restriction_detected', {
      category: 'auth',
    });
  }, []);

  return <ErrorMessage>{restrictedMessage}</ErrorMessage>;
};

export default RestrictedMessage;
