import useModal, { useCurrentModal } from '@/hooks/modal';
import { Modal } from '@purposeinplay/core-v2';
import BonusSelectionDetails from './bonus-preview/bonus-selection-details';

const BonusDetailsPreviewModal = ({
  open,
  setOpen,
}: {
  open?: boolean;
  setOpen?: (bool: boolean) => void;
}) => {
  const { close } = useModal();
  const isOpen = useCurrentModal() === 'details' || open;

  return (
    <Modal
      title={'Deposit Bonus'}
      isOpen={isOpen}
      setIsOpen={() => {
        if (setOpen) {
          setOpen(false);
          return;
        }
        close(['details', 'bonus']);
      }}
      // size={walletDynamicContent?.isOpen === 'details' ? '2xl' : '4xl'}
      size="2xl"
    >
      <BonusSelectionDetails type="bonuses" />
    </Modal>
  );
};

export default BonusDetailsPreviewModal;
