import useModal, { useCurrentModal } from '@/hooks/modal';
import { Modal } from '@purposeinplay/core-v2';
import React, { useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { useAppDispatch } from '@/hooks/redux';
import StandaloneModal from '@/components/Modals/standalone-modal';
import { useSearchParams } from 'next/navigation';
import { setWalletDynamicContent } from '@/app/reducers/bootstrap';
import WalletContentRenderer from './components/wallet-content-renderer';
import { useAnalytics } from '@/hooks/use-analytics';

const WalletModal = ({ isStandalone }: { isStandalone?: boolean }) => {
  const { close } = useModal();
  const isOpen = useCurrentModal() === 'wallet';

  const { track } = useAnalytics();

  const searchParams = useSearchParams();
  const action = searchParams.get('a');

  useEffect(() => {
    track('wallet_balance_viewed', {
      category: 'account',
      label: action,
    });
  }, [action, track]);

  const dispatch = useAppDispatch();

  const handleOnClose = () => {
    close(['a', 'c', 'p']);
    dispatch(
      setWalletDynamicContent({
        data: {},
        isOpen: 'default',
      }),
    );
  };

  if (isStandalone) {
    return (
      <StandaloneModal>
        <WalletContentRenderer isStandalone={isStandalone} />
      </StandaloneModal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={handleOnClose}
      size="xl"
      modalStylingOptions={{
        containerStyles: 'overflow-visible',
      }}
    >
      <Transition
        show={true}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <WalletContentRenderer />
      </Transition>
    </Modal>
  );
};

export default WalletModal;
