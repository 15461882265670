import React from 'react';
import QRCode from 'qrcode.react';
import { TextLoader } from '@/components/Loaders';

const DepositQR = ({ depositInfoHref }: { depositInfoHref: string }) => {
  return (
    <div className="flex justify-center">
      <div className="mb-4 self-center  rounded-xl bg-white  p-4 shadow-lg">
        {!depositInfoHref ? (
          <TextLoader className="h-[120px] w-[120px] rounded-lg bg-surface-subdued lg:w-[134px]" />
        ) : (
          <QRCode value={depositInfoHref} size={120} />
        )}
      </div>
    </div>
  );
};

export default DepositQR;
